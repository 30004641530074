import { Box, Stack, DialogTitle, Button, Popover } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { VTMeetings } from "../../../types";

//REQUESTS
import { updateMeetingInvite } from "../../../@requests/Meetings";

//GLOBAL STATE
import { meetingsStore } from "@state/store";

export type VTRSVPDialog = {
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
  meeting: VTMeetings;
  inviteId: string;
};

const RSVPPopover = ({
  needsRefresh,
  setNeedsRefresh,
  inviteId,
}: VTRSVPDialog) => {
  // GLOBAL STATE
  const { inviteStatusUpdate, setInviteStatusUpdate } = meetingsStore();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusUpdate = (inviteId: string, status: string) => {
    updateMeetingInvite(inviteId, status, "Changed status")
      .then((data) => {
        setNeedsRefresh(!needsRefresh);
        setInviteStatusUpdate(!inviteStatusUpdate);
      })
      .catch((e) => {
        console.error("failed to changed status", e);
      });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        sx={{ width: "100%" }}
        variant="outlined"
        color="secondary"
        onClick={handleClick}
      >
        RSVP
      </Button>

      <Popover
        sx={{ borderRadius: "10px" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box
          sx={{
            width: "455px",
            height: "371px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            p={1}
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "Bold",
              }}
            >
              RSVP
            </DialogTitle>
            <Stack
              spacing={1}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "90%",
                  alignSelf: "center",
                  color: "var(--visionable-mid-blue)",
                }}
                onClick={() => {
                  handleStatusUpdate(inviteId, "accepted");
                  setNeedsRefresh(!needsRefresh);
                }}
              >
                <CheckIcon />
                Accept
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "90%",
                  alignSelf: "center",
                  color: "var(--visionable-mid-blue)",
                }}
                onClick={() => {
                  handleStatusUpdate(inviteId, "tentative");
                  setNeedsRefresh(!needsRefresh);
                }}
              >
                <HelpOutlineIcon /> Tentative
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  handleStatusUpdate(inviteId, "rejected");
                  setNeedsRefresh(!needsRefresh);
                }}
                sx={{
                  width: "90%",
                  alignSelf: "center",
                  color: "var(--visionable-mid-blue)",
                }}
              >
                <CloseIcon />
                Decline
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default RSVPPopover;
