import { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";

export type VTRepeatMeetingString = {
  repeatMeeting: string;
  activeDaysString: string;
  dayString: string;
  monthString: string;
  meetingStartDate: string;
  byMonthDay: number;
  editMeetingModalIsOpen?: boolean;
  setDayString?: (dayString: string) => void;
  rruleDescription?: string;
  recurrenceModalIsOpen: boolean;
  setRecurrenceModalIsOpen: (recurrenceModalIsOpen: boolean) => void;
  recurrenceChecked: boolean;
  setRecurrenceChecked: (recurrenceChecked: boolean) => void;
};

const RepeatMeetingString = ({
  repeatMeeting,
  activeDaysString,
  dayString,
  monthString,
  meetingStartDate,
  byMonthDay,
  setDayString,
  editMeetingModalIsOpen,
  rruleDescription,
  recurrenceModalIsOpen,
  setRecurrenceModalIsOpen,
  setRecurrenceChecked,
}: VTRepeatMeetingString) => {
  const year = meetingStartDate.slice(0, 4);
  const [repeatStringIsLoaded, setRepeatStringIsLoaded] =
    useState<boolean>(false);
  const [newDayString, setNewDayString] = useState<string>(dayString);

  useEffect(() => {
    if (
      activeDaysString &&
      dayString &&
      monthString &&
      editMeetingModalIsOpen
    ) {
      setRepeatStringIsLoaded(true);
    }
  }, [activeDaysString, dayString, monthString, editMeetingModalIsOpen]);

  useEffect(() => {
    const day = meetingStartDate.slice(8);
    const getNumberWithOrdinal = () => {
      const endings = ["th", "st", "nd", "rd"];
      if (dayString) {
        if (dayString.split("")[0] === "0") {
          const number = parseInt(dayString.split("")[1]);
          const ending = endings[number % 100 > 3 ? 0 : number % 10];
          setNewDayString(`${number}${ending}`);
        } else {
          const ending =
            endings[
              parseInt(dayString) % 100 > 3 ? 0 : parseInt(dayString) % 10
            ];
          setNewDayString(`${dayString}`);
        }
      } else if (day) {
        const ending =
          endings[parseInt(day) % 100 > 3 ? 0 : parseInt(day) % 10];
        setNewDayString(`${day}`);
      }
    };

    getNumberWithOrdinal();
  }, [meetingStartDate, dayString, editMeetingModalIsOpen]);

  const handleEditIcon = () => {
    setRecurrenceChecked(true);
    setRecurrenceModalIsOpen(true);
  };

  return (
    <>
      {repeatStringIsLoaded || editMeetingModalIsOpen ? (
        <>
          {repeatMeeting !== "no" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "18.5px",
                backgroundColor: "#F5F5F5",
                height: "54px",
              }}
            >
              {repeatMeeting === "daily" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-betwen",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography>
                      Occurs every {activeDaysString}, starting {newDayString}{" "}
                      {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ) : repeatMeeting === "weekly" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography sx={{ paddingLeft: "10px" }}>
                      Occurs every {activeDaysString}, starting {newDayString}{" "}
                      {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ) : repeatMeeting === "monthly" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography sx={{ paddingLeft: "10px" }}>
                      Occurs on the {newDayString} of every month starting&nbsp;
                      {newDayString} {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {repeatMeeting !== "no" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "18.5px",
                backgroundColor: "#F5F5F5",
                height: "54px",
              }}
            >
              {repeatMeeting === "daily" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography>
                      Occurs every {activeDaysString}, starting {dayString}{" "}
                      {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                </Box>
              ) : repeatMeeting === "weekly" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography sx={{ paddingLeft: "10px" }}>
                      Occurs every {activeDaysString}, starting {dayString}{" "}
                      {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ) : repeatMeeting === "monthly" ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Stack direction="row">
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "20px", marginRight: "20px" }}
                    />
                    <Typography sx={{ paddingLeft: "10px" }}>
                      Occurs on the {dayString} of every month starting
                      {dayString} {monthString} {year}
                    </Typography>
                  </Stack>
                  <EditIcon
                    onClick={handleEditIcon}
                    sx={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default RepeatMeetingString;
