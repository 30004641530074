///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR REFRESHING MEETINGS
///
///////////////////////////////////////////////////////////////
import { useState, useEffect } from "react";

// MUI COMPONENTS
import { Box, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

//GLOBAL STATE
import { meetingsStore } from "@state/store";

///////////////////////////////////////////////////////////////
///
///  REFRESH MEETINGS BUTTON COMPONENT
///
///////////////////////////////////////////////////////////////

const MeetingsRefreshButton = () => {
  // GLOBAL STATE
  const { inviteStatusUpdate, setInviteStatusUpdate } = meetingsStore();

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const handleClick = () => {
    setInviteStatusUpdate(!inviteStatusUpdate);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Box sx={{ justifySelf: "flex-end", alignSelf: "center" }}>
      <IconButton onClick={handleClick}>
        <RefreshIcon fontSize="small" style={{ color: "black" }} />
      </IconButton>
    </Box>
  );
};

export default MeetingsRefreshButton;
