//IMPORTS
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { parse } from "date-fns";
import RepeatMeeting from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/RepeatMeeting";
import MeetingTitle from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Title";
import Attendees from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Attendees";
import Moderators from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Moderators";
import MeetingTime from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/MeetingTime";
import Description from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Description";
import Close from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Close";
import Save from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Save";
import RepeatMeetingString from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/RepeatMeetingString";

import { Box, Modal } from "@mui/material";
import { OtherUsers, VTInviteObject } from "../../../types";

//REQUESTS
import {
  getAllUsersInformation,
  createMeeting,
} from "../../../@requests/Meetings";

//STORE
import { userStore, meetingsStore } from "@state/store";

export type VTCreateMeeting = {
  createMeetingModalIsOpen: boolean;
  setCreateMeetingModalIsOpen: (createMeetingModalIsOpen: boolean) => void;
  setNeedsRefresh: (needsRefresh: boolean) => void;
  setSaveMeetingIsClicked: (saveMeetingIsClicked: boolean) => void;
  saveMeetingIsClicked: boolean;
  editMeetingModalIsOpen: boolean;
  setSnackBarIsOpenForCreateMeeting: (value: boolean) => void;
};

const CreateMeeting = ({
  editMeetingModalIsOpen,
  createMeetingModalIsOpen,
  setCreateMeetingModalIsOpen,
  setNeedsRefresh,
  setSaveMeetingIsClicked,
  saveMeetingIsClicked,
  setSnackBarIsOpenForCreateMeeting,
}: VTCreateMeeting) => {
  const { user } = userStore();
  const { setGlobalDate } = meetingsStore();

  //format the meetingStartDate and meetingEndDate to be today's date formatted as mm-dd-yyyy
  const today = new Date().toISOString().split("T")[0];
  const todayDate = new Date();

  //Updating the Start Time/date of the meeting based on the closest half hour
  todayDate.setMinutes(Math.ceil(todayDate.getMinutes() / 30) * 30);
  const h12h = todayDate.getHours();
  const m12h = todayDate.getMinutes();
  let timeStringStarting = "";
  if (h12h.toString().length === 1) {
    timeStringStarting = `0${h12h}` + ":" + ("00" + m12h).slice(-2) + ":00";
  } else {
    timeStringStarting = h12h + ":" + ("00" + m12h).slice(-2) + ":00";
  }

  // All the states currently being used
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<OtherUsers[]>([]);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingStartDate, setMeetingStartDate] = useState<string>(today);
  const [meetingEndDate, setMeetingEndDate] = useState(meetingStartDate);
  const [startingTime, setStartingTime] = useState(timeStringStarting);
  const [endingTime, setEndingTime] = useState(timeStringStarting);
  const [description, setDescription] = useState("");
  const [selectedMeetingAttendees, setSelectedMeetingAttendees] = useState<any>(
    []
  );
  const [selectedModerators, setSelectedModerators] = useState<any>([]);
  const [invites, setInvites] = useState<VTInviteObject>();
  const [meetingTitleError, setMeetingTitleError] = useState<boolean>(false);
  const [meetingTitleLengthErrorTooShort, setMeetingTitleLengthErrorTooShort] =
    useState<boolean>(false);
  const [meetingTitleLengthErrorTooLong, setMeetingTitleLengthErrorTooLong] =
    useState<boolean>(false);
  const [dateTimeRangeError, setDateTimeRangeError] = useState<boolean>(false);
  const [dateDayError, setDateDayError] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [recurrenceChecked, setRecurrenceChecked] = useState<boolean>(false);

  const meetingStartTime = `${meetingStartDate}T${startingTime}`;
  const meetingEndTime = `${meetingEndDate}T${endingTime}`;
  const formattedStartDateAndTime = parse(
    meetingStartTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );
  const formattedEndDateAndTime = parse(
    meetingEndTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );

  const unixStartTime = Math.floor(formattedStartDateAndTime?.getTime() / 1000);
  const unixEndTime = Math.floor(formattedEndDateAndTime?.getTime() / 1000);
  const duration = unixEndTime - unixStartTime;

  const day = parseInt(meetingStartDate?.slice(8, 10));

  //States used for Repeat Meeting
  const [frequency, setFrequency] = useState("Never");
  const [byMonth, setByMonth] = useState("1");
  const [byMonthDay, setByMonthDay] = useState(day);
  const [byDay, setByDay] = useState<any[]>([]);
  const [byDayWeekly, setByDayWeekly] = useState("");
  const [interval, setInterval] = useState(1);
  const [bySetPos, setBySetPos] = useState("1");
  const [count, setCount] = useState<number>(9999);
  const [reoccuringMeetingEnd, setReoccuringMeetingEnd] = useState<Date>(
    new Date()
  );
  const [repeatMeeting, setRepeatMeeting] = useState<string>("no");
  const [dtStart, setDtStart] = useState<Date>(todayDate);
  const [until, setUntil] = useState<Date>(todayDate);
  const [rRule, setRRule] = useState("");
  const [activeDaysString, setActiveDaysString] = useState<string>("");
  const [monthString, setMonthString] = useState<string>("");
  const [dayString, setDayString] = useState<string>("");
  const [rruleIsSaved, setRruleIsSaved] = useState<boolean>(false);
  const [recurrenceModalIsOpen, setRecurrenceModalIsOpen] =
    useState<boolean>(false);

  //UseEffect to make sure that the end Date is always after the start Date.
  // useEffect(() => {
  //   if (
  //     parseInt(meetingEndDate.split("-").join("")) <
  //     parseInt(meetingStartDate.split("-").join(""))
  //   ) {
  //     setMeetingEndDate(meetingStartDate);
  //   }
  // }, [meetingEndDate, meetingStartDate]);

  useEffect(() => {
    if (checked) {
      setStartingTime("00:00:00");
      setEndingTime("23:55:00");
      setRRule("");
    } else {
      setStartingTime(timeStringStarting);
    }
  }, [checked]);

  //*Update Meeting End Date based on change of meeting Start Date*/
  // useEffect(() => {
  //   setMeetingEndDate(meetingStartDate);
  // }, [meetingStartDate]);

  // useEffect to handle with 9:30 bug of start time.
  useEffect(() => {
    if (timeStringStarting.length === 7) {
      setStartingTime(`0${timeStringStarting}`);
    }
  }, [timeStringStarting, checked]);

  //Filter out current user along with the users that are selected
  useEffect(() => {
    getAllUsersInformation()
      .then((userList: OtherUsers[]) => {
        const selectedUsers = Array.from(
          new Set([...selectedMeetingAttendees, ...selectedModerators])
        );

        const allUsersWithoutMe = userList.filter(
          (allOtherUsers: OtherUsers) => {
            if (user) {
              return allOtherUsers.user_id !== user.user_id;
            }
          }
        );

        // //Expecting an array that does not include the selected users
        const allUnselectedUsers = [...allUsersWithoutMe].filter(
          (unselected: OtherUsers) => {
            return !selectedUsers
              .map((u) => u.user_id)
              .includes(unselected.user_id);
          }
        );

        setUsers(allUnselectedUsers);
      })
      .catch((err) => {
        console.log("this is the error of getting all users information", err);
      });
  }, [selectedMeetingAttendees, selectedModerators]);

  useEffect(() => {
    if (meetingStartDate < today) {
      setDateDayError(true);
    } else {
      setDateDayError(false);
    }
  }, [meetingStartDate]);

  useEffect(() => {
    const attendeeInvites: [
      {
        invitee_id: string;
        moderator: boolean;
        invitor: boolean;
        editor: boolean;
      }
    ] = selectedMeetingAttendees.map((attendee: any) => {
      return {
        invitee_id: attendee.id,
        moderator: false,
        invitor: false,
        editor: false,
      };
    });

    const moderatorInvites: [
      {
        invitee_id: string;
        moderator: boolean;
        invitor: boolean;
        editor: boolean;
      }
    ] = selectedModerators.map((moderator: any) => {
      return {
        invitee_id: moderator.id,
        moderator: true,
        invitor: false,
        editor: true,
      };
    });
    const allInvites: {
      invitee_id: string;
      moderator: boolean;
      invitor: boolean;
      editor: boolean;
    }[] = [...attendeeInvites, ...moderatorInvites];
    const invitesObject: any = {
      invitees: allInvites,
      message: description,
    };

    setInvites(invitesObject);
  }, [selectedMeetingAttendees, selectedModerators]);

  // FUNCTION/REQUEST TO HANDLE CREATE MEETING
  const saveMeeting = async () => {
    const params: {
      description: string;
      start: number;
      duration: number;
      rrule?: string;
    } = {
      description: description,
      rrule: rRule,
      start: unixStartTime,
      duration: duration,
    };
    createMeeting(meetingTitle, unixStartTime, duration, invites, params)
      .then((data: any) => {
        setNeedsRefresh(true);
        setSaveMeetingIsClicked(true);
        setSnackBarIsOpenForCreateMeeting(true);
        setGlobalDate(formattedStartDateAndTime);
        setCreateMeetingModalIsOpen(false);
      })
      .catch((error: any) => {
        if (error.includes("PARAMETER_REQUIRED")) {
          alert("Error creating a meeting");
          setMeetingTitleError(true);
        } else if (error.includes("INVALID_INTEGER_RANGE")) {
          setDateTimeRangeError(true);
          alert("Please enter a valid duration of atleast 30 minutes");
        } else if (error.includes("INVALID_MIN_CHAR_LENGTH")) {
          setMeetingTitleLengthErrorTooShort(true);
          alert("Please enter a meeting title longer than 2 characters");
        } else if (error.includes("INVALID_MAX_CHAR_LENGTH")) {
          setMeetingTitleLengthErrorTooShort(false);
          setMeetingTitleLengthErrorTooLong(true);
          alert("Please enter a meeting title less than 50 characters");
        } else {
          alert("Error Creating Meeting");
        }
      });
  };

  const handleCloseButton = () => {
    // router.push("/meetings");
    setCreateMeetingModalIsOpen(false);
  };

  return (
    <>
      <Modal open={createMeetingModalIsOpen} onClose={handleCloseButton}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            right: 0,
            width: { xs: "100%", md: "75%", lg: "65%", xl: "40%" },
            height: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // overflow: "hidden",
              //   paddingLeft: "83.3px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "75px",
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    height: "39px",
                    width: "100%",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "32.15px",
                  }}
                >
                  New meeting
                </Box>
              </Box>

              <Close handleCloseButton={handleCloseButton} />
            </Box>

            <MeetingTitle
              createMeetingModalIsOpen={createMeetingModalIsOpen}
              meetingTitle={meetingTitle}
              meetingTitleError={meetingTitleError}
              setMeetingTitleError={setMeetingTitleError}
              setMeetingTitle={setMeetingTitle}
              meetingTitleLengthErrorTooShort={meetingTitleLengthErrorTooShort}
              meetingTitleLengthErrorTooLong={meetingTitleLengthErrorTooLong}
            />

            <Attendees
              selectedMeetingAttendees={selectedMeetingAttendees}
              setSelectedMeetingAttendees={setSelectedMeetingAttendees}
              users={users}
              setUsers={setUsers}
            />

            <Moderators
              users={users}
              setUsers={setUsers}
              selectedModerators={selectedModerators}
              setSelectedModerators={setSelectedModerators}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MeetingTime
                checked={checked}
                setChecked={setChecked}
                meetingStartDate={meetingStartDate}
                setMeetingStartDate={setMeetingStartDate}
                meetingEndDate={meetingEndDate}
                setMeetingEndDate={setMeetingEndDate}
                startingTime={startingTime}
                setStartingTime={setStartingTime}
                endingTime={endingTime}
                setEndingTime={setEndingTime}
                dateTimeRangeError={dateTimeRangeError}
                dateDayError={dateDayError}
              />
            </Box>

            <RepeatMeeting
              editMeetingModalIsOpen={editMeetingModalIsOpen}
              checked={checked}
              frequency={frequency}
              byMonth={byMonth}
              byMonthDay={byMonthDay}
              byDay={byDay}
              bySetPos={bySetPos}
              interval={interval}
              count={count}
              until={until}
              setCount={setCount}
              setUntil={setUntil}
              setFrequency={setFrequency}
              setByMonth={setByMonth}
              setByMonthDay={setByMonthDay}
              setByDay={setByDay}
              setInterval={setInterval}
              setBySetPos={setBySetPos}
              byDayWeekly={byDayWeekly}
              setByDayWeekly={setByDayWeekly}
              repeatMeeting={repeatMeeting}
              setRepeatMeeting={setRepeatMeeting}
              meetingStartDate={meetingStartDate}
              setMeetingStartDate={setMeetingStartDate}
              dtStart={dtStart}
              setDtStart={setDtStart}
              setRRule={setRRule}
              reoccuringMeetingEnd={reoccuringMeetingEnd}
              setReoccuringMeetingEnd={setReoccuringMeetingEnd}
              startingTime={startingTime}
              activeDaysString={activeDaysString}
              setActiveDaysString={setActiveDaysString}
              dayString={dayString}
              setDayString={setDayString}
              monthString={monthString}
              setMonthString={setMonthString}
              setRruleIsSaved={setRruleIsSaved}
              recurrenceChecked={recurrenceChecked}
              setRecurrenceChecked={setRecurrenceChecked}
              recurrenceModalIsOpen={recurrenceModalIsOpen}
              setRecurrenceModalIsOpen={setRecurrenceModalIsOpen}
            />

            {rruleIsSaved ? (
              <RepeatMeetingString
                activeDaysString={activeDaysString}
                repeatMeeting={repeatMeeting}
                dayString={dayString}
                monthString={monthString}
                meetingStartDate={meetingStartDate}
                byMonthDay={byMonthDay}
                setDayString={setDayString}
                recurrenceModalIsOpen={recurrenceModalIsOpen}
                setRecurrenceModalIsOpen={setRecurrenceModalIsOpen}
                recurrenceChecked={recurrenceChecked}
                setRecurrenceChecked={setRecurrenceChecked}
              />
            ) : (
              <></>
            )}

            <Description
              createMeetingModalIsOpen={createMeetingModalIsOpen}
              description={description}
              setDescription={setDescription}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                borderRadius: "4px",
              }}
            >
              <Save
                saveMeeting={saveMeeting}
                createMeetingModalIsOpen={createMeetingModalIsOpen}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateMeeting;
