///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING MONTHLY MEETING CARD
///
///////////////////////////////////////////////////////////////

import { useState, useEffect } from "react";

//TIME LIBRARY
import { format, getUnixTime } from "date-fns";

// MUI COMPONENTS
import { Box, Avatar, Stack, Typography, Button } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TodayIcon from "@mui/icons-material/Today";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InviteStatusButtons from "./Components/InviteStatusButtons";
import RepeatIcon from "@mui/icons-material/Repeat";
import Fade from "@mui/material/Fade";

// API COMPONENTS
import { meetingsStore, userStore } from "@state/store";

import { getCurrentUserInfo } from "../../../../@cognito";
import { VTInvite } from "../../../../types";
import { VTMeetings } from "../../../../types";

//CUSTOM COMPONENTS
import RSVPPopover from "../../RSVPPopover";
import JoinMeetingButton from "./Components/JoinMeetingButton";
import StatusIndicator from "../StatusIndicator";

//STYLES
import styles from "./index.module.css";
import theme from "@components/VisionableTheme";

//UTILITY FUNCTIONS
import { getOwnerInitials } from "@utilities";
///////////////////////////////////////////////////////////////
///
///  DAILY CARD COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTMeetingCardProps = {
  meeting: VTMeetings;
  selection: any;
  handleSelection: Function;
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
  handleExpansionClick: ({ uuid, date }: any) => void;
};

const DailyCard = ({
  meeting,
  selection,
  handleSelection,
  needsRefresh,
  setNeedsRefresh,
  handleExpansionClick,
}: VTMeetingCardProps) => {
  // GLOBAL STATE CONTEXT
  const { user, setUser } = userStore();
  const { globalDate } = meetingsStore();

  //  STATE
  const [userId, setUserId] = useState<string>("");
  const [liveStatus, setLiveStatus] = useState(false);
  const [inPastStatus, setInPastStatus] = useState(false);
  const [isReccuring, setIsReccuring] = useState(false);
  const [isDailyCardLoaded, setIsDailyCardLoaded] = useState<boolean>(false);
  const [isOutlookGenerated, setIsOutlookGenerated] = useState<boolean>(false);

  // DATE TIME OPERATIONS

  const formattedDate = new Date(meeting.start * 1000);
  const formattedEndDate = new Date(
    meeting.start * 1000 + meeting.duration * 1000
  );
  const meetingStartTime = format(new Date(formattedDate), "HH:mm");
  const meetingEndTime = format(new Date(formattedEndDate), "HH:mm");
  const meetingFormatedDate = formattedDate.toLocaleString("en-uk", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // const showJoinButton =
  //   now > meeting.start - 900 && now < meeting.start + meeting.duration;

  const inviteArray: any = meeting.invites.filter((invite: VTInvite) => {
    if (invite.invitee_id === user?.user_id) {
      return invite.invite_id;
    }
  });
  const inviteId: string = inviteArray.map((invite: VTInvite) => {
    return invite.invite_id;
  });

  const userStatusArray: any = meeting.invites.filter((invite: VTInvite) => {
    if (invite?.invitee_id === userId) {
      return invite.status;
    }
  });

  const userStatus: string = userStatusArray.map((invite: VTInvite) => {
    return invite.status;
  });

  const isAllDay =
    (meetingStartTime === "0:00" || meetingStartTime === "00:00") &&
    (meetingEndTime === "23:30" ||
      meetingEndTime === "23:00" ||
      meetingEndTime === "23:55");

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // GET USER MEETING STATUS
  const getUserStatus = (meeting: VTMeetings) => {
    if (meeting.invites) {
      const userStatusArray: any = meeting?.invites.filter(
        (invite: VTInvite) => {
          if (invite?.invitee_id === userId) {
            return invite.status;
          }
        }
      );
      const userStatus: string = userStatusArray?.map((invite: VTInvite) => {
        return invite.status;
      });

      return userStatus;
    }
  };

  // GET USER INVITE ID
  const getInviteId = (meeting: VTMeetings) => {
    if (meeting.invites) {
      const inviteArray: any = meeting.invites.filter((invite: VTInvite) => {
        if (invite.invitee_id === user?.user_id) {
          return invite.invite_id;
        }
      });
      const inviteId: string = inviteArray.map((invite: VTInvite) => {
        return invite.invite_id;
      });

      return inviteId;
    }
  };

  // DETERMINE IF MEETING IS CURENTLY LIVE
  const isMeetingLive = () => {
    const currentTime = new Date().getTime();
    const meetingStartTime = new Date(formattedDate).getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();
    const meetingDuration = meeting.duration;

    if (
      currentTime < meetingStartTime - 900 ||
      currentTime > meetingEndTime + meetingDuration
    ) {
      setLiveStatus(false);
    } else {
      setLiveStatus(true);
    }
  };

  // DETERMINE IF MEETING IS IN THE PAST
  const isMeetingPast = () => {
    const currentTime = new Date().getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();

    if (currentTime > meetingEndTime) {
      setInPastStatus(true);
    } else {
      setInPastStatus(false);
    }
  };
  //DETERMINE IF MEETING IS RECCURNING
  const isMeetingReccuring = (meeting: VTMeetings) => {
    if (meeting.rrule === "" || meeting.rrule === null) {
      setIsReccuring(false);
    } else {
      setIsReccuring(true);
    }
  };

  // DETERMINE IF MEETING IS AN OUTLOOK MEETING
  const isMeetingOutlookGenerated = (meeting: VTMeetings) => {
    if (meeting.external_id != null) {
      setIsOutlookGenerated(true);
    } else {
      setIsOutlookGenerated(false);
    }
  };
  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  /**Get Cognito User Info*/
  useEffect(() => {
    const getCurrentUser = async () => {
      const user = await getCurrentUserInfo();
      setUserId(user.attributes.sub);
    };

    getCurrentUser();
  }, []);

  useEffect(() => {
    if (inviteId.length > 0 && userStatus.length > 0 && meeting) {
      setIsDailyCardLoaded(true);
    } else {
      setIsDailyCardLoaded(false);
    }
  }, [inviteId, userStatus, inviteArray, meeting]);

  useEffect(() => {
    isMeetingLive();
    isMeetingPast();
    isMeetingReccuring(meeting);
    isMeetingOutlookGenerated(meeting);
  }, [meeting]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    // Daily Meeting Card
    <>
      {isDailyCardLoaded ? (
        <Fade in={true}>
          <Box
            key={meeting.meeting_id}
            id={meeting.meeting_id as any}
            onClick={(event: any) => {
              handleSelection(event);
            }}
            component="div"
            onDoubleClick={() =>
              handleExpansionClick({
                uuid: meeting.meeting_id,
                date: getUnixTime(globalDate),
              })
            }
          >
            {/* Meeting is a past meeting */}
            {inPastStatus ? (
              <Stack
                direction="row"
                m={0.5}
                mb={1}
                sx={{
                  background: theme.palette.grey[500],
                  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                }}
              >
                <Stack
                  sx={{ visibility: "hidden" }}
                  direction="row"
                  p={0.5}
                  m={0.5}
                >
                  <StatusIndicator status={userStatus} />
                </Stack>
                <Stack
                  width={1}
                  p={1}
                  display="flex"
                  direction="column"
                  alignItems="flex-start"
                  spacing={0.5}
                >
                  {/* Meeting Title Section */}
                  <Box
                    p={1}
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                      color: "#FFFFFF",
                    }}
                  >
                    {meeting.name}
                  </Box>

                  {/* Organizer Section */}
                  <Stack
                    p={1}
                    width={1}
                    direction="row"
                    className={styles.meetingCardOrganizerSection}
                  >
                    {userId === meeting.owner_id ? (
                      <Stack width={1} spacing={1} direction="row">
                        <Box>
                          <Avatar
                            {...getOwnerInitials(
                              `${
                                meeting.owner_first_name +
                                " " +
                                meeting.owner_last_name
                              }`,
                              "small",
                              true
                            )}
                          />
                        </Box>

                        <Box> Organized by you</Box>
                      </Stack>
                    ) : (
                      <Stack width={1} spacing={1} direction="row">
                        <Box>
                          <Avatar
                            {...getOwnerInitials(
                              `${
                                meeting.owner_first_name +
                                " " +
                                meeting.owner_last_name
                              }`,
                              "small",
                              true
                            )}
                          />
                        </Box>

                        <Typography>
                          {" "}
                          Invited by{" "}
                          {meeting.owner_first_name +
                            " " +
                            meeting.owner_last_name}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>

                  {/* Display Time Section */}
                  <Stack
                    p={1}
                    width={1}
                    direction="row"
                    spacing={1}
                    className={styles.meetingCardTimesSection}
                  >
                    <Stack
                      direction="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TodayIcon
                        fontSize="small"
                        sx={{ color: theme.palette.background.paper }}
                      ></TodayIcon>
                      &nbsp;
                      <Typography color={theme.palette.background.paper}>
                        {meetingFormatedDate}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <AccessTimeIcon
                        fontSize="small"
                        sx={{ color: theme.palette.background.paper }}
                      />
                      &nbsp;
                      {isAllDay ? (
                        <>
                          <Typography
                            noWrap
                            color={theme.palette.background.paper}
                          >
                            All Day
                          </Typography>
                          &nbsp;
                          <RepeatIcon
                            fontSize="small"
                            sx={{
                              color: theme.palette.background.paper,
                              display: isReccuring ? "inline" : "none",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Typography
                            noWrap
                            color={theme.palette.background.paper}
                          >
                            {meetingStartTime} - {meetingEndTime}
                          </Typography>
                          &nbsp;
                          <RepeatIcon
                            fontSize="small"
                            sx={{
                              color: theme.palette.background.paper,
                              display: isReccuring ? "inline" : "none",
                            }}
                          />
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Stack>
                    {isOutlookGenerated ? (
                      <Stack
                        p={1}
                        alignItems={"center"}
                        sx={{
                          borderRadius: "3px",
                          width: "100%",
                        }}
                        spacing={1}
                        direction="row"
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{
                            color: theme.palette.background.paper,
                          }}
                        />
                        <Typography color={theme.palette.background.paper}>
                          Created in Outlook
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>

                <Stack
                  p={1}
                  width={1}
                  direction="column"
                  justifyContent="start"
                  sx={{ display: inPastStatus ? "none" : "inline" }}
                >
                  {/* // Meeting controls section */}
                  {userId === meeting.owner_id ? (
                    <Stack
                      width={1}
                      p={1}
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <JoinMeetingButton meeting={meeting} />
                    </Stack>
                  ) : (
                    <Stack
                      width={1}
                      p={1}
                      direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <>
                        {userStatus[0] != "invited" ? (
                          <JoinMeetingButton meeting={meeting} />
                        ) : null}
                      </>
                      <>
                        {userStatus[0] === "invited" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              width: "180px",
                            }}
                          >
                            <RSVPPopover
                              needsRefresh={needsRefresh}
                              meeting={meeting}
                              inviteId={inviteId}
                              setNeedsRefresh={setNeedsRefresh}
                            />
                          </Box>
                        ) : (
                          <Box sx={{ width: "180px" }}>
                            <InviteStatusButtons
                              meeting={meeting}
                              inviteId={inviteId}
                              userStatus={userStatus}
                            />
                          </Box>
                        )}
                      </>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ) : (
              // Meeting is a present Meeting
              <Stack
                sx={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
                style={
                  liveStatus
                    ? {
                        background: "#00AEEF1A",
                        boxShadow: "0px 0px 3px #1271EE",
                      }
                    : {}
                }
                direction={{ xs: "column", sm: "row", md: "row" }}
                m={0.5}
                mb={1}
                spacing={2}
              >
                <Stack direction="row">
                  <Stack direction="row" p={0.5} m={0.5}>
                    <StatusIndicator status={userStatus} />
                  </Stack>

                  <Stack
                    width={1}
                    p={1}
                    display="flex"
                    direction="column"
                    alignItems="flex-start"
                  >
                    {/* Meeting Title Section */}
                    <Box p={1} className={styles.meetingCardMeetingNameSection}>
                      {meeting.name}
                    </Box>

                    {/* Organizer Section */}
                    <Stack
                      p={1}
                      width={1}
                      direction="row"
                      className={styles.meetingCardOrganizerSection}
                    >
                      {userId === meeting.owner_id ? (
                        <Stack width={1} spacing={1} direction="row">
                          <Box>
                            <Avatar
                              {...getOwnerInitials(
                                `${
                                  meeting.owner_first_name +
                                  " " +
                                  meeting.owner_last_name
                                }`,
                                "small"
                              )}
                            />
                          </Box>

                          <Box> Organized by you</Box>
                        </Stack>
                      ) : (
                        <Stack width={1} spacing={1} direction="row">
                          <Box>
                            <Avatar
                              {...getOwnerInitials(
                                `${
                                  meeting.owner_first_name +
                                  " " +
                                  meeting.owner_last_name
                                }`,
                                "small"
                              )}
                            />
                          </Box>

                          <Box>
                            {" "}
                            Invited by{" "}
                            {meeting.owner_first_name +
                              " " +
                              meeting.owner_last_name}
                          </Box>
                        </Stack>
                      )}
                    </Stack>

                    {/* Display Time Section */}
                    <Stack
                      p={1}
                      direction={{ xs: "row", sm: "row", md: "row" }}
                      spacing={1}
                      className={styles.meetingCardTimesSection}
                    >
                      <Stack
                        direction="row"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <TodayIcon fontSize="small" />
                        &nbsp;
                        <Typography noWrap>{meetingFormatedDate}</Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <AccessTimeIcon fontSize="small" /> &nbsp;
                        {isAllDay ? (
                          <>
                            <Typography noWrap>All Day</Typography>
                            &nbsp;
                            <RepeatIcon
                              fontSize="small"
                              sx={{
                                display: isReccuring ? "inline" : "none",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Typography noWrap>
                              {meetingStartTime} - {meetingEndTime}
                            </Typography>
                            &nbsp;
                            <RepeatIcon
                              fontSize="small"
                              sx={{
                                display: isReccuring ? "inline" : "none",
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    </Stack>
                    {isOutlookGenerated ? (
                      <Stack
                        p={1}
                        alignItems={"center"}
                        sx={{
                          background: "rgba(18, 113, 238, 0.1)",
                          borderRadius: "3px",
                          width: "100%",
                        }}
                        spacing={1}
                        direction="row"
                      >
                        <InfoOutlinedIcon fontSize="small" />
                        <Typography>Created in Outlook</Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>

                <Stack
                  p={1}
                  width={1}
                  direction="column"
                  justifyContent="start"
                >
                  {/* // Meeting controls section */}
                  {userId === meeting.owner_id ? (
                    <Stack
                      className="test"
                      width={{ xs: "100%" }}
                      p={1}
                      direction="column"
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      alignItems={{ xs: "center", sm: "flex-end" }}
                      spacing={1}
                    >
                      <JoinMeetingButton meeting={meeting} />
                    </Stack>
                  ) : (
                    <Stack
                      width={{ xs: "100%" }}
                      p={1}
                      direction={{
                        xs: "row",
                        sm: "column",
                        md: "column",
                        lg: "column",
                        xl: "row",
                      }}
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      alignItems={{ xs: "center", sm: "flex-end" }}
                      spacing={1}
                      sx={{ display: "flex" }}
                    >
                      <>
                        {userStatus[0] != "invited" ? (
                          <JoinMeetingButton meeting={meeting} />
                        ) : null}
                      </>
                      <>
                        {userStatus[0] === "invited" ? (
                          <Box
                            justifyContent={{ xs: "center", sm: "flex-end" }}
                            alignItems={{ xs: "center", sm: "flex-end" }}
                            sx={{
                              display: "flex",
                              width: { xs: "100%", sm: "180px" },
                            }}
                          >
                            <RSVPPopover
                              needsRefresh={needsRefresh}
                              meeting={meeting}
                              inviteId={inviteId}
                              setNeedsRefresh={setNeedsRefresh}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <InviteStatusButtons
                              meeting={meeting}
                              inviteId={inviteId}
                              userStatus={userStatus}
                            />
                          </Box>
                        )}
                      </>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}
          </Box>
        </Fade>
      ) : (
        <></>
      )}
    </>
  );
};

export default DailyCard;
