///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING DAILY MEETING VIEW
///
///////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";

//TIME LIBRARY
import {
  format,
  isToday,
  isSameDay,
  startOfDay,
  getUnixTime,
  endOfDay,
  compareAsc,
} from "date-fns";

// MUI COMPONENTS
import { Box, Stack } from "@mui/material";

//SKELETON
import { DailyMeetingsSkeleton } from "@components/Skeletons";

// API COMPONENTS
import { VTMeetings, VTSingleMeeting } from "../../../../types";
import NoMeetingComponent from "../../NoMeeting";

// GLOBAL STATE
import { meetingsStore } from "@state/store";

// CUSTOM COMPONENTS
import DailyCard from "../DailyCard";
import DaySelector from "@components/MeetingComponents/MeetingStateViewComponents/DaySelector";

// RRULE LIBRARY
import { RRule, rrulestr } from "rrule";

///////////////////////////////////////////////////////////////
///
///  DAILY VIEW COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTDailyViewProps = {
  editMeetingModalIsOpen: boolean;
  meetings: any;
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
  handleExpansionClick: ({ uuid, date }: any) => void;
};

const DailyView = ({
  editMeetingModalIsOpen,
  needsRefresh,
  meetings,
  setNeedsRefresh,
  handleExpansionClick,
}: VTDailyViewProps) => {
  // GLOBAL STATE
  const { globalDate, setGlobalDate, meetingsLoading, setMeetingsLoading } =
    meetingsStore();

  //STATE
  const [dailyMeetings, setDailyMeetings] = useState<any>([]);
  const [selection, setSelection] = useState<any>("");

  // DATE TIME OPERATIONS
  const yesterday = new Date(globalDate.getTime() - 24 * 60 * 60 * 1000);
  const tomorrow = new Date(globalDate.getTime() + 24 * 60 * 60 * 1000);

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // Allows to click on a meeting based on id currently not used
  // will be used for popup modal dialog (not implemented)
  const handleSelection = (event: any) => {
    setSelection(event.currentTarget.id);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION (MEETINGS DATASTRUCTURE)
  ///  FORMATS MEETINGS TO DISPLAY FOR PARTICULAR DAY OF THE WEEK
  ///
  ///////////////////////////////////////////////////////////////

  // GET MEETING INSANCE ON A GIVEN DATE IF MMETING FALLS ON THAT DATE GIVEN RRULE
  const getTodaysOccurance = (date: Date, meeting: any) => {
    try {
      const rule = rrulestr(meeting.rrule, {
        forceset: true,
      });

      const start = startOfDay(date);

      const end = endOfDay(date);

      const copy = JSON.parse(JSON.stringify(meeting));

      // @ts-ignore
      const exDates = rule.exdates();
      const occurrences = rule.between(start, end, true);

      //changed this from copy.start to meeting.start
      if (occurrences.length > 0) {
        copy.start = getUnixTime(occurrences[0]);
        return copy;
      } else {
        return false;
      }
    } catch (e) {
      //console.log(e);
    }
  };

  // GET ALL RECCURNING  MEETINGS ON A GIVEN DATE
  const getTodaysReccuringMeetings = (meetings: VTSingleMeeting[]) => {
    const reccuringMeetings: VTSingleMeeting[] = [];

    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "" || meeting.rrule !== null) {
        const todaysInstance = getTodaysOccurance(globalDate, meeting);
        if (todaysInstance) {
          reccuringMeetings.push(todaysInstance);
        }
        return;
      }
      return;
    });
    return reccuringMeetings;
  };
  // REMOVE MEETINGS THAT SATISFY EXDATE
  const removeExdateMeetings = (meetings: any) => {
    const filteredMeetings: any = [];
    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "") {
        const rule = rrulestr(meeting.rrule, {
          forceset: true,
        });
        // @ts-ignore
        const exDates = rule.exdates();
        let match = false;
        exDates.forEach((date: any) => {
          if (isSameDay(date, new Date(meeting.start * 1000))) {
            match = true;
          }
        });
        if (!match) {
          filteredMeetings.push(meeting);
        }
      } else {
        filteredMeetings.push(meeting);
      }
    });
    return filteredMeetings;
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // PREPROCESS MEETINGS TO DISPLAY ON GIVEN DATE
  useEffect(() => {
    // reset meetings array
    setDailyMeetings([]);

    // copy of meetings
    const allMeetings = JSON.parse(JSON.stringify(meetings));

    // none reccuring meetings on selected date
    const todaysRegularMeetings = allMeetings
      .filter(
        (meeting: VTMeetings) =>
          new Date(meeting.start * 1000).toDateString() ===
          globalDate.toDateString()
      )
      .filter(
        (meeting: VTMeetings) => meeting.rrule === "" || meeting.rrule === null
      );

    // reccuring meetings on selected date
    let todaysReccuringMeetings = getTodaysReccuringMeetings(allMeetings);

    // remove meetings that satisfy exdate constraints
    todaysReccuringMeetings = removeExdateMeetings(todaysReccuringMeetings);

    setDailyMeetings([...todaysReccuringMeetings, ...todaysRegularMeetings]);

    setMeetingsLoading(false);
  }, [meetings, globalDate]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Stack>
      {/* NAVIGAION BUTTONS */}
      <Stack display={{ xs: "none", lg: "inline" }}>
        <DaySelector />
      </Stack>
      {/* DAILY SHEDULE VIEW */}
      <Box p={[0, 0, 0, 1, 1]}>
        {meetingsLoading && false ? (
          <Stack>
            <DailyMeetingsSkeleton />
            <DailyMeetingsSkeleton />
            <DailyMeetingsSkeleton />
          </Stack>
        ) : (
          <Stack sx={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
            {dailyMeetings.length > 0 ? (
              <Stack>
                {dailyMeetings
                  .sort((a: any, b: any) =>
                    compareAsc(new Date(a.start), new Date(b.start))
                  )

                  .map((meeting: VTMeetings) => (
                    <DailyCard
                      selection={selection}
                      handleSelection={handleSelection}
                      key={meeting.meeting_id}
                      meeting={meeting}
                      needsRefresh={needsRefresh}
                      setNeedsRefresh={setNeedsRefresh}
                      handleExpansionClick={handleExpansionClick}
                    />
                  ))}
              </Stack>
            ) : (
              <Box p={2}>
                <Stack>
                  <NoMeetingComponent />
                </Stack>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default DailyView;
