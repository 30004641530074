///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING MEETING DETAILS POPER
///  ON WEEKLY VIEW MEETING CARD
///
///////////////////////////////////////////////////////////////

import { useRouter } from "next/router";

// MUI COMPONENTS
import { useEffect, useState, useRef, cloneElement, useContext } from "react";
import {
  Box,
  Stack,
  Skeleton,
  Avatar,
  AvatarGroup,
  Theme,
  Popper,
  Grow,
  Divider,
  Paper,
  Typography,
  Button,
  Link,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TodayIcon from "@mui/icons-material/Today";
import EventNoteIcon from "@mui/icons-material/EventNote";
import GroupsIcon from "@mui/icons-material/Groups";
import ClickAwayListener from "@mui/base/ClickAwayListener";

// STYLES
import { makeStyles } from "@mui/styles";

// API COMPONENTS
import { getCurrentUserInfo } from "@cognito";
import { userStore, meetingsStore } from "@state/store";

//TIME LIBRARY
import { format, fromUnixTime } from "date-fns";

//REQUESTS
import { updateMeetingInvite } from "@requests/Meetings";
import { getMeeting } from "@requests/Meetings";

//CUSTOM COMPONENTS
import InviteStatusButtons from "../MeetingStateViewComponents/DailyCard/Components/InviteStatusButtons";
import { Router } from "next/router";
import theme from "@components/VisionableTheme";
import { MeetingPopupSkeleton } from "@components/Skeletons";

//UTILITY FUNCTIONS
import { getOwnerInitials } from "@utilities";

//TYPES
import { VTInvite, VTMeetings } from "types";

///////////////////////////////////////////////////////////////
///
///  DETAILED MEETINGS POPOVER COMPONENT
///
///////////////////////////////////////////////////////////////
export type MeetingCard = {
  open: boolean;
  anchorEl: any;
  placement: any;
  arrow?: any;
  handlePopupClose: Function;
};

///////////////////////////////////////////////////////////////
///
///  CUSTOM STYLES SECTION
///
///////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme: Theme) => {
  const color = "white"; // Feel free to customise this like they do in Tooltip
  return {
    background: {
      zIndex: -400,
      borderRadius: "10px",
      maxWidth: "434px",
      minWidth: "380px",
      maxHeight: "537px",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    },
    popoverRoot: {
      zIndex: 400,
      backgroundColor: "color",
      maxWidth: "434px",
      minWidth: "380px",
      maxHeight: "537px",
      borderRadius: "10px",
    },
    content: {
      padding: "5%",
      borderRadius: "10px",
      zIndex: 3000,
      maxWidth: "434px",
      maxHeight: "537px",
      minWidth: "380px",
      backgroundColor: "color",
    },
    popper: {
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "0 100%",
        },
      },
      '&[data-popper-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "100% 0",
          border: "1px solid var(--visionable-border-gray)",
        },
      },
      '&[data-popper-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "100% 100%",
          border: "1px solid var(--visionable-border-gray)",
        },
      },
      '&[data-popper-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "0 0",
          border: "1px solid var(--visionable-border-gray)",
        },
      },
    },
    arrow: {
      zIndex: -200,
      position: "absolute",
      width: "2em",
      height: "1em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",

      border: "none",
      "&::before": {
        zIndex: -200,
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        backgroundColor: color,
        transform: "rotate(45deg)",
      },
    },
  };
});

const MeetingModal = ({
  arrow = true,
  open,
  anchorEl,
  placement,
  handlePopupClose,
}: MeetingCard) => {
  const classes = useStyles();
  //GLOBAL STATE
  const { globalDate, setGlobalDate } = meetingsStore();
  const { user, setUser } = userStore();

  //STATE
  const [meeting, setMeeting] = useState<VTMeetings | null>(null);
  const [meetingStartTime, setMeetingStartTime] = useState<string>();
  const [meetingEndTime, setMeetingEndTime] = useState<string>();
  const [meetingFormatedDate, setMeetingFormatedDate] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userStatus, setUserStatus] = useState<string | undefined>("");
  const [inviteId, setInviteId] = useState<string | undefined>("");
  const [inPastStatus, setInPastStatus] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(user?.user_id);

  //ROUTER
  const router = useRouter();

  //REFS
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // GET USER MEETING STATUS
  const getUserStatus = (meeting: VTMeetings) => {
    if (meeting.invites) {
      const userStatusArray: any = meeting?.invites.filter(
        (invite: VTInvite) => {
          if (invite?.invitee_id === userId) {
            return invite.status;
          }
        }
      );
      const userStatus: string = userStatusArray?.map((invite: VTInvite) => {
        return invite.status;
      });

      return userStatus;
    }
  };

  // GET USER INVITE ID
  const getInviteId = (meeting: VTMeetings) => {
    if (meeting.invites) {
      const inviteArray: any = meeting.invites.filter((invite: VTInvite) => {
        if (invite.invitee_id === user?.user_id) {
          return invite.invite_id;
        }
      });
      const inviteId: string = inviteArray.map((invite: VTInvite) => {
        return invite.invite_id;
      });

      return inviteId;
    }
  };

  // DETERMINE IF MEETING IS IN THE PAST
  const isMeetingPast = (formattedEndDate: Date) => {
    const currentTime = new Date().getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();

    if (currentTime > meetingEndTime) {
      setInPastStatus(true);
    } else {
      setInPastStatus(false);
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // FETCH MEETING DETAILS
  useEffect(() => {
    if (anchorEl) {
      setLoading(true);
      getMeeting(anchorEl?.id)
        .then((meeting) => {
          setMeeting(meeting);

          // DATE TIME OPERATIONS
          const now = new Date().getTime() / 1000;
          const formattedDate = new Date(meeting.start * 1000);
          const formattedEndDate = new Date(
            meeting.start * 1000 + meeting.duration * 1000
          );
          const meetingStartTime = format(new Date(formattedDate), "HH:mm");
          const meetingEndTime = format(new Date(formattedEndDate), "HH:mm");
          const meetingFormatedDate = () => {
            if (meeting.rrule === "") {
              return format(fromUnixTime(meeting.start), "dd MMMMMM, yyyy");
            } else {
              return format(globalDate, "dd MMMMMM, yyyy");
            }
          };

          setMeetingStartTime(meetingStartTime);
          setMeetingEndTime(meetingEndTime);
          setMeetingFormatedDate(meetingFormatedDate);
          setUserStatus(getUserStatus(meeting));
          setInviteId(getInviteId(meeting));
          isMeetingPast(formattedEndDate);
          setLoading(false);
        })

        .catch((err) => {
          console.error(err);
        });
    }
  }, [anchorEl, open]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={"right"}
      transition
      className={classes.popper}
      disablePortal={false}
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },

        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={250}>
          <Paper elevation={0} className={classes.background}>
            <Paper elevation={0} className={classes.popoverRoot}>
              {arrow ? (
                <span className={classes.arrow} ref={setArrowRef} />
              ) : null}

              {loading ? (
                <MeetingPopupSkeleton />
              ) : (
                <ClickAwayListener onClickAway={(e) => handlePopupClose(e)}>
                  <Stack className={classes.content}>
                    <Typography variant="h2" p={1}>
                      {meeting?.name}
                    </Typography>
                    {/* Display Time Section */}
                    <Stack spacing={2} p={1} direction="row">
                      <Stack
                        direction="row"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <TodayIcon></TodayIcon>&nbsp;
                        <Box>{meetingFormatedDate}</Box>
                      </Stack>

                      <Stack
                        direction="row"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <AccessTimeIcon></AccessTimeIcon>&nbsp;
                        <Box>
                          {meetingStartTime} - {meetingEndTime}
                        </Box>
                      </Stack>
                    </Stack>

                    {/* Organizer Section */}
                    <Stack p={1} width={1} direction="row">
                      {userId === meeting?.owner_id ? (
                        <Stack width={1} spacing={1} direction="row">
                          <Box>
                            <Avatar
                              {...getOwnerInitials(
                                `${
                                  meeting?.owner_first_name +
                                  " " +
                                  meeting?.owner_last_name
                                }`,
                                "small"
                              )}
                            />
                          </Box>
                          <Box> Organized by you</Box>
                        </Stack>
                      ) : (
                        <Stack width={1} spacing={1} direction="row">
                          <Box>
                            <Avatar
                              {...getOwnerInitials(
                                `${
                                  meeting?.owner_first_name +
                                  " " +
                                  meeting?.owner_last_name
                                }`,
                                "small"
                              )}
                            />
                          </Box>

                          <Box>
                            {" "}
                            Invited by{" "}
                            {meeting?.owner_first_name +
                              " " +
                              meeting?.owner_last_name}
                          </Box>
                        </Stack>
                      )}
                    </Stack>
                    {/* Meeting Details Section */}
                    <Stack p={1} spacing={1} width={1} direction="row">
                      <Stack>
                        <EventNoteIcon />
                      </Stack>
                      <Stack>
                        <Typography
                          color={theme.palette.text.primary}
                          variant="body1"
                        >
                          {meeting?.description
                            ? meeting?.description
                            : "No description"}
                        </Typography>
                      </Stack>
                    </Stack>

                    {userId === meeting?.owner_id ? (
                      <Box p={1}></Box>
                    ) : (
                      <>
                        <Divider variant="middle" />
                        <Stack
                          p={1}
                          spacing={1}
                          width={1}
                          direction="row"
                          justifyContent={"flex-end"}
                        >
                          <Box p={2}>
                            {userStatus ? (
                              <InviteStatusButtons
                                userStatus={userStatus}
                                inviteId={inviteId}
                                disabled={inPastStatus}
                              />
                            ) : (
                              <Box></Box>
                            )}
                          </Box>
                        </Stack>
                      </>
                    )}

                    <Divider variant="middle" />
                    <Stack>
                      <Stack p={1} spacing={1} width={1} direction={"row"}>
                        <GroupsIcon></GroupsIcon>
                        <Typography color={theme.palette.primary.dark}>
                          Attendies
                        </Typography>
                      </Stack>
                      <Stack p={1} spacing={1} width={1} direction={"row"}>
                        <AvatarGroup
                          spacing={1}
                          total={meeting?.invites.length}
                        >
                          {meeting?.invites.map((invite: any, index) => (
                            <Avatar
                              key={index}
                              {...getOwnerInitials(
                                `${
                                  invite?.invitee_first_name +
                                  " " +
                                  invite?.invitee_last_name
                                }`,
                                "large"
                              )}
                            />
                          ))}
                        </AvatarGroup>
                        <Box
                          width={1}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          <Link
                            color={"secondary"}
                            component="button"
                            variant="body1"
                            onClick={() => {
                              router.push(`/meetings/${meeting?.meeting_id}`);
                            }}
                          >
                            See All
                          </Link>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </ClickAwayListener>
              )}
            </Paper>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default MeetingModal;
