//IMPORT CSS/STYLES
import {
  Box,
  Typography,
  Input,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//IMPORTED COMPONENTS
import ForStartDate from "../../CalendarNavigator/ForStartDate";
import ForUntilDate from "../../CalendarNavigator/ForUntilDate";

//IMPORT DATE COMPONENTS
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//TIME LIBRARY
import { format } from "date-fns";
import enLocale from "date-fns/locale/en-GB";

//IMPORT STORE
import { meetingsStore } from "@state/store";

export type VTRRULEWeeklyProps = {
  meetingStartDate: string;
  setMeetingStartDate: (meetingStartDate: string) => void;
  repeatMeeting: string;
  handleClose: () => void;
  isDayActive: {
    days: { id: number; day: string; value: any; isActive: boolean }[];
  };
  setIsDayActive: (isDayActive: any) => void;
  activeDaysString: string;
  dayString: string;
  monthString: string;
  year: string;
  onChange: (e: SelectChangeEvent) => void;
  pickDateButton: boolean;
  setPickDateButton: (pickDateButton: boolean) => void;
  interval: number;
  setInterval: (interval: number) => void;
  count: number;
  setCount: (count: number) => void;
  reoccuringMeetingEnd: Date;
  setReoccuringMeetingEnd: (reoccuringMeetingEnd: Date) => void;
  handleSaveClose: () => void;
};

const RRULEWeekly = ({
  meetingStartDate,
  setMeetingStartDate,
  repeatMeeting,
  handleClose,
  isDayActive,
  setIsDayActive,
  activeDaysString,
  onChange,
  pickDateButton,
  setPickDateButton,
  interval,
  setInterval,
  count,
  setCount,
  reoccuringMeetingEnd,
  setReoccuringMeetingEnd,
  dayString,
  monthString,
  year,
  handleSaveClose,
}: VTRRULEWeeklyProps) => {
  const {
    dateForCalendar,
    setDateForCalendar,
    dateForUntilCalendar,
    setDateForUntilCalendar,
  } = meetingsStore();

  const handleSelection = (date: Date | null) => {
    if (date) {
      setMeetingStartDate(format(date, `yyyy-MM-dd`));
      setDateForCalendar(date);
    }
  };

  const handleEndingSelectionDate = (date: Date | null) => {
    if (date) {
      setReoccuringMeetingEnd(date);
      setDateForUntilCalendar(date);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ paddingTop: "20px" }}>
          <Typography
            sx={{
              fontWeight: "700",
              paddingLeft: "5px",
              fontSize: "16px",
              lineHeight: "21.82px",
            }}
          >
            Starts
          </Typography>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider
              locale={enLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                showToolbar={true}
                ToolbarComponent={ForStartDate}
                disablePast
                openTo="day"
                views={["year", "month", "day"]}
                value={dateForCalendar}
                onChange={(date) => {
                  handleSelection(date);
                }}
                renderInput={(params) => {
                  return (
                    <>
                      <TextField
                        {...params}
                        error={false}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </>
                  );
                }}
              />
            </LocalizationProvider>
          </StyledEngineProvider>
        </Box>

        <Box sx={{ paddingTop: "20px" }}>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              paddingLeft: "5px",
              lineHeight: "21.82px",
            }}
          >
            Repeat
          </Typography>
          <Select
            value={repeatMeeting}
            onChange={(e) => onChange(e)}
            sx={{
              width: "100%",
              height: "50px",
            }}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </Box>

        <Box sx={{ paddingTop: "20px" }}>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              paddingLeft: "5px",
              lineHeight: "21.82px",
            }}
          >
            Every
          </Typography>
          <Select
            sx={{
              width: "100%",
              height: "50px",
            }}
            value={interval}
            onChange={(e) => setInterval(Number(e.target.value))}
            defaultValue={interval}
          >
            <MenuItem value="1">1 Week</MenuItem>
            <MenuItem value="2">2 Weeks</MenuItem>
            <MenuItem value="3">3 Weeks</MenuItem>
            <MenuItem value="4">4 Weeks</MenuItem>
          </Select>
        </Box>

        <Box sx={{ paddingTop: "20px" }}>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "21.82px",
              paddingLeft: "5px",
            }}
          >
            On
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {isDayActive.days.map((day) => (
              <FormControlLabel
                value="top"
                key={day.id}
                control={
                  <Checkbox
                    onClick={() => {
                      setIsDayActive({
                        days: isDayActive.days.map((d) => {
                          if (d.value === day.value) {
                            return { ...d, isActive: !d.isActive };
                          } else {
                            return { ...d, isActive: d.isActive };
                          }
                        }),
                      });
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: "var(--visionable-mid-blue)",
                      },
                    }}
                  />
                }
                label={day.day}
                labelPlacement="top"
                sx={{ marginLeft: "0px", marginRight: "0px" }}
              />
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "21.82px",
              paddingLeft: "5px",
            }}
          >
            Ends
          </Typography>

          <Box>
            <RadioGroup row defaultValue="Never">
              <FormControlLabel
                value="Never"
                control={
                  <Radio
                    sx={{
                      color: "var(--visionable-mid-blue)",
                      "&.Mui-checked": {
                        color: "var(--visionable-mid-blue)",
                      },
                    }}
                    onClick={() => setPickDateButton(false)}
                  />
                }
                label="Never"
                sx={{ marginLeft: "0px" }}
              />
              <FormControlLabel
                value="PickDate"
                control={
                  <Radio
                    sx={{
                      color: "var(--visionable-mid-blue)",
                      "&.Mui-checked": {
                        color: "var(--visionable-mid-blue)",
                      },
                    }}
                    onClick={() => setPickDateButton(true)}
                  />
                }
                label="Pick Date"
                sx={{ marginLeft: "0px" }}
              />
            </RadioGroup>
          </Box>
        </Box>

        {pickDateButton ? (
          <StyledEngineProvider injectFirst>
            <LocalizationProvider
              locale={enLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                showToolbar={true}
                ToolbarComponent={ForUntilDate}
                disablePast
                openTo="day"
                views={["year", "month", "day"]}
                value={dateForUntilCalendar}
                onChange={(date) => {
                  handleEndingSelectionDate(date);
                }}
                renderInput={(params) => {
                  return (
                    <>
                      <TextField
                        {...params}
                        error={false}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </>
                  );
                }}
              />
            </LocalizationProvider>
          </StyledEngineProvider>
        ) : null}

        {activeDaysString ? (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#FAFAFA",
              height: "100%",
              paddingTop: "20px",
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
              paddingLeft: "20px",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignText: "center",
              }}
            >
              <InfoOutlinedIcon />
              <Typography sx={{ paddingLeft: "10px" }}>
                Occurs every {activeDaysString}, starting {dayString}{" "}
                {monthString} {year}
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Box>
            {activeDaysString ? (
              <Button
                variant="contained"
                sx={{ width: "121px", height: "50px" }}
                onClick={() => handleSaveClose()}
              >
                Save
              </Button>
            ) : null}
          </Box>
          <Box sx={{ marginLeft: "10px" }}>
            <Button
              variant="outlined"
              onClick={() => handleClose()}
              sx={{ width: "121px", height: "50px" }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RRULEWeekly;
