import { useState, useEffect, useContext } from "react";

//Material UI Components
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

//Global Store
import { meetingStore, userStore } from "@state/store";

//Utility
import joinMeeting from "@utilities/JoinMeeting";

//NextJS Router
import { useRouter } from "next/router";

import { ChatContext } from "@chat";

interface VTButtons {
  isLoaded: boolean;
}

const Buttons = ({ isLoaded }: VTButtons) => {
  const {
    setPreCallModalIsOpen,
    selectedMeeting,
    activeVideoStream,
    videoDevices,
    activeAudioStream,
    microphoneIsOn,
    videoIsOn,
    setVideoIsOn,
    setMicrophoneIsOn,
    setActiveVideoStream,
  } = meetingStore();
  const store = meetingStore();

  const chat = useContext(ChatContext);

  const { user } = userStore();

  const router = useRouter();

  //@ts-ignore
  const handleJoinMeeting = async (e) => {
    setPreCallModalIsOpen(false);
    const name = user.display_name;
    const email = user.user_id;

    e.currentTarget.disabled = true;
    try {
      await joinMeeting({
        uuid: selectedMeeting.meeting_id,
        email,
        name,
        meetingStore: store,
      });

      chat.getChatHistory(selectedMeeting.meeting_id);

      router.push("/meeting/[uuid]", `/meeting/${selectedMeeting.meeting_id}`);
    } catch (e) {
      router.back();
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "right", margin: "30px" }}>
        <Button
          disabled={!isLoaded}
          sx={{ color: "white", width: "123px" }}
          onClick={() => {
            store.setLocalClients([]);
            store.setRemoteClients([]);
            store.setScreenShareClients([]);
            store.setRemoteAudioClients([]);
            store.setInitialsScreenShares([]);
            if (store.activeVideoStream !== null) {
              store?.activeVideoStream
                .getTracks()
                .forEach((track: any) => track.stop());
            }
            if (store.activeAudioStream !== null) {
              store?.activeAudioStream
                .getTracks()
                .forEach((track: any) => track.stop());
            }
            setActiveVideoStream({} as MediaStream);
            setPreCallModalIsOpen(false);
            setVideoIsOn(false);
            setMicrophoneIsOn(false);
          }}
        >
          {" "}
          Cancel
        </Button>

        <Button
          disabled={!isLoaded}
          sx={{
            color: "white",
            backgroundColor: "var(--visionable-mid-blue)",
            width: "123px",
          }}
          onClick={(e) => handleJoinMeeting(e)}
        >
          {" "}
          Join{" "}
        </Button>
      </Box>
    </>
  );
};

export default Buttons;
