import { Box, Typography, TextField, Stack } from "@mui/material";
import { useState, useEffect } from "react";

export type VTDescription = {
  description: string;
  setDescription: (description: string) => void;
  existingDescription?: string;
  createMeetingModalIsOpen: boolean;
};

const Description = ({
  description,
  setDescription,
  existingDescription,
  createMeetingModalIsOpen,
}: VTDescription) => {
  const [descriptionLength, setDescriptionLength] = useState<number>(0);
  const [existingDescriptionLength, setExistingDescriptionLength] =
    useState<number>(0);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);

  useEffect(() => {
    description
      ? setDescriptionLength(description.length)
      : setDescriptionLength(0);
  }, [description]);

  useEffect(() => {
    existingDescription
      ? setExistingDescriptionLength(existingDescription.length)
      : setExistingDescriptionLength(0);
  }, [existingDescription]);

  useEffect(() => {
    if (createMeetingModalIsOpen) {
      if (descriptionLength <= 250) {
        setDescriptionError(false);
      } else {
        setDescriptionError(true);
      }
    } else {
      existingDescriptionLength <= 250
        ? setDescriptionError(false)
        : setDescriptionError(true);
    }
  }, [existingDescription, descriptionLength]);

  return (
    <Box
      sx={{
        // paddingTop: "27px",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          fontWeight: "bold",
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "120%",
          width: "151px",
          height: "38.7px",
          alignItems: "center",
        }}
      >
        Description
      </Typography>
      {descriptionError ? (
        <>
          <Stack>
            <TextField
              id="outlined-multiline-static"
              error
              multiline
              value={description}
              defaultValue={existingDescription}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              sx={{
                width: "100%",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                "& .MuiFormHelperText-root.Mui-error": { marginLeft: "0px" },
              }}
            />
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "var(--error)" }}>
                Must be less than 250 characters
              </Typography>
              <Typography sx={{ color: "var(--error)" }}>
                {descriptionLength}/250
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <TextField
          id="outlined-multiline-static"
          placeholder="Add description here"
          multiline
          value={description}
          defaultValue={existingDescription}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          sx={{
            width: "100%",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            marginLeft: "0px",
          }}
        />
      )}
      {descriptionError ? null : (
        <>
          {description?.length || createMeetingModalIsOpen ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  size: "10px",
                  color: descriptionError ? "var(--red)" : "#CCCCCC",
                }}
              >
                {descriptionLength}/250
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  size: "10px",
                  color: descriptionError ? "var(--red)" : "#CCCCCC",
                  position: descriptionError ? "absolute" : "relative",
                  bottom: descriptionError ? "130px" : "",
                }}
              >
                {existingDescriptionLength}/250
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Description;
