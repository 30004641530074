///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING MONTHLY CALENDAR
///
///////////////////////////////////////////////////////////////

import * as React from "react";
import { useEffect, useState } from "react";

//MUI COMPONENTS
import {
  Box,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  CardActionArea,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// CUSTOM COMPONENTS
import MonthlyCard from "../MonthlyCard";
import NoMeetingComponent from "../../NoMeeting";
import DayIndicator from "./../DayIndicator";
import FilterPopover from "@components/MeetingComponents/FilterPopover";
import DaySelector from "@components/MeetingComponents/MeetingStateViewComponents/DaySelector";

//TIME LIBRARY
import {
  format,
  endOfMonth,
  startOfMonth,
  eachDayOfInterval,
  isToday,
  isSameDay,
  startOfDay,
  endOfDay,
  getUnixTime,
} from "date-fns";

//TYPES
import { VTSingleMeeting, VTMeetings } from "types";

// GLOBAL STATE
import { meetingsStore } from "@state/store";

// RRULE LIBRARY
import { RRule, rrulestr } from "rrule";

///////////////////////////////////////////////////////////////
///
///  MONTHLY COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTMonthlyViewProps = {
  meetings: any;
  handleExpansionClick: ({ uuid }: VTSingleMeeting) => void;
};

const MonthlyView = ({
  meetings,
  handleExpansionClick,
}: VTMonthlyViewProps) => {
  // GLOBAL STATE
  const { setMeetingsView, globalDate, setGlobalDate } = meetingsStore();

  // STATE
  const [monthlyMeetings, setMonthlyMeetings] = useState<any>([]);
  const [monthlyMeetingsLayout, setMonthlyMeetingsLayout] = useState<any>([]);
  const [selection, setSelection] = useState<any>("");
  const [dateOfSelection, setDateOfSelection] = useState<any>("");

  // DATE TIME OPERATIONS
  const firstDayOfCurrentMonth = startOfMonth(new Date(globalDate));
  const lastDayCurrentMonth = endOfMonth(new Date(globalDate));
  const daysArray = eachDayOfInterval({
    start: firstDayOfCurrentMonth,
    end: lastDayCurrentMonth,
  });

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // CARD SELECTION HIGHLITING
  const handleSelection = (event: any) => {
    setSelection(event.currentTarget.id);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION (MEETINGS DATASTRUCTURE)
  ///  FORMATS MEETINGS TO DISPLAY FOR ALL DAYS IN THE SELECTED MONTH
  ///
  ///////////////////////////////////////////////////////////////

  // GET MEETING INSANCE ON A GIVEN DATE IF MMETING FALLS ON THAT DATE GIVEN RRULE
  const getTodaysOccurance = (date: Date, meeting: any) => {
    try {
      const rule = rrulestr(meeting.rrule, {
        forceset: true,
      });
      const start = startOfDay(date);
      const end = endOfDay(date);
      const copy = JSON.parse(JSON.stringify(meeting));
      // @ts-ignore
      const exDates = rule.exdates();
      const occurrences = rule.between(start, end, true);

      if (occurrences.length > 0) {
        copy.start = getUnixTime(occurrences[0]);
        return copy;
      } else {
        return false;
      }
    } catch (e) {
      //console.log(e);
    }
  };

  // GET ALL RECCURNING  MEETINGS ON A GIVEN DATE RANGE
  const getTodaysReccuringMeetings = (meetings: VTSingleMeeting[]) => {
    const reccuringMeetings: VTSingleMeeting[] = [];

    daysArray.forEach((date: Date) => {
      meetings.forEach((meeting: any) => {
        if (meeting.rrule !== "" || meeting.rrule !== null) {
          const todaysInstance = getTodaysOccurance(date, meeting);
          if (todaysInstance) {
            reccuringMeetings.push(todaysInstance);
          }
          return;
        }
        return;
      });
    });

    return reccuringMeetings;
  };

  // REMOVE MEETINGS THAT SATISFY EXDATE
  const removeExdateMeetings = (meetings: any) => {
    const filteredMeetings: any = [];
    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "") {
        const rule = rrulestr(meeting.rrule, {
          forceset: true,
        });
        // @ts-ignore
        const exDates = rule.exdates();
        let match = false;
        exDates.forEach((date: any) => {
          if (isSameDay(date, new Date(meeting.start * 1000))) {
            match = true;
          }
        });
        if (!match) {
          filteredMeetings.push(meeting);
        }
      } else {
        filteredMeetings.push(meeting);
      }
    });
    return filteredMeetings;
  };

  // CREATES A DATASTRUCTURE FOR MONNTHLY MEETINGS
  const formatMeetings = (meetings: VTSingleMeeting[]) => {
    let maxMeetings = 0;
    const stringDaysArray: string[] = [];
    const meetingsObject: { [key: string]: any } = {};
    daysArray.forEach((day) => {
      const d = format(day, "yyyy-MM-dd")?.toString();
      stringDaysArray.push(d);
      meetingsObject[d] = [];
    });
    meetings.forEach((meeting: any) => {
      const d = format(
        new Date(meeting.start * 1000),
        "yyyy-MM-dd"
      )?.toString();
      if (d in meetingsObject) {
        maxMeetings = Math.max(maxMeetings, meetingsObject[d].length);
        meetingsObject[d].push(meeting);
        meetingsObject[d].sort((a: any, b: any) => {
          return a.start - b.start;
        });
      }
    });

    // DATE LAYOUT FOR MONTHLY CALENDAR TABLE
    const layout = [...daysArray];
    JSON.parse(JSON.stringify(daysArray));
    const layoutArraySplit = [];
    for (let i = 0; i < layout.length; i += 7) {
      layoutArraySplit.push(layout.slice(i, i + 7));
    }
    setMonthlyMeetingsLayout(layoutArraySplit);

    // ORGANIZED MEETINGS LAYOUT FOR  CALENDAR TABLE
    const output = [...stringDaysArray];

    stringDaysArray.forEach((day) => {
      const dayMeetings = meetingsObject[day];
      output[output.indexOf(day)] = dayMeetings;
    });
    const daysArraySplit = [];
    for (let i = 0; i < output.length; i += 7) {
      daysArraySplit.push(output.slice(i, i + 7));
    }

    setMonthlyMeetings(daysArraySplit);

    return meetingsObject;
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // PREPROCESS MEETINGS TO DISPLAY ON GIVEN DATE
  useEffect(() => {
    const allMeetings = JSON.parse(JSON.stringify(meetings));

    // non reccuring meetings on between selected dates
    const monthsRegularMeetings = allMeetings
      .filter(
        (meeting: VTMeetings) =>
          meeting.start >= getUnixTime(startOfDay(firstDayOfCurrentMonth)) &&
          meeting.start <= getUnixTime(endOfDay(lastDayCurrentMonth))
      )
      .filter((meeting: VTMeetings) => meeting.rrule === "");

    // reccuring meetings on between selected dates
    let monthsReccuringMeetings = getTodaysReccuringMeetings(allMeetings);

    // remove meetings that satisfy exdate constraints
    monthsReccuringMeetings = removeExdateMeetings(monthsReccuringMeetings);

    formatMeetings([...monthsRegularMeetings, ...monthsReccuringMeetings]);
  }, [globalDate, meetings]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Stack>
      {/* NAVIGAION BUTTONS */}
      <Stack display={{ xs: "none", lg: "inline" }}>
        <DaySelector />
      </Stack>
      {/* MONTHLY SHEDULE VIEW */}
      <Box p={2}>
        <TableContainer>
          <Table sx={{ minWidth: "450px" }} aria-label="simple table">
            <TableHead></TableHead>
            <TableBody>
              {monthlyMeetings.map((week: any, i: number) => (
                <TableRow key={i}>
                  {week.map((meetings: any, j: number) => (
                    <TableCell
                      key={i + j}
                      align="center"
                      sx={{
                        padding: "0",
                        border: "1px solid #E1E1E1",
                        borderRadius: "20px",
                        minWidth: "50px",
                        maxWidth: "calc(5vw)",
                        width: "calc(100vw/7)",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        height="fit-content"
                        sx={{
                          alignSelf: "flex-start",
                          height: "100%",
                          minHeight: "210px",
                        }}
                      >
                        <Stack p={1}>
                          <DayIndicator
                            day={monthlyMeetingsLayout[i][j]}
                            selected={dateOfSelection}
                          />
                        </Stack>
                        <Stack
                          p={1}
                          spacing={0.75}
                          height="100%"
                          direction="column"
                          justifyContent="flex-start"
                        >
                          {meetings.slice(0, 3).map((meeting: any) => (
                            <MonthlyCard
                              handleSelection={handleSelection}
                              setDateOfSelection={setDateOfSelection}
                              selection={selection}
                              key={
                                monthlyMeetingsLayout[i][j] + meeting.meeting_id
                              }
                              meeting={meeting}
                              handleExpansionClick={handleExpansionClick}
                            />
                          ))}
                        </Stack>
                        <Stack>
                          {meetings.length > 0 ? (
                            <CardActionArea
                              onClick={() => {
                                setGlobalDate(
                                  new Date(meetings[0].start * 1000)
                                );
                                setMeetingsView("daily");
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </CardActionArea>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default MonthlyView;
