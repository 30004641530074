import { useState } from "react";
import { Box, Typography, Chip, Stack, Modal, Button } from "@mui/material";
import { OtherUsers } from "types";
import VisAvatar from "@components/Avatar";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchField from "@components/SearchField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export type VTModerators = {
  selectedModerators: any[];
  setSelectedModerators: (selectedModerators: any[]) => void;
  users: OtherUsers[];
  setUsers: (users: []) => void;
};

const Moderators = ({
  selectedModerators,
  setSelectedModerators,
  users,
  setUsers,
}: VTModerators) => {
  const [moderatorModalIsOpen, setModeratorModalIsOpen] =
    useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredResults, setFilteredResults] = useState<OtherUsers[]>([]);

  const searchedUsers = (searchValue: string) => {
    setSearchValue(searchValue);
    if (searchValue !== "") {
      const filteredData = users.filter((user) => {
        return Object.values(user)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(users);
    }
  };

  const handleAddingModerator = (user: OtherUsers) => {
    setSelectedModerators([...selectedModerators, user]);
    setSearchValue("");
  };

  const handleRemovingModerator = (user: OtherUsers) => {
    setSelectedModerators(
      selectedModerators.filter(
        (moderator) => moderator.user_id !== user.user_id
      )
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            width: "100%",
          }}
        >
          {selectedModerators?.length ? (
            <Box
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "21.82px",
                    color: "#031C3A",
                  }}
                >
                  Moderators &nbsp;
                </Typography>
                <InfoOutlinedIcon
                  sx={{
                    color: "var(--visionable-mid-blue)",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxHeight: "100px",
                    height: "100%",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.6em",
                      marginRight: "20px",
                      marginTop: "20px",
                      backgroundColor: "#F1F1F1",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "1 1 1 6px #F1F1F1",
                      webkitBoxShadow: "1 1 1 6px #F1F1F1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#C4C4C4",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {selectedModerators?.map((moderator) => (
                    <Chip
                      sx={{
                        margin: "5px",
                        background: "rgba(18, 113, 238, 0.05);",
                        border: "1px solid rgba(18, 113, 238, 0.05)",
                      }}
                      key={moderator.id}
                      label={moderator.name}
                      onDelete={() => {
                        handleRemovingModerator(moderator);
                      }}
                      deleteIcon={
                        <CloseIcon
                          style={{ color: "var(--visionable-mid-blue)" }}
                        />
                      }
                    />
                  ))}
                </Box>
                <AddIcon
                  style={{
                    color: "var(--visionable-mid-blue",
                    cursor: "pointer",
                  }}
                  onClick={() => setModeratorModalIsOpen(true)}
                />
              </Box>
            </Box>
          ) : (
            <>
              <AddIcon
                onClick={() => setModeratorModalIsOpen(true)}
                style={{
                  color: "var(--visionable-mid-blue",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "21.82px",
                  color: "var(--visionable-mid-blue)",
                  alignItems: "center",
                }}
              >
                Moderator
              </Typography>
            </>
          )}

          {moderatorModalIsOpen ? (
            <Modal hideBackdrop open={moderatorModalIsOpen}>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  right: 0,
                  width: { xs: "100%", md: "75%", lg: "65%", xl: "40%" },
                  height: "100%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  overflow: "hidden",
                }}
              >
                <Stack spacing={4} sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowBackIosNewIcon
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginRight: "30px",
                        color: "var(--visionable-dark-blue)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModeratorModalIsOpen(false);
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "32px",
                        fontWeight: "700",
                        lineHeight: "29px",
                        fontStyle: "normal",
                      }}
                    >
                      Select moderators
                    </Typography>
                  </Box>

                  <SearchField
                    placeholder="Search Directory"
                    value={searchValue}
                    onChange={(e) => searchedUsers(e.target.value)}
                  />

                  {selectedModerators?.length ? (
                    <Box
                      sx={{
                        maxHeight: "100px",
                        height: "100%",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0em",
                          marginRight: "20px",
                          marginTop: "20px",
                          backgroundColor: "#F1F1F1",
                          borderRadius: "10px",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "1 1 1 6px #F1F1F1",
                          webkitBoxShadow: "1 1 1 6px #F1F1F1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#C4C4C4",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {selectedModerators?.map((moderator, index) => (
                        <Chip
                          sx={{
                            margin: "5px",
                            background: "rgba(18, 113, 238, 0.05);",
                            border: "1px solid rgba(18, 113, 238, 0.05)",
                          }}
                          key={moderator.id}
                          label={moderator.name}
                          onDelete={() => {
                            handleRemovingModerator(moderator);
                          }}
                          deleteIcon={
                            <CloseIcon
                              style={{ color: "var(--visionable-mid-blue)" }}
                            />
                          }
                        />
                      ))}
                    </Box>
                  ) : (
                    <></>
                  )}

                  <Box
                    sx={{
                      width: "100%",
                      height: "500px",
                      backgroundColor: "var(--visionable-background-gray)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "98%",
                        height: "90%",
                        overflowY: "scroll",
                        justifyContent: "flex-start",
                        "&::-webkit-scrollbar": {
                          width: "0.6em",
                          marginRight: "20px",
                          marginTop: "20px",
                          backgroundColor: "#F1F1F1",
                          borderRadius: "10px",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "1 1 1 6px #F1F1F1",
                          webkitBoxShadow: "1 1 1 6px #F1F1F1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#C4C4C4",
                          borderRadius: "10px",
                        },

                        flexDirection: "column",
                      }}
                    >
                      {searchValue.length > 1 ? (
                        filteredResults.map((user) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "30px",
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleAddingModerator(user)}
                                >
                                  <VisAvatar userID={user.user_id} />
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "700",
                                      lineHeight: "21.82px",
                                      color: "#031C3A",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {user.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <Box>
                            {users.map((user) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "30px",
                                        paddingTop: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleAddingModerator(user)
                                      }
                                    >
                                      <VisAvatar userID={user.user_id} />
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "700",
                                          lineHeight: "21.82px",
                                          color: "#031C3A",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {user.name}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      width: "137px",
                      height: "50px",
                      background: "var(--visionable-mid-blue)",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      setModeratorModalIsOpen(false);
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Moderators;
