///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING CALENDAR
///
///////////////////////////////////////////////////////////////
import { useEffect, useState, useContext } from "react";

// API
import { VTMeetings, VTSingleMeeting } from "../../../types";

//MUI COMPONENT AND TIME FUNCTIONS
import { Box, Stack, IconButton, Badge, TextField } from "@mui/material";
import {
  PickersDay,
  PickersDayProps,
  pickersDayClasses,
} from "@mui/x-date-pickers/PickersDay";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { CalendarPickerSkeleton } from "@mui/x-date-pickers/CalendarPickerSkeleton";

//TIME LIBRARY
import {
  format,
  lastDayOfWeek,
  startOfWeek,
  isBefore,
  getDate,
  endOfMonth,
  startOfMonth,
  isWithinInterval,
  isSameDay,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  getUnixTime,
} from "date-fns";
import enLocale from "date-fns/locale/en-GB";

//STYLES
import { styled } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";

// GLOBAL STATE
import { meetingsStore } from "@state/store";

// RRULE LIBRARY
import { RRule, rrulestr } from "rrule";

/*
 *
 *
 * There currently is an error saying that there is a non-boolean value for ispast. Can't seem
 * to find it despite typescript checking everything.
 *
 */

///////////////////////////////////////////////////////////////
///
///  CALENDAR COMPONENT
///
///////////////////////////////////////////////////////////////
export type CalendarProps = {
  meetings: VTMeetings[];
};
const Calendar = ({ meetings }: CalendarProps) => {
  // GLOBAL STATE
  const { setMeetingsView, meetingsView, globalDate, setGlobalDate } =
    meetingsStore();

  //STATE
  const [value, setValue] = useState<Date | null>(globalDate);
  const [highlightedDays, setHighlightedDays] = useState([0]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  MUI STYLE OVERRIDES - DAILY CALENDAR
  ///
  ///////////////////////////////////////////////////////////////

  // CUSTOMIZATION OF THE DAILY VIEW DAY PICKER

  const renderDailyPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start: Date = value;
    const end: Date = value;
    const yesterday: Date = new Date(
      new Date().setDate(new Date().getDate() - 1)
    );
    const ispast: boolean = isBefore(date, yesterday);
    const dayIsBetween: boolean = isWithinInterval(date, { start, end });
    const isFirstDay: boolean = isSameDay(date, start);
    const isLastDay: boolean = isSameDay(date, end);

    const hasMeetings =
      !pickersDayProps.outsideCurrentMonth &&
      !ispast &&
      highlightedDays.indexOf(date.getDate()) > 0;

    return (
      <StyledEngineProvider injectFirst>
        <Box>
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                marginLeft: "35%",
              },
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            key={"s"}
            overlap="circular"
            color="warning"
            variant={hasMeetings ? "dot" : undefined}
          >
            <CustomPickerDaily
              {...pickersDayProps}
              sx={{
                [`&&.${pickersDayClasses.selected && pickersDayClasses.today}`]:
                  {
                    backgroundColor: "#1271EE",
                  },
                [`&&.${pickersDayClasses.selected}`]: {
                  backgroundColor: "#1271EE",
                  borderRadius: "50%",
                },
                [`&&.${pickersDayClasses.today}`]: {
                  backgroundColor: "rgba(189, 216, 250, 1)",
                  border: "none",
                },
                [`&&.${pickersDayClasses.dayOutsideMonth}`]: {
                  color: "#B7B7B7",
                },
              }}
              disabled={pickersDayProps.outsideCurrentMonth ? true : false}
              showDaysOutsideCurrentMonth
              ispast={ispast}
              dayIsBetween={dayIsBetween}
              isFirstDay={isFirstDay}
              isLastDay={isLastDay}
            />
          </Badge>
        </Box>
      </StyledEngineProvider>
    );
  };

  type CustomPickerDailyProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
    ispast: boolean;
  };
  const CustomPickerDaily = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
  })<CustomPickerDailyProps>(
    ({
      theme,
      dayIsBetween,
      ispast,
    }: {
      theme: any;
      dayIsBetween: boolean;
      ispast: boolean;
    }) => ({
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: "rgba(231, 241, 253, 1);",
        color: "black",
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),

      ...(ispast && {
        color: "#B7B7B7",
      }),
    })
  ) as React.ComponentType<CustomPickerDailyProps>;

  ///////////////////////////////////////////////////////////////
  ///
  ///  MUI STYLE OVERRIDES - WEEKLY CALENDAR
  ///
  ///////////////////////////////////////////////////////////////

  // CUSTOMIZATION OF THE WEEKLY VIEW DAY PICKER

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start: Date = startOfWeek(value, {
      weekStartsOn: 1,
    });
    const end: Date = lastDayOfWeek(value, {
      weekStartsOn: 1,
    });

    const yesterday: Date = new Date(
      new Date().setDate(new Date().getDate() - 1)
    );
    const ispast: boolean = isBefore(date, yesterday);
    const dayIsBetween: boolean = isWithinInterval(date, { start, end });
    const isFirstDay: boolean = isSameDay(date, start);
    const isLastDay: boolean = isSameDay(date, end);

    const hasMeetings: boolean =
      !pickersDayProps.outsideCurrentMonth &&
      !ispast &&
      highlightedDays.indexOf(date.getDate()) > 0;

    return (
      <>
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          sx={
            //applies styles to box wrapping picker day buttons
            dayIsBetween
              ? {
                  backgroundColor: "rgba(231, 241, 253, 1)",
                  borderRadius: isLastDay
                    ? "0 25% 25% 0"
                    : "none" && isFirstDay
                    ? "25% 0 0 25%"
                    : "none",
                }
              : {}
          }
        >
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                marginLeft: "35%",
              },
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            key={"s"}
            overlap="circular"
            color="warning"
            variant={hasMeetings ? "dot" : undefined}
          >
            <CustomPickerWeekly
              {...pickersDayProps}
              sx={{
                [`&&.${pickersDayClasses.selected && pickersDayClasses.today}`]:
                  {
                    backgroundColor: "#1271EE",
                    borderRadius: "50%",
                  },
                [`&&.${pickersDayClasses.selected}`]: {
                  backgroundColor: "#1271EE",
                  borderRadius: "50%",
                },
                [`&&.${pickersDayClasses.today}`]: {
                  backgroundColor: "rgba(189, 216, 250, 1)",
                  border: "none",
                  borderRadius: "50%",
                },
                [`&&.${pickersDayClasses.dayOutsideMonth}`]: {
                  color: "#B7B7B7",
                },
              }}
              disabled={pickersDayProps.outsideCurrentMonth ? true : false}
              showDaysOutsideCurrentMonth
              dayIsBetween={dayIsBetween}
              isFirstDay={isFirstDay}
              isLastDay={isLastDay}
              ispast={ispast}
            />
          </Badge>
        </Box>
      </>
    );
  };

  type CustomWeeklyPickerProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
    ispast: boolean;
  };
  const CustomPickerWeekly = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
  })<CustomWeeklyPickerProps>(
    ({
      dayIsBetween,
      isFirstDay,
      isLastDay,
      ispast,
    }: {
      dayIsBetween: boolean;
      isFirstDay: boolean;
      isLastDay: boolean;
      ispast: boolean;
    }) => ({
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: "rgba(231, 241, 253, 1)",
        color: "black",
        "&:hover, &:focus": {
          backgroundColor: "rgba(189, 216, 250, 1)",
        },
      }),
      ...(isFirstDay && {
        borderTopLeftRadius: "25%",
        borderBottomLeftRadius: "25%",
      }),

      ...(isLastDay && {
        borderTopRightRadius: "25%",
        borderBottomRightRadius: "25%",
      }),
      ...(ispast && {
        color: "#B7B7B7",
      }),
    })
  ) as React.ComponentType<CustomWeeklyPickerProps>;

  ///////////////////////////////////////////////////////////////
  ///
  ///  MUI STYLE OVERRIDES - MONTHLY CALENDAR
  ///
  ///////////////////////////////////////////////////////////////

  // CUSTOMIZATION OF THE MOTHLY VIEW DAY PICKER

  const renderMonthPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start: Date = startOfMonth(value);
    const end: Date = endOfMonth(value);
    const yesterday: Date = new Date(
      new Date().setDate(new Date().getDate() - 1)
    );
    const ispast: boolean = isBefore(date, yesterday);
    const dayIsBetween: boolean = isWithinInterval(date, { start, end });
    const isFirstDay: boolean = isSameDay(date, start);
    const isLastDay: boolean = isSameDay(date, end);
    const hasMeetings: boolean =
      !pickersDayProps.outsideCurrentMonth &&
      !ispast &&
      highlightedDays.indexOf(date.getDate()) > 0;

    return (
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        sx={
          dayIsBetween
            ? {
                backgroundColor: "rgba(231, 241, 253, 1)",
                borderRadius: isLastDay
                  ? "0 25% 25% 0"
                  : "none" && isFirstDay
                  ? "25% 0 0 25%"
                  : "none",
              }
            : {}
        }
      >
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              marginLeft: "35%",
            },
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          key={"s"}
          overlap="circular"
          color="warning"
          variant={hasMeetings ? "dot" : undefined}
        >
          <CustomPickersMonthly
            {...pickersDayProps}
            sx={{
              [`&&.${pickersDayClasses.selected && pickersDayClasses.today}`]: {
                backgroundColor: "#1271EE",
                borderRadius: "50%",
              },
              [`&&.${pickersDayClasses.selected}`]: {
                backgroundColor: "#1271EE",
                borderRadius: "50%",
              },
              [`&&.${pickersDayClasses.today}`]: {
                backgroundColor: "rgba(189, 216, 250, 1)",
                border: "none",
                borderRadius: "50%",
              },
              [`&&.${pickersDayClasses.dayOutsideMonth}`]: {
                color: "#B7B7B7",
              },
            }}
            disabled={pickersDayProps.outsideCurrentMonth ? true : false}
            showDaysOutsideCurrentMonth
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
            ispast={ispast}
          />
        </Badge>
      </Box>
    );
  };

  type CustomPickerMonthlyProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
    ispast: boolean;
  };
  const CustomPickersMonthly = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
  })<CustomPickerMonthlyProps>(
    ({
      dayIsBetween,
      isFirstDay,
      isLastDay,
      ispast,
    }: {
      dayIsBetween: boolean;
      isFirstDay: boolean;
      isLastDay: boolean;
      ispast: boolean;
    }) => ({
      ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: "rgba(231, 241, 253, 1)",
        color: "black",
        "&:hover, &:focus": {
          backgroundColor: "rgba(189, 216, 250, 1)",
        },
      }),
      ...(isFirstDay && {
        borderTopLeftRadius: "25%",
        borderBottomLeftRadius: "25%",
      }),

      ...(isLastDay && {
        borderTopRightRadius: "25%",
        borderBottomRightRadius: "25%",
      }),
      ...(ispast && {
        color: "#B7B7B7",
      }),
    })
  ) as React.ComponentType<CustomPickerMonthlyProps>;

  const Navigator = () => {
    return (
      <Stack
        padding={1}
        direction="row"
        spacing={6}
        sx={{
          justifyContent: "center",
          alignItems: "center,",
          minWidth: "320px",
          alignSelf: "center",
        }}
      >
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={hanldeBackwardDateClick}
        >
          <ArrowBackIosIcon sx={{ color: "black" }} />
        </IconButton>
        <Box
          sx={{
            width: "40%",
            textAlign: "center",
          }}
          alignSelf={"center"}
        >
          {format(globalDate, "MMMM Y")}
        </Box>
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={handleForwardDateClick}
        >
          <ArrowForwardIosIcon sx={{ color: "black" }} />
        </IconButton>
      </Stack>
    );
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // SET GLOBAL STATE FOR NAVIGATION
  const handleSelection = (date: Date | null) => {
    setValue(date);
    if (date !== null) {
      setValue(date);
      setGlobalDate(date);
    }
  };

  // NAVIGATION BUTTONS TOGGLE BETWEEN WEEKS
  const hanldeBackwardDateClick = () => {
    setGlobalDate(new Date(globalDate.getTime() - 30 * 24 * 60 * 60 * 1000));
  };

  const handleForwardDateClick = () => {
    setGlobalDate(new Date(globalDate.getTime() + 30 * 24 * 60 * 60 * 1000));
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION (MEETINGS DATASTRUCTURE)
  ///  FORMATS MEETINGS TO DISPLAY FOR ALL DAYS IN THE SELECTED MONTH CALENDAR
  ///
  ///////////////////////////////////////////////////////////////

  // GET MEETING INSANCE ON A GIVEN DATE IF MMETING FALLS ON THAT DATE GIVEN RRULE
  const getTodaysOccurance = (date: Date, meeting: any) => {
    try {
      const rule = rrulestr(meeting.rrule, {
        forceset: true,
      });
      const start = startOfDay(date);
      const end = endOfDay(date);
      const copy = JSON.parse(JSON.stringify(meeting));
      // @ts-ignore
      const exDates = rule.exdates();
      const occurrences = rule.between(start, end, true);

      if (occurrences.length > 0) {
        copy.start = getUnixTime(occurrences[0]);
        return copy;
      } else {
        return false;
      }
    } catch (e) {
      //console.log(e);
    }
  };

  // GET ALL RECCURNING  MEETINGS ON A GIVEN DATE RANGE
  const getTodaysReccuringMeetings = (
    meetings: VTSingleMeeting[],
    daysArray: Date[]
  ) => {
    const reccuringMeetings: VTSingleMeeting[] = [];

    daysArray.forEach((date: Date) => {
      meetings.forEach((meeting: any) => {
        if (meeting.rrule !== "" || meeting.rrule !== null) {
          const todaysInstance = getTodaysOccurance(date, meeting);
          if (todaysInstance) {
            reccuringMeetings.push(todaysInstance);
          }
          return;
        }
        return;
      });
    });

    return reccuringMeetings;
  };

  // REMOVE MEETINGS THAT SATISFY EXDATE
  const removeExdateMeetings = (meetings: any) => {
    const filteredMeetings: any = [];
    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "") {
        const rule = rrulestr(meeting.rrule, {
          forceset: true,
        });
        // @ts-ignore
        const exDates = rule.exdates();
        let match = false;
        exDates.forEach((date: any) => {
          if (isSameDay(date, new Date(meeting.start * 1000))) {
            match = true;
          }
        });
        if (!match) {
          filteredMeetings.push(meeting);
        }
      } else {
        filteredMeetings.push(meeting);
      }
    });
    return filteredMeetings;
  };

  // FUNCTION THAT SETS DAYS WITH MEETINGS ON THE CALENDAR (HIGHLIGHTED) YELLOW DOT
  const getDaysOfMeetings = (meetings: any) => {
    const daysSet = new Set<number>();
    const date = globalDate;
    const firstOfMonth = startOfMonth(date);
    const lastOfMonth = endOfMonth(date);
    const daysArray = eachDayOfInterval({
      start: firstOfMonth,
      end: lastOfMonth,
    });

    const allMeetings = JSON.parse(JSON.stringify(meetings));

    // non reccuring meetings on between selected dates
    const monthsRegularMeetings = allMeetings
      .filter(
        (meeting: VTMeetings) =>
          meeting.start >= getUnixTime(startOfDay(firstOfMonth)) &&
          meeting.start <= getUnixTime(endOfDay(lastOfMonth))
      )
      .filter(
        (meeting: VTMeetings) => meeting.rrule === "" || meeting.rrule === null
      );

    // reccuring meetings on between selected dates
    let monthsReccuringMeetings = getTodaysReccuringMeetings(
      allMeetings,
      daysArray
    );
    // remove meetings that satisfy exdate constraints
    monthsReccuringMeetings = removeExdateMeetings(monthsReccuringMeetings);

    const combinedMeetings = [
      ...monthsRegularMeetings,
      ...monthsReccuringMeetings,
    ];

    combinedMeetings.forEach((meeting: any) => {
      const meetingDate = new Date(meeting.start * 1000);
      if (meetingDate >= firstOfMonth && meetingDate <= lastOfMonth) {
        daysSet.add(getDate(meetingDate));
      }
    });
    const array = Array.from(daysSet);
    setHighlightedDays([0, ...array]);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // SYNC GLOBAL STATE WIH CALENDAR STATE
  useEffect(() => {
    setValue(globalDate);
  }, [globalDate]);

  useEffect(() => {
    getDaysOfMeetings(meetings);
  }, [meetings, value]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return meetingsView === "daily" ? (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider locale={enLocale} dateAdapter={AdapterDateFns}>
        <Box
          m={1}
          sx={{
            width: "99%",
            minWidth: "350px",
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Navigator />
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              label="Day picker"
              openTo="day"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                handleSelection(newValue);
              }}
              renderDay={renderDailyPickerDay}
              renderLoading={() => <CalendarPickerSkeleton />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: "100%",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    backgroundColor: "white",
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </StyledEngineProvider>
  ) : meetingsView === "weekly" ? (
    <LocalizationProvider locale={enLocale} dateAdapter={AdapterDateFns}>
      <Box
        m={1}
        sx={{
          width: "99%",
          minWidth: "350px",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Navigator />
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label="Week picker"
            openTo="day"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              handleSelection(newValue);
            }}
            renderDay={renderWeekPickerDay}
            renderLoading={() => <CalendarPickerSkeleton />}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="'Week of' MMM d"
          />
        </Box>
      </Box>
    </LocalizationProvider>
  ) : meetingsView === "monthly" ? (
    <LocalizationProvider locale={enLocale} dateAdapter={AdapterDateFns}>
      <Box
        m={1}
        sx={{
          width: "99%",
          minWidth: "350px",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Navigator />
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label="Month picker"
            openTo="day"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              handleSelection(newValue);
            }}
            renderDay={renderMonthPickerDay}
            renderLoading={() => <CalendarPickerSkeleton />}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="'Week of' MMM d"
          />
        </Box>
      </Box>
    </LocalizationProvider>
  ) : null;
};

export default Calendar;
