import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export type VTStateView = {
  view: string;
  setView: (view: string) => void;
};

const StateView = ({ view, setView }: VTStateView) => {
  const handleGeneralClick = () => {
    setView("general");
  };

  const handleAdvancedClick = () => {
    setView("advanced");
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Typography
          onClick={handleGeneralClick}
          sx={{
            color: "white",
            paddingRight: "9px",
            paddingLeft: "9px",
            borderBottom: view === "general" ? "1px white solid" : "none",
            cursor: "pointer",
          }}
        >
          General
        </Typography>
        {/* <Typography
          onClick={handleAdvancedClick}
          sx={{
            color: "white",
            paddingRight: "9px",
            paddingLeft: "9px",
            borderBottom: view === "advanced" ? "1px white solid" : "none",
            cursor: "pointer",
          }}
        >
          Advanced
        </Typography> */}
      </Stack>
    </>
  );
};

export default StateView;
