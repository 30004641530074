//Imports
import React from "react";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { parse, format } from "date-fns";
import { userStore } from "@state/store";
import { Box, Modal, Typography } from "@mui/material";

//Types
import {
  VTMeetings,
  VTInvite,
  OtherUsers,
  VTInviteObject,
} from "../../../types";

// Components
import RepeatMeeting from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/RepeatMeeting";
import MeetingTitle from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Title";
import Attendees from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Attendees";
import Moderators from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Moderators";
import MeetingTime from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/MeetingTime";
import Description from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Description";
import Close from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Close";
import RepeatMeetingString from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/RepeatMeetingString";
import Save from "@components/CreateAndEditMeetings/CreateAndEditMeetingComponents/Save";

//Requests
import {
  getMeeting,
  getAllUsersInformation,
  updateMeeting,
} from "@requests/Meetings";
//STORE
import { meetingsStore } from "@state/store";

//RRULE
import { RRule, rrulestr } from "rrule";

export type VTEditMeeting = {
  createMeetingModalIsOpen: boolean;
  editMeetingModalIsOpen: boolean;
  setSnackBarIsOpenForEditMeeting: (value: boolean) => void;
  setEditMeetingModalIsOpen: (editMeetingModalIsOpen: boolean) => void;
  setMeetingOverViewModalIsOpen: (meetingOverViewIsOpean: boolean) => void;
  setNeedsRefresh: (needsRefresh: boolean) => void;
};

const EditMeeting = ({
  editMeetingModalIsOpen,
  setEditMeetingModalIsOpen,
  setMeetingOverViewModalIsOpen,
  createMeetingModalIsOpen,
  setNeedsRefresh,
  setSnackBarIsOpenForEditMeeting,
}: VTEditMeeting) => {
  const { user } = userStore();
  const { meetingUUID } = meetingsStore();
  const [meeting, setMeeting] = useState<VTMeetings>({} as VTMeetings);

  //Existing meeting information
  const [editMeetingPageIsLoaded, setIsMeetingPageLoaded] =
    useState<boolean>(false);

  //useEffect to get the existing meeting details
  useEffect(() => {
    // const { uuid } = router.query;
    getMeeting(meetingUUID)
      .then((res) => {
        setMeeting(res);
      })
      .catch((e) => console.log(e));
  }, [meetingUUID]);

  // All the states currently being used
  const [users, setUsers] = useState<OtherUsers[]>([]);
  const [meetingTitle, setMeetingTitle] = useState<string>("");
  const [meetingStartDate, setMeetingStartDate] = useState("");
  const [meetingEndDate, setMeetingEndDate] = useState(meetingStartDate);
  const [startingTime, setStartingTime] = useState("");
  const [endingTime, setEndingTime] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMeetingAttendees, setSelectedMeetingAttendees] = useState<any>(
    []
  );
  const [invites, setInvites] = useState<VTInviteObject>({} as VTInviteObject);
  const [meetingTitleLengthErrorTooShort, setMeetingTitleLengthErrorTooShort] =
    useState<boolean>(false);
  const [meetingTitleLengthErrorTooLong, setMeetingTitleLengthErrorTooLong] =
    useState<boolean>(false);
  const [selectedModerators, setSelectedModerators] = useState<any>([]);
  const [meetingTitleError, setMeetingTitleError] = useState<boolean>(false);
  const [dateTimeRangeError, setDateTimeRangeError] = useState<boolean>(false);
  const [dateDayError, setDateDayError] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  //format the meetingStartDate and meetingEndDate to be today's date formatted as mm-dd-yyyy
  const today = new Date().toISOString().split("T")[0];
  const todayDate = new Date();

  useEffect(() => {
    if (meetingStartDate < today) {
      setDateDayError(true);
    } else {
      setDateDayError(false);
    }
  }, [meetingStartDate]);

  // useEffect to input existing time and ending
  useEffect(() => {
    //unixtime for the meeting start date and time
    const existingStartDateAndTime = new Date(meeting.start * 1000);

    //unixtime for the meeting end date and time
    const existingEndDateAndTime = new Date(
      (meeting?.start + meeting?.duration) * 1000
    );

    //meeting start time in a string
    const existingHours = existingStartDateAndTime.getHours();
    const existingMinutes = existingStartDateAndTime.getMinutes();
    const existingStartTimeString =
      existingHours + ":" + ("00" + existingMinutes).slice(-2) + ":00";

    //meeting end time in a string
    const existingEndHours = existingEndDateAndTime.getHours();
    const existingEndMinutes = existingEndDateAndTime.getMinutes();
    const existingEndTimeString = `${
      existingEndHours + ":" + ("00" + existingEndMinutes).slice(-2) + ":00"
    }`;

    if (existingStartTimeString.length === 7) {
      setStartingTime(`0${existingStartTimeString}`);
    } else {
      setStartingTime(existingStartTimeString);
    }

    if (existingEndTimeString.length === 7) {
      setEndingTime(`0${existingEndTimeString}`);
    } else {
      setEndingTime(existingEndTimeString);
    }
    if (
      existingStartTimeString === "0:00:00" ||
      ("00:00:00" && existingEndTimeString === "23:00:00")
    )
      setChecked(true);
  }, [meeting.start, meeting.duration]);

  //useEffect to prepopulate existing attendees and moderators from the meeting
  useEffect(() => {
    const existingAttendeesArray: any = meeting?.invites?.filter(
      (invite: VTInvite) => {
        if (invite.moderator === false) return invite;
      }
    );

    const existingAttendees = existingAttendeesArray?.map(
      (invite: VTInvite) => {
        return {
          email: invite.invitee_email,
          id: invite.invitee_id,
          name: invite.invitee_first_name + " " + invite.invitee_last_name,
          account_id: invite.account_id,
          user_id: invite.invitee_id,
          user_firstname: invite.invitee_first_name,
          user_lastname: invite.invitee_last_name,
        };
      }
    );

    setSelectedMeetingAttendees(existingAttendees);

    const existingModeratorsArray = meeting?.invites?.filter(
      (invite: VTInvite) => {
        if (invite.moderator) {
          return {
            invite,
          };
        }
      }
    );

    const existingModerators = existingModeratorsArray?.map(
      (moderator: VTInvite) => {
        return {
          account_id: moderator.account_id,
          email: moderator.invitee_email,
          id: moderator.invitee_id,
          name:
            moderator.invitee_first_name + " " + moderator.invitee_last_name,
          user_id: moderator.invitee_id,
          user_firstname: moderator.invitee_first_name,
          user_lastname: moderator.invitee_last_name,
        };
      }
    );

    setSelectedModerators(existingModerators);
  }, [meeting.invites]);

  //useEffect to find existing meeting start date, end date, start time, and end time
  useEffect(() => {
    if (meeting.start) {
      const existingStartDateAndTime = new Date(meeting.start * 1000);
      const existingEndDateAndTime = new Date(
        (meeting?.start - meeting?.duration) * 1000
      );
      //meeting start date in a format
      const unformattedExistingStartDate = existingStartDateAndTime
        .toLocaleString()
        .split(",")[0]
        .split("/");

      const unformattedExistingEndDate = existingEndDateAndTime
        .toLocaleString()
        .split(",")[0]
        .split("/");

      const numberStartYear = parseInt(unformattedExistingStartDate[2]);
      const numberStartMonth = parseInt(unformattedExistingStartDate[0]);
      const numberStartDay = parseInt(unformattedExistingStartDate[1]);

      const numberEndYear = parseInt(unformattedExistingEndDate[2]);
      const numberEndMonth = parseInt(unformattedExistingEndDate[0]);
      const numberEndDay = parseInt(unformattedExistingEndDate[1]);

      const formattedExistingStartDate = format(
        new Date(numberStartYear, numberStartMonth - 1, numberStartDay),
        "yyyy-MM-dd"
      );

      const formattedExistingEndDate = format(
        new Date(numberEndYear, numberEndMonth - 1, numberEndDay),
        "yyyy-MM-dd"
      );
      setMeetingStartDate(formattedExistingStartDate);
      setMeetingEndDate(formattedExistingEndDate);
    }
  }, [meeting.start, meeting.duration]);

  const meetingStartTime = `${meetingStartDate}T${startingTime}`;
  const meetingEndTime = `${meetingEndDate}T${endingTime}`;
  const formattedStartDateAndTime = parse(
    meetingStartTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );
  const formattedEndDateAndTime = parse(
    meetingEndTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );

  const unixStartTime = Math.floor(formattedStartDateAndTime?.getTime() / 1000);
  const unixEndTime = Math.floor(formattedEndDateAndTime?.getTime() / 1000);
  const duration = unixEndTime - unixStartTime;

  //States used for Repeat Meeting
  const [frequency, setFrequency] = useState("Never");
  const [byMonth, setByMonth] = useState("1");
  const [byMonthDay, setByMonthDay] = useState<number>(
    parseInt(meetingStartDate?.slice(8, 10))
  );
  const [existingByDay, setExistingByDay] = useState<any[]>([]);
  const [byDay, setByDay] = useState<any[]>([]);
  const [byDayWeekly, setByDayWeekly] = useState("");
  const [interval, setInterval] = useState(1);
  const [bySetPos, setBySetPos] = useState("1");
  const [count, setCount] = useState(9999);
  const [reoccuringMeetingEnd, setReoccuringMeetingEnd] = useState<Date>(
    new Date()
  );
  const [repeatMeeting, setRepeatMeeting] = useState("no");
  const [dtStart, setDtStart] = useState<Date>(todayDate);
  const [until, setUntil] = useState<Date>(new Date());
  const [rRule, setRRule] = useState<string>(meeting.rrule);

  const [activeDaysString, setActiveDaysString] = useState<string>("");
  const [monthString, setMonthString] = useState<string>("");
  const [dayString, setDayString] = useState<string>("");
  const [recurrenceChecked, setRecurrenceChecked] = useState<boolean>(
    meeting?.rrule ? true : false
  );
  const [rruleIsSaved, setRruleIsSaved] = useState<boolean>(false);

  const [recurrenceModalIsOpen, setRecurrenceModalIsOpen] =
    useState<boolean>(false);

  const [existingActiveDaysString, setExistingActiveDaysString] = useState("");

  //useEffect to find the meeting rrule settings
  useEffect(() => {
    const daysArray = [
      {
        id: 1,
        day: "M",
        value: RRule.MO,
        string: "MO",
        fullDay: "Monday",
      },
      {
        id: 2,
        day: "T",
        value: RRule.TU,
        string: "TU",
        fullDay: "Tuesday",
      },
      {
        id: 3,
        day: "W",
        string: "WE",
        value: RRule.WE,
        fullDay: "Wednesday",
      },
      {
        id: 4,
        day: "T",
        value: RRule.TH,
        string: "TH",
        fullDay: "Thursday",
      },
      {
        id: 5,
        day: "F",
        string: "FR",
        value: RRule.FR,
        fullDay: "Friday",
      },
      {
        id: 6,
        day: "S",
        string: "SA",
        value: RRule.SA,
        fullDay: "Saturday",
      },
      {
        id: 7,
        day: "S",
        string: "SU",
        value: RRule.SU,
        fullDay: "Sunday",
      },
    ];

    if (meeting.rrule && editMeetingModalIsOpen) {
      const freq = meeting.rrule.split("Z")[1].split(";")[0].split("=")[1];

      setRecurrenceChecked(true);
      setRepeatMeeting(freq.toLowerCase());

      if (freq !== "no") {
        const days = meeting.rrule.split("Z")[1].split(";")[3].split("=")[1];

        if (days.length > 2) {
          const newDaysArray = days.split(",");
          // map to set the existing active days string
          const newDays = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.fullDay;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingActiveDaysString(newDays.join(" and "));

          // a map to set existing by Day
          const newDaysMap = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.value;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingByDay(newDaysMap);
        } else {
          const newDaysArray = Array(days);

          const newDays = daysArray
            .map((day) => {
              if (newDaysArray.includes(day.string)) {
                return day.fullDay;
              }
            })
            .filter((day: any) => day !== undefined);

          setExistingActiveDaysString(newDays.toString());

          const newDaysMap = daysArray
            .map((day: any) => {
              if (newDaysArray.includes(day.string)) {
                return day.value;
              }
            })
            .filter((day: any) => day !== undefined);
          setExistingByDay(newDaysMap);
        }
      }
    }
  }, [meeting.rrule, meeting, editMeetingModalIsOpen, editMeetingPageIsLoaded]);

  //UseEffect to make sure that the end Date is always after the start Date.
  useEffect(() => {
    if (
      parseInt(meetingEndDate.split("-").join("")) <
      parseInt(meetingStartDate.split("-").join(""))
    ) {
      setMeetingEndDate(meetingStartDate);
    }
  }, [meetingEndDate, meetingStartDate]);

  //*Update Meeting End Date based on change of meeting Start Date*/
  useEffect(() => {
    setMeetingEndDate(meetingStartDate);
  }, [meetingStartDate]);

  useEffect(() => {
    //Updating the Start Time/date of the meeting based on the closest half hour
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);
    const h12h = now.getHours();
    const m12h = now.getMinutes();
    const timeStringStarting = h12h + ":" + ("00" + m12h).slice(-2) + ":00";
    if (checked) {
      setStartingTime("00:00:00");
      setEndingTime("23:55:00");
    } else {
      setStartingTime(timeStringStarting);
    }
  }, [checked]);

  useEffect(() => {
    getAllUsersInformation()
      .then((userList: any[]) => {
        const allUsersWithoutMe = userList.filter(
          (allOtherUsers: OtherUsers) => {
            if (user) {
              return allOtherUsers.user_id !== user.user_id;
            }
          }
        );
        setUsers(allUsersWithoutMe);
      })
      .catch((err) => {
        console.log("this is the error of getting all users information", err);
      });
  }, []);

  useEffect(() => {
    if (editMeetingPageIsLoaded) {
      if (selectedMeetingAttendees || selectedModerators) {
        const attendeeInvites: [
          {
            invitee_id: string;
            moderator: boolean;
            invitor: boolean;
            editor: boolean;
          }
        ] = selectedMeetingAttendees?.map((attendee: any) => {
          return {
            invitee_id: attendee.id,
            moderator: false,
            invitor: false,
            editor: false,
          };
        });

        const moderatorInvites: [
          {
            invitee_id: string;
            moderator: boolean;
            invitor: boolean;
            editor: boolean;
          }
        ] = selectedModerators?.map((moderator: any) => {
          return {
            invitee_id: moderator.id,
            moderator: true,
            invitor: false,
            editor: true,
          };
        });
        const allInvites: {
          invitee_id: string;
          moderator: boolean;
          invitor: boolean;
          editor: boolean;
        }[] = [...attendeeInvites, ...moderatorInvites];
        const invitesObject: VTInviteObject = {
          invitees: allInvites,
          message: description,
        };
        setInvites(invitesObject);
      }
    }
  }, [selectedMeetingAttendees, selectedModerators, editMeetingPageIsLoaded]);

  const updateMeetingFunction = async () => {
    const params: {
      description: string;
      rrule?: string;
      start: number;
      duration: number;
      name: string;
      meetingId: string;
    } = {
      description: description || "",
      rrule: rRule || "",
      duration: duration,
      name: meetingTitle || meeting?.name,
      start: unixStartTime,
      meetingId: meeting.meeting_id,
    };

    updateMeeting(meeting.meeting_id, invites, params)
      .then((data: any) => {
        setNeedsRefresh(true);
        setSnackBarIsOpenForEditMeeting(true);
        setEditMeetingModalIsOpen(false);
        setMeetingOverViewModalIsOpen(true);
      })
      .catch(
        (error: {
          code: number;
          exception: string;
          message: string;
          parameter_errors: [
            {
              status: [
                {
                  error: string;
                  message: string;
                }
              ];
            }
          ];
          parameters: object;
        }) => {
          console.log("this is the error", error.parameter_errors);
          if (
            error?.parameter_errors[0]?.status[0]?.error.includes(
              "PARAMETER_REQUIRED"
            )
          ) {
            alert("Please enter a meeting subject");
            setMeetingTitleError(true);
          } else if (
            error?.parameter_errors[0]?.status[0]?.error.includes(
              "INVALID_INTEGER_RANGE"
            )
          ) {
            setDateTimeRangeError(true);
            alert("Please enter a valid duration of atleast 30 minutes");
          } else if (
            error?.parameter_errors[0]?.status[0]?.error.includes(
              "INVALID_MIN_CHAR_LENGTH"
            )
          ) {
            setMeetingTitleLengthErrorTooShort(true);
            alert("Please enter a meeting title longer than 2 characters");
          } else if (
            error?.parameter_errors[0]?.status[0]?.error.includes(
              "INVALID_MAX_CHAR_LENGTH"
            )
          ) {
            setMeetingTitleLengthErrorTooShort(false);
            setMeetingTitleLengthErrorTooLong(true);
            alert("Please enter a meeting title less than 50 characters");
          } else {
            alert("Error Updating Meeting");
          }
        }
      );
  };

  const handleCloseButton = () => {
    setEditMeetingModalIsOpen(false);
  };

  useEffect(() => {
    if (meeting && meetingStartDate) {
      setByMonthDay(parseInt(meetingStartDate?.slice(8, 10)));
      setRRule(meeting.rrule);
      setMeetingTitle(meeting.name);
      setDescription(meeting.description);
      setIsMeetingPageLoaded(true);
    } else {
      setIsMeetingPageLoaded(false);
    }
  }, [meeting, meetingStartDate]);

  return (
    <>
      {editMeetingPageIsLoaded ? (
        <Modal
          hideBackdrop
          open={editMeetingModalIsOpen}
          onClose={handleCloseButton}
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              right: 0,
              width: { xs: "100%", md: "75%", lg: "65%", xl: "40%" },
              height: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "hidden",
              zIndex: "9999",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "75px",
                }}
              >
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      height: "39px",
                      width: "100%",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "32.15px",
                    }}
                  >
                    Edit meeting
                  </Typography>
                </Box>

                <Close handleCloseButton={handleCloseButton} />
              </Box>

              <MeetingTitle
                createMeetingModalIsOpen={createMeetingModalIsOpen}
                meetingTitle={meetingTitle}
                meetingTitleError={meetingTitleError}
                setMeetingTitleError={setMeetingTitleError}
                setMeetingTitle={setMeetingTitle}
                meetingTitleLengthErrorTooShort={
                  meetingTitleLengthErrorTooShort
                }
                meetingTitleLengthErrorTooLong={meetingTitleLengthErrorTooLong}
                existingMeetingTitle={meeting.name}
              />

              <Attendees
                selectedMeetingAttendees={selectedMeetingAttendees}
                setSelectedMeetingAttendees={setSelectedMeetingAttendees}
                users={users}
                setUsers={setUsers}
              />

              <Moderators
                users={users}
                setUsers={setUsers}
                selectedModerators={selectedModerators}
                setSelectedModerators={setSelectedModerators}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MeetingTime
                  checked={checked}
                  setChecked={setChecked}
                  meetingStartDate={meetingStartDate}
                  setMeetingStartDate={setMeetingStartDate}
                  meetingEndDate={meetingEndDate}
                  setMeetingEndDate={setMeetingEndDate}
                  startingTime={startingTime}
                  setStartingTime={setStartingTime}
                  endingTime={endingTime}
                  setEndingTime={setEndingTime}
                  dateTimeRangeError={dateTimeRangeError}
                  dateDayError={dateDayError}
                />
              </Box>

              <RepeatMeeting
                editMeetingModalIsOpen={editMeetingModalIsOpen}
                existingByDay={existingByDay}
                checked={checked}
                existingActiveDaysString={existingActiveDaysString}
                frequency={frequency}
                byMonth={byMonth}
                byMonthDay={byMonthDay}
                byDay={byDay}
                bySetPos={bySetPos}
                interval={interval}
                count={count}
                until={until}
                setCount={setCount}
                setUntil={setUntil}
                setFrequency={setFrequency}
                setByMonth={setByMonth}
                setByMonthDay={setByMonthDay}
                setByDay={setByDay}
                setInterval={setInterval}
                setBySetPos={setBySetPos}
                byDayWeekly={byDayWeekly}
                setByDayWeekly={setByDayWeekly}
                repeatMeeting={repeatMeeting}
                setRepeatMeeting={setRepeatMeeting}
                meetingStartDate={meetingStartDate}
                setMeetingStartDate={setMeetingStartDate}
                dtStart={dtStart}
                setDtStart={setDtStart}
                setRRule={setRRule}
                reoccuringMeetingEnd={reoccuringMeetingEnd}
                setReoccuringMeetingEnd={setReoccuringMeetingEnd}
                startingTime={startingTime}
                activeDaysString={activeDaysString}
                setActiveDaysString={setActiveDaysString}
                dayString={dayString}
                setDayString={setDayString}
                monthString={monthString}
                setMonthString={setMonthString}
                setRruleIsSaved={setRruleIsSaved}
                recurrenceChecked={recurrenceChecked}
                setRecurrenceChecked={setRecurrenceChecked}
                recurrenceModalIsOpen={recurrenceModalIsOpen}
                setRecurrenceModalIsOpen={setRecurrenceModalIsOpen}
              />

              {meeting.rrule_description || rruleIsSaved ? (
                <RepeatMeetingString
                  rruleDescription={meeting.rrule_description}
                  editMeetingModalIsOpen={editMeetingModalIsOpen}
                  activeDaysString={activeDaysString}
                  repeatMeeting={repeatMeeting}
                  dayString={dayString}
                  monthString={monthString}
                  meetingStartDate={meetingStartDate}
                  byMonthDay={byMonthDay}
                  setDayString={setDayString}
                  recurrenceChecked={recurrenceChecked}
                  setRecurrenceChecked={setRecurrenceChecked}
                  recurrenceModalIsOpen={recurrenceModalIsOpen}
                  setRecurrenceModalIsOpen={setRecurrenceModalIsOpen}
                />
              ) : (
                <></>
              )}

              <Description
                createMeetingModalIsOpen={createMeetingModalIsOpen}
                description={description}
                setDescription={setDescription}
                existingDescription={description}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",

                  borderRadius: "4px",
                }}
              >
                <Save
                  saveMeeting={updateMeetingFunction}
                  createMeetingModalIsOpen={createMeetingModalIsOpen}
                />
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditMeeting;
