import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";

export type VTSearchFieldProps = TextFieldProps;

const StyledSearchTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: `${alpha(theme.palette.primary.main, 0.05)}`,
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    paddingLeft: "0px",
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    borderRadius: "0 10px 10px 0",
    padding: "14px",
    paddingLeft: "0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputAdornment-root": {
    marginRight: "0px",
  },
}));

const SearchField = (props: VTSearchFieldProps) => {
  return (
    <StyledSearchTextField
      {...props}
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" disablePointerEvents={true}>
            <IconButton>
              <Search color="primary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
