import { Box, MenuItem, Select } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState, useEffect, useRef } from "react";
import { VTMeetings, VTInvite } from "types";
import { getCurrentUserInfo } from "@cognito";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type VTInviteStatusSelectorProps = {
  inviteId: string;
  meeting: VTMeetings;
  userId: string;

  setNeedsRefresh: (needsRefresh: boolean) => void;
};

const InviteStatusSelector = ({
  inviteId,
  meeting,
  userId,

  setNeedsRefresh,
}: VTInviteStatusSelectorProps) => {
  const [openInviteStatus, setOpenInviteStatus] = useState<boolean>(false);
  const [field, setField] = useState<string>("");
  const [isInviteStatusSelectorLoaded, setIsInviteStatusSelectorLoaded] =
    useState(false);

  const ref = useRef(null);

  const userStatusArray: any = meeting.invites.filter((invite: VTInvite) => {
    if (invite?.invitee_id === userId) {
      return invite.status;
    }
  });
  const userStatus: string = userStatusArray.map((invite: VTInvite) => {
    return invite.status;
  });

  const userStatusToUse = userStatus[0];

  useEffect(() => {
    const handleChange = async () => {
      const user = await getCurrentUserInfo();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/invites/${inviteId}`;

      try {
        const res = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: jwt,
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            status: field,
            message: "Changed status",
          }),
        });
        if (res.ok) {
          const data = await res;
          setNeedsRefresh(true);
          return data;
        }
      } catch (e) {
        console.log("failed to changed status", e);
      }
    };
    handleChange();
  }, [field]);

  useEffect(() => {
    if (userStatusToUse && inviteId) {
      setIsInviteStatusSelectorLoaded(true);
    }
  }, [userStatusToUse, inviteId]);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: Event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenInviteStatus(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ref);

  return (
    <>
      {isInviteStatusSelectorLoaded ? (
        <Select
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            "& .MuiSelect-select": {
              padding: "0px",
            },
          }}
          IconComponent={() => (
            <ExpandMoreIcon
              onClick={() => setOpenInviteStatus(true)}
              style={{
                color: "var(--visionable-dark-blue",
                cursor: "pointer",
                borderLeft: "1px solid #cccccc",
              }}
            />
          )}
          ref={ref}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {},
              },
            },
            disableScrollLock: true,
          }}
          open={openInviteStatus}
          value={field}
          renderValue={(value: string) => {
            if (value === "accepted") {
              return (
                <MenuItem>
                  <CheckIcon />
                  Accepted
                </MenuItem>
              );
            } else if (value === "rejected") {
              return (
                <MenuItem>
                  <CloseIcon />
                  Declined
                </MenuItem>
              );
            } else if (value === "tentative") {
              return (
                <MenuItem>
                  <HelpOutlineIcon />
                  Tentative
                </MenuItem>
              );
            } else {
              return (
                <>
                  {userStatusToUse === "accepted" ? (
                    <MenuItem>
                      <CheckIcon />
                      Accepted
                    </MenuItem>
                  ) : userStatusToUse === "tentative" ? (
                    <MenuItem>
                      {" "}
                      <HelpOutlineIcon />
                      Tentative
                    </MenuItem>
                  ) : userStatusToUse === "rejected" ? (
                    <MenuItem>
                      <CloseIcon />
                      Declined
                    </MenuItem>
                  ) : (
                    <MenuItem>Invited</MenuItem>
                  )}
                </>
              );
            }
          }}
          onChange={(event) => {
            setField(event.target.value);
          }}
          displayEmpty
        >
          <MenuItem value={"accepted"}>
            <CheckIcon />
            Accept
          </MenuItem>
          <MenuItem value={"tentative"}>
            <HelpOutlineIcon />
            Tentative
          </MenuItem>
          <MenuItem value={"rejected"}>
            <CloseIcon />
            Decline
          </MenuItem>
          <MenuItem disabled value={"invited"}>
            Invited
          </MenuItem>
        </Select>
      ) : (
        <></>
      )}
    </>
  );
};

export default InviteStatusSelector;
