///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR APPLYING FILTERS TO MEETINGS
///
///////////////////////////////////////////////////////////////
import { useState, useEffect } from "react";

// MUI COMPONENTS
import { Box, Stack, IconButton, Popover } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";

//REQUESTS
import { updateMeetingInvite } from "../../../@requests/Meetings";

//GLOBAL STATE
import { meetingsStore } from "@state/store";

///////////////////////////////////////////////////////////////
///
///  FILTER MEETINGS POPOVER DROPDOWN
///
///////////////////////////////////////////////////////////////

const FilterPopover = () => {
  //STATE
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  // GLOBAL STATE
  const {
    inviteStatusUpdate,
    setInviteStatusUpdate,
    meetingsFilter,
    setMeetingsFilter,
  } = meetingsStore();

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (event: any) => {
    setMeetingsFilter(event.currentTarget.id);
    setInviteStatusUpdate(!inviteStatusUpdate);
    handleClose();
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  //   useEffect(() => {
  //     console.log(meetingsFilter);
  //   }, [meetingsFilter]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <>
      <Box sx={{ justifySelf: "flex-end", alignSelf: "center" }}>
        <IconButton onClick={handleClick}>
          <FilterListIcon
            fontSize="small"
            style={{ color: "black", cursor: "pointer" }}
          />
        </IconButton>
      </Box>

      <Popover
        elevation={2}
        sx={{ borderRadius: "10px" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack
          p={0.5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            id="all"
            direction={"row"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor:
                meetingsFilter === "all" ? "rgba(18, 113, 238, 0.1)" : "white",
            }}
            onClick={(e) => handleSelect(e)}
          >
            <Box p={1}>All invites</Box>

            <CheckIcon
              fontSize="small"
              sx={{ visibility: meetingsFilter === "all" ? "flex" : "hidden" }}
            />
          </Stack>

          <Stack
            id="declined"
            direction={"row"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor:
                meetingsFilter === "declined"
                  ? "rgba(18, 113, 238, 0.1)"
                  : "white",
            }}
            onClick={(e) => handleSelect(e)}
          >
            <Box p={1}>Declined invites</Box>
            <CheckIcon
              fontSize="small"
              sx={{
                visibility: meetingsFilter === "declined" ? "flex" : "hidden",
              }}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default FilterPopover;
