import { useState, useEffect, useContext } from "react";

//MUI COMPONENTS
import { Box, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";

// CUSTOM  COMPONENTS
import PendingInviteCard from "././PendingInviteCard";
//TYPES
import { VTInvite, VTMeetings } from "../../../types";
//DATE LIBRARY
import { compareAsc } from "date-fns";
// GLOBAL STATE
import { userStore, meetingsStore } from "@state/store";

//REQUESTS
import { getMeetings } from "@requests/Meetings";

export type VTPendingInvitesProps = {
  meetings: any;
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
};

const PendingInvites = ({
  meetings,
  needsRefresh,
  setNeedsRefresh,
}: VTPendingInvitesProps) => {
  //GLOBAL STATE
  const { user } = userStore();
  const { inviteStatusUpdate } = meetingsStore();

  //STATE
  const [isPendingInvitesOpen, setIsPendingInvitesOpen] =
    useState<boolean>(true);
  const [pendingMeetings, setPendingMeetings] = useState<any[]>([]);
  const [isPendingInvitesLoaded, setIsPendingInvitesLoaded] =
    useState<boolean>(false);

  const [fetchingData, setFetchingData] = useState<boolean>(true);

  // TIME OPERATIONS
  const today = new Date();
  const yesterdayMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 365
  );
  const yearBack = yesterdayMidnight.getTime() / 1000;
  const laterMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 365
  );
  const yearForward = laterMidnight.getTime() / 1000;

  const todayMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  );
  const todayMidnightEpoch = todayMidnight.getTime() / 1000;

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION SECTION
  ///
  ///////////////////////////////////////////////////////////////
  const onClickArrow = () => {
    setIsPendingInvitesOpen(!isPendingInvitesOpen);
  };

  const omitPagination = async (
    midnightEpochDayBefore: any,
    TodayMidnightEpoch: any,
    status: string
  ) => {
    const data = await getMeetings(
      midnightEpochDayBefore,
      TodayMidnightEpoch,
      1,
      {
        status: status,
      }
    );
    const allData = [];

    for (let index = 1; index <= data.last_page; index++) {
      const data = await getMeetings(
        midnightEpochDayBefore,
        TodayMidnightEpoch,
        index,
        {
          status: status,
        }
      );
      allData.push(...data.data);
    }
    return allData;
  };
  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFE CYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    omitPagination(yearBack, yearForward, "invited").then((meetings) => {
      const results = meetings.filter(
        (m: VTMeetings) => m.start >= todayMidnightEpoch
      );
      const sortedResult = results?.sort((a: VTMeetings, b: VTMeetings) => {
        return compareAsc(new Date(a.start * 1000), new Date(b.start * 1000));
      });
      if (sortedResult && fetchingData) {
        setPendingMeetings(sortedResult);
        setFetchingData(false);
      }
    });

    return () => setFetchingData(true);
  }, [inviteStatusUpdate, meetings]);

  ///////////////////////////////////////////////////////////////
  ///
  /// STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Box p={0} width={"100%"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" p={2}>
          Invites
        </Typography>

        {isPendingInvitesOpen ? (
          <IconButton onClick={onClickArrow}>
            <KeyboardArrowDownIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton onClick={onClickArrow}>
            <KeyboardArrowUpIcon color="primary" />
          </IconButton>
        )}
      </Box>

      {isPendingInvitesOpen ? (
        <Stack pl={1} spacing={2}>
          {pendingMeetings?.map((meeting: VTMeetings) => (
            <PendingInviteCard
              key={meeting.meeting_id}
              meeting={meeting}
              needsRefresh={needsRefresh}
              setNeedsRefresh={setNeedsRefresh}
            />
          ))}
        </Stack>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PendingInvites;
