///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR UPDATING USER INVITE STATUS
///
///////////////////////////////////////////////////////////////

import { useState, useEffect, useContext } from "react";

// MUI COMPONENTS
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// STYLES
import styles from "./index.module.css";

// API COMPONENTS
import { updateMeetingInvite } from "@requests/Meetings";

//GLOBAL STATE
import { meetingsStore } from "@state/store";

//TYPES
import { VTMeetings } from "types";

///////////////////////////////////////////////////////////////
///
///  INVITE STATUS BUTTONS COMPONENT
///
///////////////////////////////////////////////////////////////
export type VTInviteStatusButtons = {
  inviteId: any;
  userStatus: string;
  meeting?: VTMeetings;
  disabled?: boolean;
};

const InviteStatusButtons = ({
  inviteId,
  userStatus,
  meeting,
  disabled,
}: VTInviteStatusButtons) => {
  // GLOBAL STATE
  const { inviteStatusUpdate, setInviteStatusUpdate } = meetingsStore();

  // Selector Input Fields
  const userStatusToUse = userStatus[0];
  const inviteIdToUse = inviteId[0];
  const [field, setField] = useState(userStatusToUse);
  const [isInviteStatusLoaded, setIsInviteStatusLoaded] =
    useState<boolean>(false);

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    if (userStatusToUse && inviteIdToUse) {
      setIsInviteStatusLoaded(true);
    } else {
      setIsInviteStatusLoaded(false);
    }
  }, [userStatus, field]);

  const handleChange = async (event: any) => {
    const newStatus = event.target.value;
    updateMeetingInvite(inviteIdToUse, newStatus, "Changed status")
      .then((data) => {
        setField(newStatus);
        setInviteStatusUpdate(!inviteStatusUpdate);
      })
      .catch((e) => {
        console.error("failed to changed status", e);
      });
  };

  useEffect(() => {
    setField("invited");
    setInviteStatusUpdate(false);
  }, [meeting]);

  return (
    // Selector for Accept/decline meeting invtation
    <>
      {isInviteStatusLoaded ? (
        <Select
          disabled={disabled}
          className={styles.selectorButton}
          value={field}
          renderValue={(value: string) => {
            if (value === "accepted") {
              return (
                <MenuItem className={styles.selectorTag}>
                  <CheckIcon />
                  Accepted
                </MenuItem>
              );
            } else if (value === "rejected") {
              return (
                <MenuItem className={styles.selectorTag}>
                  <CloseIcon />
                  Declined
                </MenuItem>
              );
            } else if (value === "tentative") {
              return (
                <MenuItem className={styles.selectorTag}>
                  <HelpOutlineIcon />
                  Tentative
                </MenuItem>
              );
            } else {
              return (
                <>
                  {userStatusToUse === "accepted" ? (
                    <MenuItem className={styles.selectorTag}>
                      <CheckIcon color={disabled ? "disabled" : "secondary"} />
                      Accepted
                    </MenuItem>
                  ) : userStatusToUse === "tentative" ? (
                    <MenuItem className={styles.selectorTag}>
                      {" "}
                      <HelpOutlineIcon
                        color={disabled ? "disabled" : "secondary"}
                      />
                      Tentative
                    </MenuItem>
                  ) : userStatusToUse === "rejected" ? (
                    <MenuItem className={styles.selectorTag}>
                      <CloseIcon color={disabled ? "disabled" : "secondary"} />
                      Declined
                    </MenuItem>
                  ) : (
                    <MenuItem className={styles.selectorTag}>Invited</MenuItem>
                  )}
                </>
              );
            }
          }}
          onChange={(event) => {
            setField(event.target.value);
            handleChange(event);
          }}
          displayEmpty
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          <MenuItem value={"accepted"}>
            <CheckIcon />
            Accept
          </MenuItem>
          <MenuItem value={"tentative"}>
            <HelpOutlineIcon />
            Tentative
          </MenuItem>
          <MenuItem value={"rejected"}>
            <CloseIcon />
            Decline
          </MenuItem>
          <MenuItem disabled value={"invited"}>
            Invited
          </MenuItem>
        </Select>
      ) : (
        <></>
      )}
    </>
  );
};

export default InviteStatusButtons;
