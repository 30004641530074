///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR CHANGING DATES ON MEETINGS VIEWS
///
///////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";

//TIME LIBRARY
import {
  format,
  endOfMonth,
  startOfMonth,
  lastDayOfWeek,
  startOfWeek,
} from "date-fns";
// MUI COMPONENTS
import { Box, Stack, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// GLOBAL STATE
import { meetingsStore } from "@state/store";

// CUSTOM COMPONENTS
import FilterPopover from "@components/MeetingComponents/FilterPopover";
import MeetingsRefreshButton from "@components/MeetingComponents/MeetingsRefresh";
///////////////////////////////////////////////////////////////
///
///  DAILY VIEW COMPONENT
///
///////////////////////////////////////////////////////////////

const DaySelector = () => {
  // GLOBAL STATE
  const { globalDate, setGlobalDate, meetingsView } = meetingsStore();

  // LOCAL STATE
  const [yesterday, setYesterday] = useState(new Date());

  const [tomorrow, setTomorrow] = useState(new Date());

  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] = useState(
    new Date()
  );
  const [lastDayOfCurrentWeek, setLastDayOfCurrentWeek] = useState(new Date());

  const [firstDayOfCurrentMonth, setFirstDayOfCurrentMonth] = useState(
    new Date()
  );
  const [lastDayOfCurrentMonth, setLastDayOfCurrentMonth] = useState(
    new Date()
  );

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // NAVIGATION BUTTONS TOGGLE BETWEEN DAYS FOR ALL 3 VIEWS
  const hanldeBackwardDateClick = () => {
    switch (meetingsView) {
      case "daily":
        setGlobalDate(yesterday);
        break;
      case "weekly":
        setGlobalDate(new Date(globalDate.getTime() - 7 * 24 * 60 * 60 * 1000));

        break;
      case "monthly":
        setGlobalDate(
          new Date(globalDate.getTime() - 30 * 24 * 60 * 60 * 1000)
        );
        break;
      default:
        break;
    }
  };

  const handleForwardDateClick = () => {
    switch (meetingsView) {
      case "daily":
        setGlobalDate(tomorrow);
        break;
      case "weekly":
        setGlobalDate(new Date(globalDate.getTime() + 7 * 24 * 60 * 60 * 1000));

        break;
      case "monthly":
        setGlobalDate(
          new Date(globalDate.getTime() + 30 * 24 * 60 * 60 * 1000)
        );
        break;
      default:
        break;
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  /// LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  //CONECTS TO CALENDAR VIA GLOBAL DATES
  useEffect(() => {
    const yesterday = new Date(globalDate.getTime() - 24 * 60 * 60 * 1000);
    setYesterday(yesterday);
    const tomorrow = new Date(globalDate.getTime() + 24 * 60 * 60 * 1000);
    setTomorrow(tomorrow);

    const firstDayOfCurrentWeek = startOfWeek(new Date(globalDate), {
      weekStartsOn: 1,
    });
    setFirstDayOfCurrentWeek(firstDayOfCurrentWeek);
    const lastDayOfCurrentWeek = lastDayOfWeek(new Date(globalDate), {
      weekStartsOn: 1,
    });
    setLastDayOfCurrentWeek(lastDayOfCurrentWeek);

    const firstDayOfCurrentMonth = startOfMonth(new Date(globalDate));
    setFirstDayOfCurrentMonth(firstDayOfCurrentMonth);
    const lastDayOfCurrentMonth = endOfMonth(new Date(globalDate));
    setLastDayOfCurrentMonth(lastDayOfCurrentMonth);
  }, [globalDate]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  switch (meetingsView) {
    case "daily":
      return (
        <Stack
          p={{ sm: 1, md: 1, lg: 2 }}
          sx={{
            backgroundColor: {
              xs: "white",
              sm: "white",
              md: "white",
              lg: "rgba(250, 250, 250, 1)",
              xl: "rgba(250, 250, 250, 1)",
            },
          }}
          width={"100%"}
          minWidth={"10%"}
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
        >
          <Box sx={{ justifySelf: "flex-end", alignSelf: "flex-end" }}>
            <></>
          </Box>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
            width={{ xs: "80%", sm: "50%", md: "40%", lg: "35%", xl: "30%" }}
          >
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={hanldeBackwardDateClick}>
                <ArrowBackIcon fontSize="small" />
              </IconButton>
            </Box>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {globalDate.toDateString()}
            </Stack>
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={handleForwardDateClick}>
                <ArrowForwardIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction={"row"}>
            <MeetingsRefreshButton />
            <FilterPopover />
          </Stack>
        </Stack>
      );

    case "weekly":
      return (
        <Stack
          p={2}
          width={"100%"}
          minWidth={"10%"}
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
        >
          <Box sx={{ justifySelf: "flex-end", alignSelf: "flex-end" }}>
            <></>
          </Box>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
            width={{ xs: "80%", sm: "50%", md: "40%", lg: "35%", xl: "30%" }}
          >
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={hanldeBackwardDateClick}>
                <ArrowBackIcon
                  fontSize="small"
                  style={{ color: "black", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
            <Stack
              spacing={2}
              width={"calc(100vh/5)"}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box>{format(firstDayOfCurrentWeek, "dd LLL")}</Box>
              <Box>-</Box>
              <Box>{format(lastDayOfCurrentWeek, "dd LLL")}</Box>
            </Stack>
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={handleForwardDateClick}>
                <ArrowForwardIcon
                  fontSize="small"
                  style={{ color: "black", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction={"row"}>
            <MeetingsRefreshButton />
            <FilterPopover />
          </Stack>
        </Stack>
      );

    case "monthly":
      return (
        <Stack
          p={2}
          width={"100%"}
          minWidth={"10%"}
          spacing={2}
          direction="row"
          justifyContent={"space-between"}
        >
          <Box sx={{ justifySelf: "flex-end", alignSelf: "flex-end" }}>
            <></>
          </Box>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
            width={{ xs: "80%", sm: "50%", md: "40%", lg: "35%", xl: "30%" }}
          >
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={hanldeBackwardDateClick}>
                <ArrowBackIcon
                  fontSize="small"
                  style={{ color: "black", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
            <Stack
              spacing={2}
              width={"calc(100vh/5)"}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box>{format(firstDayOfCurrentMonth, "dd LLL")}</Box>
              <Box alignSelf={"center"}> - </Box>
              <Box>{format(lastDayOfCurrentMonth, "dd LLL")}</Box>
            </Stack>
            <Box sx={{ display: "flex", direction: "Column" }}>
              <IconButton onClick={handleForwardDateClick}>
                <ArrowForwardIcon
                  fontSize="small"
                  style={{ color: "black", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction={"row"}>
            <MeetingsRefreshButton />
            <FilterPopover />
          </Stack>
        </Stack>
      );
    default:
      return <></>;
  }
};

export default DaySelector;
