import { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import styles from "./index.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//TIME LIBRARY
import { format } from "date-fns";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import enLocale from "date-fns/locale/en-GB";

import { meetingsStore } from "@state/store";

//CALENDAR HEADER IMPORT
import ForStartDate from "../CalendarNavigator/ForStartDate";

export type VTMeetingTime = {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  meetingStartDate: string;
  setMeetingStartDate: (meetingStartDate: string) => void;
  meetingEndDate: string;
  setMeetingEndDate: (meetingEndDate: string) => void;
  startingTime: string;
  setStartingTime: (startingTime: string) => void;
  endingTime: string;
  setEndingTime: (endingTime: string) => void;
  dateTimeRangeError: boolean;
  dateDayError: boolean;
};

const MeetingTime = ({
  checked,
  setChecked,
  meetingStartDate,
  setMeetingStartDate,
  meetingEndDate,
  setMeetingEndDate,
  startingTime,
  setStartingTime,
  endingTime,
  setEndingTime,
  dateTimeRangeError,
  dateDayError,
}: VTMeetingTime) => {
  const { dateForCalendar, setDateForCalendar } = meetingsStore();

  const [timeInterval, setTimeInterval] = useState<string[]>([]);

  const handleAllDayToggle = () => {
    setChecked(!checked);
    setStartingTime("00:00:00");
    setEndingTime("23:55:00");
  };

  const handleSelection = (date: Date | null) => {
    if (date) {
      setMeetingStartDate(format(date, `yyyy-MM-dd`));
      setMeetingEndDate(format(date, "yyyy-MM-dd"));
      setDateForCalendar(date);
    }
  };

  const handleTime = (string: string) => {
    const now = new Date();
    let timeStringStarting = "";
    const formattedNow = format(now, "yyyy-MM-dd");

    // now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30); // Now we let you create a meeting starting Very Soon
    const h12h = now.getHours();
    const m12h = now.getMinutes();

    if (h12h.toString().length === 1) {
      timeStringStarting = `0${h12h}` + ":" + ("00" + m12h).slice(-2) + ":00";
    } else {
      timeStringStarting = h12h + ":" + ("00" + m12h).slice(-2) + ":00";
    }

    return string < timeStringStarting && formattedNow == meetingStartDate
      ? true
      : false;
  };
  //constructing time interval for select options
  useEffect(() => {
    const timeArray = () => {
      let timeStart = 0;
      const x = 5;
      const array = [];

      for (let i = 0; timeStart < 1440; i++) {
        const hh = Math.floor(timeStart / 60);
        const mm = timeStart % 60;
        array[i] =
          ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2) + ":00";
        timeStart = timeStart + x;
      }

      setTimeInterval(array);
    };
    timeArray();
  }, []);

  //UseEffect to make sure that the endingTime is always after the start time
  useEffect(() => {
    const nums = startingTime.split(":");
    const hours = nums[0];
    const minutes = nums[1];
    const seconds = nums[2];

    if (endingTime <= startingTime) {
      if (minutes === "00") {
        setEndingTime(`${hours}` + ":05" + `:${seconds}`);
      } else {
        if (minutes === "55") {
          setEndingTime(`${parseInt(hours) + 1}` + ":00" + `:${seconds}`);
        } else {
          setEndingTime(
            `${hours}` + `:${parseInt(minutes) + 5}` + `:${seconds}`
          );
        }
      }
    } else if (startingTime > endingTime) {
      if (minutes === "55") {
        setEndingTime(`${parseInt(hours) + 1}` + ":00" + `:${seconds}`);
      } else {
        setEndingTime(`${hours}` + `:${parseInt(minutes) + 5}` + `:${seconds}`);
      }
    }
  }, [endingTime, startingTime]);

  // useEffect to make sure that the ending time of the meeting is always thirty minutes after the starting time.
  useEffect(() => {
    const nums = startingTime.split(":");
    const hours = nums[0];
    const minutes = nums[1];
    const seconds = nums[2];

    if (!checked) {
      if (minutes === "00") {
        setEndingTime(`${hours}` + ":30" + `:${seconds}`);
      } else if (minutes === "30") {
        if (hours.split("")[0] === "0" && hours.split("")[1] !== "9") {
          setEndingTime(
            `0${(parseInt(hours) + 1).toString()}` + ":00" + `:${seconds}`
          );
        } else {
          setEndingTime(
            `${(parseInt(hours) + 1).toString()}` + ":00" + `:${seconds}`
          );
        }
      }
    }
  }, [startingTime, checked]);

  useEffect(() => {
    setDateForCalendar(new Date());
  }, []);

  return (
    <>
      <Box
        sx={{
          paddingTop: "18.5px",
          width: "100%",
          display: "flex",
          // justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: checked ? "flex-start" : "space-between",
              }}
            >
              {checked ? (
                <>
                  <Typography
                    sx={{
                      display: "flex",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      fontSize: "18px",
                      lineHeight: "120%",
                      width: checked ? "200px" : "100%",
                      height: "38.7px",
                      alignItems: "center",
                    }}
                  >
                    Date
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontSize: "18px",
                    lineHeight: "120%",
                  }}
                >
                  Start Date
                </Typography>
              )}
              <Stack m={1} direction={"row"} spacing={1}>
                <Typography>All Day</Typography>
                <Switch
                  color="success"
                  checked={checked}
                  onChange={handleAllDayToggle}
                />
              </Stack>
            </Box>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  showToolbar={true}
                  ToolbarComponent={ForStartDate}
                  disablePast
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={dateForCalendar}
                  onChange={(date: Date | null) => {
                    handleSelection(date);
                  }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          {...params}
                          error={false}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </>
                    );
                  }}
                />
              </LocalizationProvider>
            </StyledEngineProvider>
          </Box>

          {checked ? (
            <></>
          ) : (
            <>
              <Box
                sx={{
                  marginLeft: "20px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontSize: "18px",
                    lineHeight: "120%",
                    width: "100%",
                    height: "38.7px",
                    alignItems: "center",
                  }}
                >
                  Start Time
                </Typography>

                <Select
                  value={startingTime}
                  id="input"
                  onChange={(e) => {
                    setStartingTime(e.target.value);
                  }}
                  required
                  className={styles["select-start-time"]}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "225px",
                        width: "175px",
                      },
                    },
                  }}
                  IconComponent={(props) => (
                    <ExpandMoreIcon
                      style={{
                        color: "var(--visionable-dark-blue)",
                        cursor: "pointer",
                      }}
                      {...props}
                    />
                  )}
                  sx={{ width: "100%" }}
                >
                  {timeInterval.map((time) => (
                    <MenuItem
                      value={time}
                      key={time}
                      disabled={handleTime(time)}
                    >
                      {time.slice(0, -3)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box
                sx={{
                  marginLeft: "20px",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    display: "flex",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontSize: "18px",
                    lineHeight: "120%",
                    width: "100%",
                    height: "38.7px",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  End Time
                </Typography>
                <Select
                  value={endingTime}
                  onChange={(e) => {
                    setEndingTime(e.target.value);
                  }}
                  id="time"
                  className={styles["select-end-time"]}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "225px",
                        width: "175px",
                      },
                    },
                  }}
                  IconComponent={(props) => (
                    <ExpandMoreIcon
                      style={{
                        color: "var(--visionable-dark-blue",
                        cursor: "pointer",
                      }}
                      {...props}
                    />
                  )}
                  sx={{ width: "100%" }}
                >
                  {timeInterval.map((time) => (
                    <MenuItem
                      value={time}
                      key={time}
                      disabled={handleTime(time)}
                    >
                      {time.slice(0, -3)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MeetingTime;
