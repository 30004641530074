import React, { useState, useEffect } from "react";

//STORE
import { meetingsStore, utilityStore } from "@state/store";

// MUI COMPONENTS
import { Box, Button, Stack, Typography, Paper, Backdrop } from "@mui/material";

//TYPES
import { VTMeetings, VTInviteObject } from "types";

//RRULE
import { RRule, RRuleSet, rrulestr } from "rrule";

//REQUESTS
import { updateMeeting, deleteMeeting } from "@requests/Meetings";

//COMPONENTS PROP
export type VTDialogDelete = {
  meeting: VTMeetings;
  setSnackBarIsOpenForDeleteMeeting: (value: boolean) => void;
  setMeetingOverViewModalIsOpen: (value: boolean) => void;
};

const DialogDelete = ({
  meeting,
  setMeetingOverViewModalIsOpen,
  setSnackBarIsOpenForDeleteMeeting,
}: VTDialogDelete) => {
  const { globalDate } = meetingsStore();
  const { deleteModalOpen, setDeleteModalOpen, deleteType } = utilityStore();

  const handleCloseDialog = () => {
    setDeleteModalOpen(false);
  };

  // MODIFY RRULE TO ADD EXDATE
  const updateRrule = (date: Date) => {
    const dateToRemove = date;
    const rule = rrulestr(meeting.rrule, { forceset: true });
    // @ts-ignore
    rule.exdate(dateToRemove);
    return rule.toString();
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////
  ///////// FUNCTION RESPONSIBLE FOR DELEETING MEETINGS SINGLE MEETINGS, OCCURENCES AND SERIES
  /////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  const handleDelete = () => {
    if (deleteType === "occurance") {
      //   const { date } = router.query;
      const rule = updateRrule(globalDate);

      const inviteObject = meeting?.invites?.map((invite: any) => {
        return {
          invitee_id: invite.invitee_id,
          moderator: invite.moderator,
          invitor: invite.invitor,
          editor: invite.editor,
        };
      });

      const invitesToUpdate: VTInviteObject = {
        invitees: inviteObject,
        message: meeting.description,
      };

      updateMeeting(meeting.meeting_id, invitesToUpdate, { rrule: rule })
        .then((data) => {
          setDeleteModalOpen(false);
          setSnackBarIsOpenForDeleteMeeting(true);
          setMeetingOverViewModalIsOpen(false);
        })
        .catch((error) => {
          setMeetingOverViewModalIsOpen(false);
        });
    } else {
      deleteMeeting(meeting.meeting_id)
        .then((data) => {
          setDeleteModalOpen(false);
          setSnackBarIsOpenForDeleteMeeting(true);
          setMeetingOverViewModalIsOpen(false);
        })
        .catch((error) => {
          setMeetingOverViewModalIsOpen(false);
        });
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          overflow: "auto",
          color: "#fff",
          opacity: "100%",
          zIndex: 10000,
        }}
        open={deleteModalOpen}
      >
        <Paper
          elevation={1}
          sx={{
            borderRadius: "10px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Box pl={6} pr={6} pt={2} pb={2}>
            {deleteType === "individual" ? (
              <>
                <Typography p={1} variant="h3" align="center">
                  Delete
                </Typography>
                <Typography p={1} variant="body1" align="center">
                  Are you sure you want to delete this meeting?
                </Typography>
              </>
            ) : (
              <>
                {deleteType === "occurance" ? (
                  <>
                    <Typography p={1} variant="h3" align="center">
                      Delete
                    </Typography>
                    <Typography p={1} variant="body1" align="center">
                      Are you sure you want to delete this occurance?
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography p={1} variant="h3" align="center">
                      Delete
                    </Typography>
                    <Typography p={1} variant="body1" align="center">
                      Are you sure you want to delete this series?
                    </Typography>
                  </>
                )}
              </>
            )}
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box p={1}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Backdrop>
    </>
  );
};

export default DialogDelete;
