///////////////////////////////////////////////////////////////
///
///  DAY INDICATOR COMPONENT FOR TRACKING CURRENT AND SELECTED DATES
///
///////////////////////////////////////////////////////////////

//MUI COMPONENTS
import { Box, Stack, Typography } from "@mui/material";

//TIME LIBRARY
import { format, isToday, isSameDay } from "date-fns";

//STYLES
import styles from "./index.module.css";

export type Status = {
  day: Date;
  selected: Date;
};

const DayIndicator = ({ day, selected }: Status) => {
  return (
    <Stack spacing={2}>
      <Box className={styles.dayIndicatorTableHeadDay}>
        {format(day, "iiiii")}
      </Box>

      {isSameDay(new Date(day), new Date(selected)) ? (
        <Box
          justifyContent={"center"}
          sx={
            isToday(day)
              ? {
                  background: "var(--visionable-mid-blue)",
                  borderRadius: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "10px",
                    xl: "50px",
                  },
                  alignSelf: "center",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "80%",
                    xl: "70%",
                    xxl: "40%",
                  },
                }
              : {
                  background: "var(--visionable-mid-blue)",
                  borderRadius: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "10px",
                    xl: "50px",
                  },
                  alignSelf: "center",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "80%",
                    xl: "70%",
                    xxl: "40%",
                  },
                }
          }
        >
          {isToday(day) ? (
            <Typography
              noWrap={true}
              p={0.2}
              justifyContent={"center"}
              alignSelf={"center"}
              className={styles.dayIndicatorDateSelected}
            >
              {format(day, "dd LLL")}
            </Typography>
          ) : (
            <Typography
              noWrap={true}
              p={0.2}
              justifyContent={"center"}
              alignSelf={"center"}
              className={styles.dayIndicatorDateSelected}
            >
              {format(day, "dd LLL")}
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          justifyContent={"center"}
          sx={
            isToday(day)
              ? {
                  background: "var(--visionable-accent-blue)",
                  borderRadius: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "10px",
                    xl: "50px",
                  },
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "80%",
                    xl: "70%",
                    xxl: "40%",
                  },
                  alignSelf: "center",
                }
              : {}
          }
        >
          {isToday(day) ? (
            <Typography
              noWrap={true}
              p={0.2}
              justifyContent={"center"}
              alignItems={"center"}
              className={styles.dayIndicatorTableHeadDate}
            >
              {format(day, "dd LLL")}
            </Typography>
          ) : (
            <Typography
              noWrap={true}
              p={0.2}
              justifyContent={"center"}
              alignItems={"center"}
              className={styles.dayIndicatorTableHeadDate}
            >
              {format(day, "dd LLL")}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
};
export default DayIndicator;
