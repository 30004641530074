import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import joinMeeting from "@utilities/JoinMeeting";
import { meetingStore } from "@state/store";
import { getCurrentUserInfo } from "@cognito";

import Button from "@mui/material/Button";
import styles from "./index.module.css";
import { VTMeetings } from "types";
export type VTJoinMeetingProps = {
  meeting: VTMeetings;
};

const JoinMeetingButton = ({ meeting }: VTJoinMeetingProps) => {
  const { preCallModalIsOpen, setPreCallModalIsOpen, setSelectedMeeting } =
    meetingStore();

  const start = meeting?.start;
  const duration = meeting?.duration;

  const [disabled, setDisabled] = useState<boolean>(true);

  const now = new Date().getTime() / 1000;

  useEffect(() => {
    now > start - 1800 && now < start + duration
      ? setDisabled(false)
      : setDisabled(true);
  }, [now, start, duration]);

  const router = useRouter();

  const handleToggleForModal = () => {
    setPreCallModalIsOpen(!preCallModalIsOpen);
    setSelectedMeeting(meeting);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className={styles.meetingJoinButton}
      onClick={handleToggleForModal}
      disabled={disabled}
      sx={{
        width: { xs: "100%", sm: "180px" },
        maxWidth: { sm: "180px" },
        minWidth: { sm: "180px" },
      }}
    >
      Join
    </Button>
  );
};

export default JoinMeetingButton;
