import { useEffect, useState } from "react";
//IMPORT CSS/STYLES
import {
  Box,
  Typography,
  Input,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import styles from "./index.module.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import getDaysInMonth from "date-fns/getDaysInMonth";

//IMPORTED COMPONENTS
import ForStartDate from "../../CalendarNavigator/ForStartDate";
import ForUntilDate from "../../CalendarNavigator/ForUntilDate";

//IMPORT DATE COMPONENTS
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//TIME LIBRARY
import { format } from "date-fns";
import enLocale from "date-fns/locale/en-GB";

//IMPORT STORE
import { meetingsStore } from "@state/store";

export type VTRRULEMonthlyProps = {
  handleClose: () => void;
  setMeetingStartDate: (meetingStartDate: string) => void;
  repeatMeeting: string;
  isDayActive: {
    days: { id: number; day: string; value: any; isActive: boolean }[];
  };
  setIsDayActive: (isActive: any) => void;
  onChange: (e: SelectChangeEvent) => void;
  meetingStartDate: string;
  pickDateButton: boolean;
  setPickDateButton: (pickDateButton: boolean) => void;
  interval: number;
  setInterval: (interval: number) => void;
  count: number;
  setCount: (count: number) => void;
  reoccuringMeetingEnd: Date;
  setReoccuringMeetingEnd: (reoccuringMeetingEnd: Date) => void;
  byMonthDay: number;
  setByMonthDay: (byMonthDay: number) => void;
  dayString: string;
  monthString: string;
  year: string;
  handleSaveClose: () => void;
};

const RRULEMonthly = ({
  handleClose,
  setMeetingStartDate,
  repeatMeeting,
  isDayActive,
  setIsDayActive,
  onChange,
  meetingStartDate,
  pickDateButton,
  setPickDateButton,
  interval,
  setInterval,
  count,
  setCount,
  reoccuringMeetingEnd,
  setReoccuringMeetingEnd,
  byMonthDay,
  setByMonthDay,
  dayString,
  monthString,
  year,
  handleSaveClose,
}: VTRRULEMonthlyProps) => {
  const {
    dateForCalendar,
    setDateForCalendar,
    dateForUntilCalendar,
    setDateForUntilCalendar,
  } = meetingsStore();

  const [monthlyIsLoaded, setMonthlyIsLoaded] = useState<boolean>(false);

  const handleSelection = (date: Date | null) => {
    if (date) {
      setMeetingStartDate(format(date, `yyyy-MM-dd`));
      setDateForCalendar(date);
    }
  };

  const handleEndingSelectionDate = (date: Date | null) => {
    if (date) {
      setReoccuringMeetingEnd(date);
      setDateForUntilCalendar(date);
    }
  };

  //Change the month day depending on the global date
  useEffect(() => {
    const formatedDate = format(dateForCalendar, `yyyy-MM-dd`);
    const day = formatedDate.slice(8, 10).split("");

    if (day[0] === "0") {
      setByMonthDay(parseInt(day[1]));
    } else {
      setByMonthDay(parseInt(day.join("")));
    }
  }, [dateForCalendar]);

  const days = [
    { id: 1, value: 1, name: "Day 1" },
    { id: 2, value: 2, name: "Day 2" },
    { id: 3, value: 3, name: "Day 3" },
    { id: 4, value: 4, name: "Day 4" },
    { id: 5, value: 5, name: "Day 5" },
    { id: 6, value: 6, name: "Day 6" },
    { id: 7, value: 7, name: "Day 7" },
    { id: 8, value: 8, name: "Day 8" },
    { id: 9, value: 9, name: "Day 9" },
    { id: 10, value: 10, name: "Day 10" },
    { id: 11, value: 11, name: "Day 11" },
    { id: 12, value: 12, name: "Day 12" },
    { id: 13, value: 13, name: "Day 13" },
    { id: 14, value: 14, name: "Day 14" },
    { id: 15, value: 15, name: "Day 15" },
    { id: 16, value: 16, name: "Day 16" },
    { id: 17, value: 17, name: "Day 17" },
    { id: 18, value: 18, name: "Day 18" },
    { id: 19, value: 19, name: "Day 19" },
    { id: 20, value: 20, name: "Day 20" },
    { id: 21, value: 21, name: "Day 21" },
    { id: 22, value: 22, name: "Day 22" },
    { id: 23, value: 23, name: "Day 23" },
    { id: 24, value: 24, name: "Day 24" },
    { id: 25, value: 25, name: "Day 25" },
    { id: 26, value: 26, name: "Day 26" },
    { id: 27, value: 27, name: "Day 27" },
    { id: 28, value: 28, name: "Day 28" },
    { id: 29, value: 29, name: "Day 29" },
    { id: 30, value: 30, name: "Day 30" },
    { id: 31, value: 31, name: "Day 31" },
  ];

  const monthLength = getDaysInMonth(new Date(meetingStartDate));

  useEffect(() => {
    if (byMonthDay && dateForCalendar) {
      setMonthlyIsLoaded(true);
    }
  }, [byMonthDay, dateForCalendar]);

  return (
    <>
      {monthlyIsLoaded ? (
        <Box>
          <Box sx={{ paddingTop: "20px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                paddingLeft: "5px",
                fontSize: "16px",
                lineHeight: "21.82px",
              }}
            >
              Starts
            </Typography>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  showToolbar={true}
                  ToolbarComponent={ForStartDate}
                  disablePast
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={dateForCalendar}
                  onChange={(date) => {
                    handleSelection(date);
                  }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          {...params}
                          error={false}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </>
                    );
                  }}
                />
              </LocalizationProvider>
            </StyledEngineProvider>
          </Box>

          <Box sx={{ paddingTop: "20px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                paddingLeft: "5px",
                lineHeight: "21.82px",
              }}
            >
              Repeat
            </Typography>
            <Select
              value={repeatMeeting}
              onChange={(e) => onChange(e)}
              sx={{
                width: "100%",
                height: "50px",
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </Box>

          <Box sx={{ paddingTop: "20px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                paddingLeft: "5px",
                lineHeight: "21.82px",
              }}
            >
              Every
            </Typography>
            <Select
              sx={{
                width: "100%",
                height: "50px",
              }}
              value={interval}
              defaultValue={interval}
              onChange={(e) => setInterval(Number(e.target.value))}
            >
              <MenuItem value="1">1 Month</MenuItem>
              <MenuItem value="2">2 Months</MenuItem>
              <MenuItem value="3">3 Months</MenuItem>
              <MenuItem value="4">4 Months</MenuItem>
              <MenuItem value="6">6 Months</MenuItem>
            </Select>
          </Box>

          <Box sx={{ paddingTop: "20px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "21.82px",
                paddingLeft: "5px",
              }}
            >
              On
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Select
                sx={{
                  width: "100%",
                  height: "50px",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        padding: 1,
                      },
                      height: "225px",
                    },
                  },
                }}
                value={byMonthDay}
                defaultValue={byMonthDay}
                onChange={(e) => setByMonthDay(Number(e.target.value))}
              >
                {days.slice(0, monthLength).map((day) => (
                  <MenuItem key={day.id} value={day.value}>
                    {day.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              paddingTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "21.82px",
                paddingLeft: "5px",
              }}
            >
              Ends
            </Typography>

            <Box>
              <RadioGroup row defaultValue="Never">
                <FormControlLabel
                  value="Never"
                  control={
                    <Radio
                      sx={{
                        color: "var(--visionable-mid-blue)",
                        "&.Mui-checked": {
                          color: "var(--visionable-mid-blue)",
                        },
                      }}
                      onClick={() => setPickDateButton(false)}
                    />
                  }
                  label="Never"
                  sx={{ marginLeft: "0px" }}
                />
                <FormControlLabel
                  value="PickDate"
                  control={
                    <Radio
                      sx={{
                        color: "var(--visionable-mid-blue)",
                        "&.Mui-checked": {
                          color: "var(--visionable-mid-blue)",
                        },
                      }}
                      onClick={() => setPickDateButton(true)}
                    />
                  }
                  label="Pick Date"
                  sx={{ marginLeft: "0px" }}
                />
              </RadioGroup>
            </Box>
          </Box>

          {pickDateButton ? (
            <StyledEngineProvider injectFirst>
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  showToolbar={true}
                  ToolbarComponent={ForUntilDate}
                  disablePast
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={dateForUntilCalendar}
                  onChange={(date) => {
                    handleEndingSelectionDate(date);
                  }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          {...params}
                          error={false}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </>
                    );
                  }}
                />
              </LocalizationProvider>
            </StyledEngineProvider>
          ) : null}

          <Box
            sx={{
              width: "100%",
              backgroundColor: "#FAFAFA",
              height: "100%",
              paddingTop: "20px",
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
              paddingLeft: "20px",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignText: "center",
              }}
            >
              <InfoOutlinedIcon />
              <Typography sx={{ paddingLeft: "10px" }}>
                Occurs every month on the {dayString}, starting on the{" "}
                {dayString} {monthString} {year}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Box>
              <Button
                variant="contained"
                sx={{ width: "121px", height: "50px" }}
                onClick={() => handleSaveClose()}
              >
                Save
              </Button>
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                sx={{ width: "121px", height: "50px" }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default RRULEMonthly;
