///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING WEEKLY MEETING CARD
///
///////////////////////////////////////////////////////////////

import { useState, useEffect, useContext } from "react";

//TIME LIBRARY
import { format, getDate, compareAsc } from "date-fns";

// MUI COMPONENTS
import { Box, Stack, Button } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Fade from "@mui/material/Fade";

//TYPES
import { getCurrentUserInfo } from "../../../../@cognito";
import { VTInvite, VTMeetings, VTSingleMeeting } from "../../../../types";

// GLOBAL STATE
import { meetingsStore, userStore } from "@state/store";

//STYLES
import theme from "@components/VisionableTheme";

// CUSTOM COMPONENTS
import StatusIndicator from "../StatusIndicator";

///////////////////////////////////////////////////////////////
///
///  WEEKLY CARD COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTMeetingCardProps = {
  date: number;
  selection: any;
  handleSelection: Function;
  setDateOfSelection: Function;
  meeting: VTMeetings;
  handlePopup: Function;
  handleExpansionClick: Function;
};

export const WeeklyCard = ({
  date,
  meeting,
  selection,
  handleSelection,
  setDateOfSelection,
  handleExpansionClick,
  handlePopup,
}: VTMeetingCardProps) => {
  // GLOBAL STATE CONTEXT
  const { user } = userStore();
  // GLOBAL STATE
  const { globalDate, setGlobalDate } = meetingsStore();
  //  STATE
  const [liveStatus, setLiveStatus] = useState(false);
  const [inPastStatus, setInPastStatus] = useState(false);

  // DATE TIME OPERATIONS
  const formattedDate = new Date(meeting.start * 1000);
  const formattedEndDate = new Date(
    meeting.start * 1000 + meeting.duration * 1000
  );
  const meetingStartTime = format(new Date(formattedDate), "HH:mm");
  const meetingEndTime = format(new Date(formattedEndDate), "HH:mm");

  // INVITES
  const userStatusArray: any = meeting.invites.filter((invite: VTInvite) => {
    if (invite?.invitee_id === user?.user_id) {
      return invite.status;
    }
  });
  const userStatus: string = userStatusArray.map((invite: VTInvite) => {
    return invite.status;
  });

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // DETERMINE IF MEETING IS CURENTLY LIVE
  const isMeetingLive = () => {
    const currentTime = new Date().getTime();
    const meetingStartTime = new Date(formattedDate).getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();
    const meetingDuration = meeting.duration;

    if (
      currentTime < meetingStartTime - 900 ||
      currentTime > meetingEndTime + meetingDuration
    ) {
      setLiveStatus(false);
    } else {
      setLiveStatus(true);
    }
  };
  // DETERMINE IF MEETING IS IN THE PAST
  const isMeetingPast = () => {
    const currentTime = new Date().getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();

    if (currentTime > meetingEndTime) {
      setInPastStatus(true);
    } else {
      setInPastStatus(false);
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // DETERMINE IF THERE IS A LIVE MEETING RUNNING
  useEffect(() => {
    isMeetingLive();
    isMeetingPast();
  }, [meeting]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <>
      {inPastStatus ? (
        <Fade in={true}>
          <Box
            key={meeting.meeting_id}
            id={meeting.meeting_id as any}
            style={
              selection === meeting.meeting_id
                ? { boxShadow: "0px 0px 0px 2px #000000" }
                : {}
            }
            onClick={(event: any) => {
              handleSelection(event);
              setDateOfSelection(new Date(meeting.start * 1000));
              setGlobalDate(new Date(meeting.start * 1000));
              // handlePopup("right", event);
              handleExpansionClick({
                uuid: meeting.meeting_id,
                date: date,
              });
            }}
            sx={{ borderRadius: "10px", cursor: "pointer" }}
            onDoubleClick={() =>
              handleExpansionClick({
                uuid: meeting.meeting_id,
                date: date,
              })
            }
          >
            {/*live meeting box wrapper */}
            <Box
              style={
                liveStatus
                  ? {
                      background: "#00AEEF1A",
                      boxShadow: "0px 0px 3px #1271EE",
                    }
                  : {}
              }
              sx={{
                background: "#ffffff",
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",

                overflowX: "hidden",
              }}
            >
              <Stack
                p={0.8}
                direction="row"
                sx={{
                  background: theme.palette.grey[500],
                  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                }}
              >
                {/* Status Indicator Column */}
                <Stack direction="column" sx={{ visibility: "hidden" }}>
                  <StatusIndicator status={userStatus} />
                </Stack>
                {/* Meeting Column */}
                <Stack
                  spacing={{ xs: 0, xl: 1 }}
                  direction="column"
                  sx={{
                    text: "left",
                    whiteSpace: "nowrap",
                    minWidth: "10px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {/* Meeting Title Section */}

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "1em",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      &nbsp;{meeting.name}
                    </Box>
                  </Stack>

                  {/* Display Time Section */}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <AccessTimeIcon
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "none",
                          xl: "block",
                        },
                        color: theme.palette.background.paper,
                      }}
                      fontSize="small"
                    ></AccessTimeIcon>
                    &nbsp;
                    <Stack
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        color: "#FFFFFF",
                      }}
                      direction={{
                        xs: "column",
                        md: "column",
                        lg: "column",
                        xl: "row",
                      }}
                    >
                      <Box>{meetingStartTime}</Box>
                      <Box display={{ xs: "none", xl: "inline" }}> - </Box>
                      <Box>{meetingEndTime}</Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Fade>
      ) : (
        <Fade in={true}>
          <Box
            key={meeting.meeting_id}
            id={meeting.meeting_id as any}
            style={
              selection === meeting.meeting_id
                ? { boxShadow: "0px 0px 0px 2px #000000" }
                : {}
            }
            onClick={(event: any) => {
              handleSelection(event);
              setDateOfSelection(new Date(meeting.start * 1000));
              setGlobalDate(new Date(meeting.start * 1000));
              //handlePopup("right", event);
              handleExpansionClick({ uuid: meeting.meeting_id, date: date });
            }}
            sx={{ borderRadius: "10px", cursor: "pointer" }}
            onDoubleClick={() =>
              handleExpansionClick({ uuid: meeting.meeting_id, date: date })
            }
          >
            {/*live meeting box wrapper */}
            <Box
              style={
                liveStatus
                  ? {
                      background: "#00AEEF1A",
                      boxShadow: "0px 0px 3px #1271EE",
                    }
                  : {}
              }
              sx={{
                background: "#ffffff",
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <Stack p={0.8} direction="row">
                {/* Status Indicator Column */}
                <Stack direction="column">
                  <StatusIndicator status={userStatus} />
                </Stack>
                {/* Meeting Column */}
                <Stack
                  spacing={{ xs: 0, xl: 1 }}
                  direction="column"
                  sx={{
                    text: "left",
                    whiteSpace: "nowrap",
                    minWidth: "10px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {/* Meeting Title Section */}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "1em",
                        fontWeight: "bold",
                      }}
                    >
                      &nbsp;{meeting.name}
                    </Box>
                  </Stack>

                  {/* Display Time Section */}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <AccessTimeIcon
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "none",
                          xl: "block",
                        },
                      }}
                      fontSize="small"
                    ></AccessTimeIcon>
                    &nbsp;
                    <Stack
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      direction={{
                        xs: "column",
                        md: "column",
                        lg: "column",
                        xl: "row",
                      }}
                    >
                      <Box>{meetingStartTime}</Box>
                      <Box display={{ xs: "none", xl: "inline" }}> - </Box>
                      <Box>{meetingEndTime}</Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Fade>
      )}
      {/* // Selection box wrapper */}
    </>
  );
};
