import DateRangeIcon from "@mui/icons-material/DateRange";
import styles from "./index.module.css";

const NoMeetingComponent = () => {
  return (
    <>
      <div className={styles.noMeetingContainer}>
        <div className={styles.noMeetingCircle}>
          <DateRangeIcon
            style={{ height: "46.64px", width: "46.64px", color: "#909FA4" }}
          />
          <div>No scheduled meetings</div>
          <div>When you have meetings, they</div>
          <div>will appear here</div>
        </div>
      </div>
    </>
  );
};

export default NoMeetingComponent;
