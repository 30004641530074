///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING WEEKLY MEETINGS
///
///////////////////////////////////////////////////////////////

import * as React from "react";
import { useEffect, useState } from "react";

//MUI COMPONENTS
import {
  Box,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import { PopperPlacementType } from "@mui/material/Popper";
import { sizing } from "@mui/system";

// CUSTOM COMPONENTS
import MeetingModal from "@components/MeetingComponents/MeetingsModal";
import { WeeklyCard } from "./../WeeklyCard";
import NoMeetingComponent from "../../NoMeeting";
import DayIndicator from "./../DayIndicator";
import DaySelector from "@components/MeetingComponents/MeetingStateViewComponents/DaySelector";

//TIME LIBRARY
import {
  format,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  getUnixTime,
} from "date-fns";

//GLOBAL STATE
import { meetingsStore } from "@state/store";

//TYPES
import { VTSingleMeeting, VTMeetings } from "types";

// RRULE LIBRARY
import { RRule, rrulestr } from "rrule";

///////////////////////////////////////////////////////////////
///
///  WEEKLYVIEW COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTWeeklyViewProps = {
  meetings: any;
  handleExpansionClick: ({ uuid, date }: any) => void;
};

const WeeklyView = ({ meetings, handleExpansionClick }: VTWeeklyViewProps) => {
  // GLOBAL STATE
  const { globalDate, setGlobalDate } = meetingsStore();

  //STATE
  const [weeklyMeetings, setWeeklyMeetings] = useState<any>([]);
  const [weeklyMeetingsExist, setWeeklyMeetingsExist] =
    useState<boolean>(false);

  //SELECTION STATE
  const [selection, setSelection] = useState<any>("");
  const [dateOfSelection, setDateOfSelection] = useState<any>("");

  //POPPER STATE
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  // DATE TIME OPERATIONS
  const firstDayOfCurrentWeek = startOfWeek(new Date(globalDate), {
    weekStartsOn: 1,
  });
  const lastDayCurrentWeek = lastDayOfWeek(new Date(globalDate), {
    weekStartsOn: 1,
  });
  const daysArray = eachDayOfInterval({
    start: firstDayOfCurrentWeek,
    end: lastDayCurrentWeek,
  });

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const handleSelection = (event: any) => {
    setSelection(event.currentTarget.id);
  };

  //ACTIVATE MEETING DETAILS POPUP
  const handlePopup = (
    newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setPlacement(newPlacement);
  };

  // CLOSE MEETING DETAILS POPUP
  const handlePopupClose = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    // Selector triggers close event known issue Mui temporary workaround
    if (event.target.localName === "body") {
      event.preventDefault();
    } else {
      setOpen(false);
    }
  };

  // CHECK IF THERE ARE MEETINGS IN THE CURRENT WEEK
  const checkMeeingsExist = () => {
    for (const day of weeklyMeetings) {
      for (const meeting of day) {
        if (meeting !== undefined) {
          setWeeklyMeetingsExist(true);
          return;
        }
      }
    }
    setWeeklyMeetingsExist(false);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION (MEETINGS DATASTRUCTURE)
  ///  FORMATS MEETINGS TO DISPLAY FOR ALL DAYS IN THE SELECTED WEEK
  ///
  ///////////////////////////////////////////////////////////////

  // GET MEETING INSANCE ON A GIVEN DATE IF MMETING FALLS ON THAT DATE GIVEN RRULE
  const getTodaysOccurance = (date: Date, meeting: any) => {
    try {
      const rule = rrulestr(meeting.rrule, {
        forceset: true,
      });
      const start = startOfDay(date);
      const end = endOfDay(date);
      const copy = JSON.parse(JSON.stringify(meeting));
      // @ts-ignore
      const exDates = rule.exdates();
      const occurrences = rule.between(start, end, true);

      if (occurrences.length > 0) {
        copy.start = getUnixTime(occurrences[0]);
        return copy;
      } else {
        return false;
      }
    } catch (e) {
      //console.log(e);
    }
  };

  // GET ALL RECCURNING  MEETINGS ON A GIVEN DATE RANGE
  const getTodaysReccuringMeetings = (meetings: VTSingleMeeting[]) => {
    const reccuringMeetings: VTSingleMeeting[] = [];

    daysArray.forEach((date: Date) => {
      meetings.forEach((meeting: any) => {
        if (meeting.rrule !== "" || meeting.rrule !== null) {
          const todaysInstance = getTodaysOccurance(date, meeting);
          if (todaysInstance) {
            reccuringMeetings.push(todaysInstance);
          }
        }
      });
    });
    return reccuringMeetings;
  };
  // REMOVE MEETINGS THAT SATISFY EXDATE
  const removeExdateMeetings = (meetings: any) => {
    const filteredMeetings: any = [];
    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "") {
        const rule = rrulestr(meeting.rrule, {
          forceset: true,
        });
        // @ts-ignore
        const exDates = rule.exdates();
        let match = false;
        exDates.forEach((date: any) => {
          if (isSameDay(date, new Date(meeting.start * 1000))) {
            match = true;
          }
        });
        if (!match) {
          filteredMeetings.push(meeting);
        }
      } else {
        filteredMeetings.push(meeting);
      }
    });
    return filteredMeetings;
  };

  // GETTING THE MEETINGS FOR THE CURRENT WEEK AND POPULATE THE TABLE
  const formatMeetings = (meetings: VTSingleMeeting[]) => {
    let maxMeetings = 0;
    const meetingsObject: { [key: string]: any } = {};
    daysArray.forEach((day) => {
      const d = format(day, "yyyy-MM-dd")?.toString();
      meetingsObject[d] = [];
    });
    meetings.forEach((meeting: any) => {
      const d = format(
        new Date(meeting.start * 1000),
        "yyyy-MM-dd"
      )?.toString();
      if (d in meetingsObject) {
        maxMeetings = Math.max(maxMeetings, meetingsObject[d].length);
        meetingsObject[d].push(meeting);
        meetingsObject[d].sort((a: any, b: any) => {
          return a.start - b.start;
        });
      }
    });
    const matrix: any = [];
    daysArray.map((day) => {
      const d = format(day, "yyyy-MM-dd");
      matrix.push(meetingsObject[d]);
    });
    // transpose matrix for rendering purposes in a single pass
    const transposed = matrix
      .reduce((accumulator: any, value: any) => accumulator.concat(value), [])
      .map((row: any, i: number) => matrix.map((col: any) => col[i]));
    setWeeklyMeetings(transposed);
    //console.log("DATA", "TRANSOPSED", transposed);
    return transposed;
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // PREPROCESS MEETINGS TO DISPLAY ON GIVEN DATE
  useEffect(() => {
    const allMeetings = JSON.parse(JSON.stringify(meetings));

    // non reccuring meetings on between selected dates
    const weeksRegularMeetings = allMeetings
      .filter(
        (meeting: VTMeetings) =>
          meeting.start >= getUnixTime(startOfDay(firstDayOfCurrentWeek)) &&
          meeting.start <= getUnixTime(endOfDay(lastDayCurrentWeek))
      )
      .filter(
        (meeting: VTMeetings) => meeting.rrule === "" || meeting.rrule === null
      );

    // reccuring meetings on between selected dates
    let weeksReccuringMeetings = getTodaysReccuringMeetings(allMeetings);

    // remove meetings that satisfy exdate constraints
    weeksReccuringMeetings = removeExdateMeetings(weeksReccuringMeetings);

    // format meetings into matrix format to render ui
    formatMeetings([...weeksRegularMeetings, ...weeksReccuringMeetings]);
  }, [globalDate, meetings]);

  // CHECK IF THERE ARE MEETINGS IN THE CURRENT WEEK
  useEffect(() => {
    checkMeeingsExist();
  }, [weeklyMeetings, globalDate]);

  useEffect(() => {
    console.log("size", sizing);
  }, [sizing]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Box>
      {/* Meeting details popup modal */}
      <MeetingModal
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        handlePopupClose={handlePopupClose}
      />

      {/* NAVIGAION BUTTONS */}

      <Stack display={{ xs: "none", lg: "inline" }}>
        <DaySelector />
      </Stack>

      {/* WEEKLY SHEDULE VIEW */}

      <Box p={2}>
        {weeklyMeetingsExist ? (
          <TableContainer
            sx={{
              overflow: "hidden",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ padding: "0.5%" }}>
                  {daysArray.map((day: any, i: number) => (
                    <TableCell
                      key={day.getTime() + i}
                      align="center"
                      sx={{
                        padding: "0.5%",
                        paddingTop: "1%",
                        paddingBottom: "1%",
                        border: "none",
                        minWidth: "50px",
                        maxWidth: "calc(5vw)",
                        width: "calc(100vw/7)",
                        background: "white",
                      }}
                    >
                      <DayIndicator day={day} selected={dateOfSelection} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <Box p={0.5}></Box>
              <TableBody>
                {weeklyMeetings.map((row: any, i: number) => (
                  <TableRow key={i}>
                    {row.map((meeting: any, j: number) =>
                      meeting ? (
                        <TableCell
                          key={i + j}
                          align="center"
                          sx={{
                            padding: "0.5%",
                            border: "none",
                            minWidth: "50px",
                            maxWidth: "calc(5vw)",
                            width: "calc(100vw/7)",
                          }}
                        >
                          <WeeklyCard
                            date={meeting.start}
                            selection={selection}
                            handleSelection={handleSelection}
                            setDateOfSelection={setDateOfSelection}
                            meeting={meeting}
                            handleExpansionClick={handleExpansionClick}
                            handlePopup={handlePopup}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={i + j}
                          sx={{ borderBottom: "none", padding: "0" }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "46px",
                              display: "hidden",
                              opacity: "0",
                              border: "1px solid",
                            }}
                          >
                            No Meeting
                          </Box>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box p={2}>
            <NoMeetingComponent />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WeeklyView;
