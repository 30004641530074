///////////////////////////////////////////////////////////////
///
///  MEETINGS PAGE COMPONENT -Responsible for displaying meetings page
///
///////////////////////////////////////////////////////////////s
import React from "react";
import { useEffect, useContext, useState } from "react";
import type { NextPage } from "next";

// MUI COMPONENTS
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import AddIcon from "@mui/icons-material/Add";
import TodayIcon from "@mui/icons-material/Today";
import useMediaQuery from "@mui/material/useMediaQuery";

// ROUTER
import { useRouter } from "next/router";

// CUSTOM COMPONENTS
import Dashboard from "@components/Layout/Dashboard";
import DailyView from "../../@components/MeetingComponents/MeetingStateViewComponents/DailyView";
import MonthlyView from "../../@components/MeetingComponents/MeetingStateViewComponents/MonthlyView";
import WeeklyView from "../../@components/MeetingComponents/MeetingStateViewComponents/WeeklyView";
import Calendar from "../../@components/MeetingComponents/Calendar";
import PendingInvites from "../../@components/MeetingComponents/PendingInvites";
import CreateMeeting from "@components/CreateAndEditMeetings/CreateMeeting";
import MeetingOverview from "@components/MeetingOverview";
import MobileCalendar from "@components/MeetingComponents/MobileCalendar";
import DaySelector from "@components/MeetingComponents/MeetingStateViewComponents/DaySelector";
import SnackbarComponent from "@components/Snackbar";
import AuthorizedPreCall from "@components/PreCall/AuthorizedPreCall";

//REQUESTS
import { getMeetings, getUserInformation } from "../../@requests/Meetings";

//THEME
import theme from "@components/VisionableTheme";
import styles from "./index.module.css";

// GLOBAL STATE
import { meetingsStore, meetingStore, userStore } from "@state/store";
import EditMeeting from "@components/CreateAndEditMeetings/EditMeeting";

// CHAT
import { ChatContext } from "@chat";

// UTILS
import joinMeeting from "@utilities/JoinMeeting";

//TYPES
import { VTMeetings, VTSingleMeeting } from "../../types";
import { getCurrentUserInfo } from "@cognito";

///////////////////////////////////////////////////////////////
///
///  MEETINGS PAGE
///
///////////////////////////////////////////////////////////////s
const Meetings: NextPage = () => {
  const router = useRouter();
  const chat = useContext(ChatContext);

  const store = meetingStore();

  const [meetings, setMeetings] = useState<VTMeetings[]>([]);
  const [search, setSearch] = useState("");
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);

  const [createMeetingModalIsOpen, setCreateMeetingModalIsOpen] =
    useState<boolean>(false);

  const [meetingOverViewModalIsOpen, setMeetingOverViewModalIsOpen] =
    useState<boolean>(false);

  const [editMeetingModalIsOpen, setEditMeetingModalIsOpen] =
    useState<boolean>(false);

  const [saveMeetingIsClicked, setSaveMeetingIsClicked] =
    useState<boolean>(false);

  const [snackBarIsOpenForCreateMeeting, setSnackBarIsOpenForCreateMeeting] =
    useState<boolean>(false);

  const [snackBarIsOpenForEditMeeting, setSnackBarIsOpenForEditMeeting] =
    useState<boolean>(false);

  const [snackBarIsOpenForDeleteMeeting, setSnackBarIsOpenForDeleteMeeting] =
    useState<boolean>(false);

  const [fetchingData, setFetchingData] = useState<boolean>(true);

  // GLOBAL STATE
  const {
    setMeetingsView,
    meetingsView,
    inviteStatusUpdate,
    globalDate,
    meetingsLoading,
    meetingsFilter,
    setMeetingsLoading,
    setGlobalDate,
  } = meetingsStore();
  const {
    preCallModalIsOpen,
    setPreCallModalIsOpen,
    activeVideoStream,
    setActiveVideoStream,
  } = meetingStore();
  const { user, setUser } = userStore();
  const { setMeetingDate, setMeetingUUID } = meetingsStore();

  //BREAKPOINTS
  const matches = useMediaQuery("(min-width:900px)");
  const mobile = useMediaQuery("(min-width: 600px)");

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////
  const getMedia = async () => {
    try {
      const constraints = {
        audio: false,
        video: true,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      setActiveVideoStream(stream);
      meetNow(stream);
      return { stream, error: null };
    } catch (error) {
      console.log("stream failed due to", error);

      return { stream: null, error };
    }
  };

  /** "Meet Now" button */
  const meetNow = async (stream: MediaStream) => {
    // Create meeting
    const meetingId = await chat.createInstantMeeting();
    if (!meetingId) {
      console.log("Couldn't create meeting");
      return;
    }

    // Get user info
    const userInfo = await getCurrentUserInfo();
    const jwt = userInfo.signInUserSession.idToken.jwtToken;
    const uuid = userInfo.username;
    // const email = userInfo.attributes.email;
    const email = user.user_id;
    const name = chat.currentUser?.full_name;

    // Go to meetings page
    // @ts-ignore
    // eslint-disable-next-line new-cap
    joinMeeting({
      uuid: meetingId,
      jwt,
      meetingStore: store,
      email,
      name: name ? name : "",
      stream,
    });
    router.push("/meeting/[uuid]", `/meeting/${meetingId}`);
  };

  const omitPagination = async (
    midnightEpochDayBefore: any,
    TodayMidnightEpoch: any,
    status: string
  ) => {
    const data = await getMeetings(
      midnightEpochDayBefore,
      TodayMidnightEpoch,
      1,
      {
        status: status,
      }
    );
    const allData = [];

    for (let index = 1; index <= data.last_page; index++) {
      const data = await getMeetings(
        midnightEpochDayBefore,
        TodayMidnightEpoch,
        index,
        {
          status: status,
        }
      );
      allData.push(...data.data);
    }
    return allData;
  };

  const getAllMeetings = async (
    midnightEpochDayBefore: number,
    TodayMidnightEpoch: number
  ) => {
    const meetings: any[] = [];

    const invited = await omitPagination(
      midnightEpochDayBefore,
      TodayMidnightEpoch,
      "invited"
    );

    const accepted = await omitPagination(
      midnightEpochDayBefore,
      TodayMidnightEpoch,
      "accepted"
    );

    const tentative = await omitPagination(
      midnightEpochDayBefore,
      TodayMidnightEpoch,
      "tentative"
    );

    return meetings.concat(invited, accepted, tentative);
  };

  const handleNewMeeting = () => {
    // router.push("/meetings/CreateMeeting");
    setSaveMeetingIsClicked(false);
    setCreateMeetingModalIsOpen(true);
  };

  const handleExpansionClick = ({ uuid, date }: any) => {
    setMeetingUUID(uuid);
    setMeetingDate(date);
    setMeetingOverViewModalIsOpen(true);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    setMeetingsLoading(true);
    //epoch time for the midnight of the previous day and current day
    const today = new Date();
    const yesterdayMidnight = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 365
    );
    const midnightEpochDayBefore = yesterdayMidnight.getTime() / 1000;

    const laterMidnight = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 365
    );
    const TodayMidnightEpoch = laterMidnight.getTime() / 1000;

    if (meetingsFilter == "declined") {
      omitPagination(midnightEpochDayBefore, TodayMidnightEpoch, "rejected")
        .then((meetings) => {
          const newMeetings = meetings.map((m: any) => ({
            ...m,
            owner_name: `${m.owner_first_name} ${m.owner_last_name}`,
          }));
          if (fetchingData) {
            setMeetings(newMeetings);
            setNeedsRefresh(false);
            setFetchingData(false);
          }
        })
        .catch((err) => {
          setFetchingData(true);
          setNeedsRefresh(false);
        });
    } else {
      getAllMeetings(midnightEpochDayBefore, TodayMidnightEpoch)
        .then((meetings) => {
          const newMeetings = meetings.map((m: any) => ({
            ...m,
            owner_name: `${m.owner_first_name} ${m.owner_last_name}`,
          }));
          if (fetchingData) {
            setMeetings(newMeetings);
            setNeedsRefresh(false);
            setFetchingData(false);
          }
        })
        .catch((err) => {
          setNeedsRefresh(false);
          setFetchingData(true);
        });
    }

    return () => setFetchingData(true);
  }, [
    inviteStatusUpdate,
    user,
    saveMeetingIsClicked,
    meetingOverViewModalIsOpen,
    editMeetingModalIsOpen,
  ]);

  useEffect(() => {
    getUserInformation()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {});
  }, []);

  //RESPONSIVE CHANGES TRIGGER BY DEVICE WIDTH TO SHOW DAILY VIEW ONLY
  useEffect(() => {
    setMeetingsView("daily");
  }, [mobile]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Dashboard>
      {/**Meetings Title with Search & New Meeting Button */}
      {/**Need to put notifications button/function at the end of this container */}
      <Box className={styles.meetingsOverallPage}>
        <Stack
          p={1}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "white",
            paddingTop: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #E5E5E5",
          }}
          direction={"row"}
        >
          <Typography p={1} variant="h2">
            Meetings
          </Typography>

          <Stack direction={"row"}>
            {matches ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNewMeeting}
                  sx={{ mr: 2 }}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New Meeting
                </Button>
                {/* <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    getMedia();
                  }}
                >
                  <VideoCallOutlinedIcon sx={{ mr: 1 }} />
                  Meet Now
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNewMeeting}
                  sx={{ mr: 2 }}
                >
                  <AddIcon sx={{ mr: 1 }} />
                </Button>
                <Button variant="outlined" color="secondary">
                  <VideoCallOutlinedIcon sx={{ mr: 1 }} />
                </Button>
              </>
            )}
          </Stack>
        </Stack>

        <Stack
          className={"Outer"}
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              maxWidth: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "400px",
                xl: "400px",
              },
              minWidth: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "400px",
                xl: "400px",
              },
            }}
            p={1}
          >
            <Stack
              p={2}
              sx={{ paddingLeft: "10px", paddingRight: "0px" }}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Select
                  sx={{
                    minWidth: "130px",
                    maxHeight: "40px",
                  }}
                  value={meetingsView}
                  onChange={(e) => setMeetingsView(e.target.value)}
                >
                  <MenuItem value={"daily"}>Daily</MenuItem>
                  <MenuItem value={"weekly"}>Weekly</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
              </Box>

              <Button
                variant="outlined"
                sx={{
                  color: "#031C3A",
                  borderColor: "#CCCCCC",
                  fontSize: "1rem",
                  borderRadius: "4px",
                  maxHeight: "40px",
                }}
                startIcon={<TodayIcon />}
                onClick={() => {
                  setGlobalDate(new Date());
                }}
              >
                Today
              </Button>
            </Stack>
            <Stack display={{ xs: "inline", lg: "none" }}>
              <DaySelector />
            </Stack>
            <Stack>
              {meetingsView === "daily" ? (
                <>
                  <Box display={{ xs: "none", lg: "inline" }}>
                    <Calendar meetings={meetings} />
                  </Box>
                  <Box display={{ xs: "inline", lg: "none" }}>
                    <MobileCalendar meetings={meetings} />
                  </Box>
                </>
              ) : meetingsView === "weekly" ? (
                <>
                  <Box display={{ xs: "none", lg: "inline" }}>
                    <Calendar meetings={meetings} />
                  </Box>
                </>
              ) : meetingsView === "monthly" ? (
                <Box display={{ xs: "none", lg: "inline" }}>
                  <Calendar meetings={meetings} />
                </Box>
              ) : null}
            </Stack>

            <Stack
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "inline",
                  xl: "inline",
                },
              }}
            >
              <PendingInvites
                meetings={meetings}
                needsRefresh={needsRefresh}
                setNeedsRefresh={setNeedsRefresh}
              />
            </Stack>
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.palette.background.default,
            }}
            className={"test"}
            p={1}
            width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%" }}
          >
            {meetingsView === "daily" ? (
              <DailyView
                editMeetingModalIsOpen={editMeetingModalIsOpen}
                meetings={meetings}
                needsRefresh={needsRefresh}
                setNeedsRefresh={setNeedsRefresh}
                handleExpansionClick={handleExpansionClick}
              />
            ) : meetingsView === "weekly" ? (
              <WeeklyView
                meetings={meetings}
                handleExpansionClick={handleExpansionClick}
              />
            ) : meetingsView === "monthly" ? (
              <MonthlyView
                meetings={meetings}
                handleExpansionClick={handleExpansionClick}
              />
            ) : null}
          </Box>
        </Stack>
      </Box>

      {createMeetingModalIsOpen ? (
        <CreateMeeting
          editMeetingModalIsOpen={editMeetingModalIsOpen}
          createMeetingModalIsOpen={createMeetingModalIsOpen}
          setCreateMeetingModalIsOpen={setCreateMeetingModalIsOpen}
          setNeedsRefresh={setNeedsRefresh}
          setSaveMeetingIsClicked={setSaveMeetingIsClicked}
          saveMeetingIsClicked={saveMeetingIsClicked}
          setSnackBarIsOpenForCreateMeeting={setSnackBarIsOpenForCreateMeeting}
        />
      ) : (
        <></>
      )}

      {meetingOverViewModalIsOpen ? (
        <MeetingOverview
          meetingOverViewModalIsOpen={meetingOverViewModalIsOpen}
          setMeetingOverViewModalIsOpen={setMeetingOverViewModalIsOpen}
          editMeetingModalIsOpen={editMeetingModalIsOpen}
          setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
          setSaveMeetingIsClicked={setSaveMeetingIsClicked}
          setSnackBarIsOpenForDeleteMeeting={setSnackBarIsOpenForDeleteMeeting}
        />
      ) : (
        <></>
      )}

      {editMeetingModalIsOpen ? (
        <EditMeeting
          setNeedsRefresh={setNeedsRefresh}
          createMeetingModalIsOpen={createMeetingModalIsOpen}
          setMeetingOverViewModalIsOpen={setMeetingOverViewModalIsOpen}
          editMeetingModalIsOpen={editMeetingModalIsOpen}
          setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
          setSnackBarIsOpenForEditMeeting={setSnackBarIsOpenForEditMeeting}
        />
      ) : (
        <> </>
      )}

      <SnackbarComponent
        message={"Meeting created"}
        vertical={"bottom"}
        horizontal={"right"}
        severity={"success"}
        open={snackBarIsOpenForCreateMeeting}
        setOpen={setSnackBarIsOpenForCreateMeeting}
      />

      <SnackbarComponent
        message={"Meeting updated"}
        vertical={"bottom"}
        horizontal={"right"}
        severity={"success"}
        open={snackBarIsOpenForEditMeeting}
        setOpen={setSnackBarIsOpenForEditMeeting}
      />

      <SnackbarComponent
        message={"Meeting deleted"}
        vertical={"bottom"}
        horizontal={"right"}
        severity={"success"}
        open={snackBarIsOpenForDeleteMeeting}
        setOpen={setSnackBarIsOpenForDeleteMeeting}
      />

      {preCallModalIsOpen ? <AuthorizedPreCall /> : <></>}
    </Dashboard>
  );
};

export default Meetings;
