///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING MOBILE CALENDAR
///
///////////////////////////////////////////////////////////////
import { useRef, useEffect, useState, useContext } from "react";

// API
import { VTMeetings, VTSingleMeeting } from "../../../types";

//MUI COMPONENT AND TIME FUNCTIONS
import { Box, Stack, Badge, Typography } from "@mui/material";

//TIME LIBRARY
import {
  format,
  isBefore,
  getDate,
  isSameDay,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  isToday,
  isAfter,
  isPast,
  getUnixTime,
} from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";

// GLOBAL STATE
import { meetingsStore, utilityStore } from "@state/store";

// RRULE LIBRARY
import { rrulestr } from "rrule";

/*
 *
 *
 * NOTE FROM DAN: There is a console error that is saying that the ids do not match.
 * They do not match because they are off by a few miliseconds. Will need to change the structure
 * of globalDate in global store to only be of  Day Month Year, and similar to "day" on this component
 */

///////////////////////////////////////////////////////////////
///
///  CALENDAR COMPONENT MOBILE
///
///////////////////////////////////////////////////////////////
export type CalendarProps = {
  meetings: VTMeetings[];
};
const MobileCalendar = ({ meetings }: CalendarProps) => {
  // GLOBAL STATE
  const { globalDate, setGlobalDate } = meetingsStore();
  const { navbarOpen } = utilityStore();

  //STATE
  const [value, setValue] = useState<Date | null>(globalDate);
  const [highlightedDays, setHighlightedDays] = useState(new Set());
  const [daysArray, setDaysArray] = useState<Date[]>([globalDate]);
  const [reset, setReset] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  //BREAKPOINTS
  const matches = useMediaQuery("(min-width:300px)");

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const hasMeetings = (day: Date) => {
    let hasMeetings = false;
    const Day = day.getDate();
    if (highlightedDays.has(Day)) {
      hasMeetings = true;
    }
    return hasMeetings;
  };

  // FUNCTION RECOMPUTES CALENDAR ARRAY ON RESIZE
  const calculateDaysArray = () => {
    let width;
    if (navbarOpen) {
      if (matches) {
        width = dimensions.width;
      } else {
        width = dimensions.width - 250;
      }
    } else {
      width = dimensions.width;
    }
    const cell = 68;

    const columns = Math.floor((width - 100) / cell);
    const left = Math.floor(columns / 2);
    const right = Math.floor(columns / 2);

    const today = globalDate;
    const start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - left
    );
    const startDate = start.getTime();

    const end = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + right
    );
    const endDate = end.getTime();

    const daysArray = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    return daysArray;
  };

  // SET GLOBAL STATE FOR NAVIGATION
  const handleSelection = (date: Date) => {
    if (date !== null) {
      setValue(date);
      setGlobalDate(date);
    }
  };

  // MODIFY ARRAY TO DISPLAY WHEN USER NAVIGATES USING CHANGE DAY BUTTONS
  const modifyDaysArray = () => {
    if (globalDate)
      if (daysArray.length > 0) {
        const currentDay = globalDate;
        const firstDayInArray = daysArray[0];
        const firstDayOuthisdeArray = new Date(
          firstDayInArray.getFullYear(),
          firstDayInArray.getMonth(),
          firstDayInArray.getDate() - 1
        );

        const lastDayInArray = daysArray[daysArray.length - 1];
        const lasDayOutsideArray = new Date(
          lastDayInArray.getFullYear(),
          lastDayInArray.getMonth(),
          lastDayInArray.getDate() + 1
        );
        const lastDayOutsideArrayOffset = new Date(
          lastDayInArray.getFullYear(),
          lastDayInArray.getMonth(),
          lastDayInArray.getDate() + 2
        );

        //OUTSID RESET ON TODAY BUTTON CLICK
        if (isBefore(currentDay, firstDayOuthisdeArray)) {
          setReset(!reset);
        }
        if (isAfter(currentDay, lastDayOutsideArrayOffset)) {
          setReset(!reset);
        }

        // RIGHT
        if (isSameDay(lasDayOutsideArray, currentDay)) {
          const newLastDay = new Date(
            currentDay.getFullYear(),
            currentDay.getMonth(),
            currentDay.getDate() + daysArray.length - 1
          );

          const newDaysArray = eachDayOfInterval({
            start: currentDay,
            end: newLastDay,
          });

          setDaysArray(newDaysArray);
        }
        // LEFT
        if (isSameDay(firstDayOuthisdeArray, currentDay)) {
          const newFirstDay = new Date(
            currentDay.getFullYear(),
            currentDay.getMonth(),
            currentDay.getDate() - daysArray.length + 1
          );
          const newDaysArray = eachDayOfInterval({
            start: newFirstDay,
            end: currentDay,
          });
          setDaysArray(newDaysArray);
        }
      }
  };
  // DEBOUNCE HELPER FUNCTION
  const debounce = (func: any, delay: any) => {
    const time = delay;
    let timer: any;
    return function (event: any) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
      setRefresh(!refresh);
    };
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION (MEETINGS DATASTRUCTURE)
  ///  FORMATS MEETINGS TO DISPLAY FOR ALL DAYS IN THE SELECTED MONTH CALENDAR
  ///
  ///////////////////////////////////////////////////////////////

  // GET MEETING INSANCE ON A GIVEN DATE IF MMETING FALLS ON THAT DATE GIVEN RRULE
  const getTodaysOccurance = (date: Date, meeting: any) => {
    try {
      const rule = rrulestr(meeting.rrule, {
        forceset: true,
      });
      const start = startOfDay(date);
      const end = endOfDay(date);
      const copy = JSON.parse(JSON.stringify(meeting));
      // @ts-ignore
      const exDates = rule.exdates();
      const occurrences = rule.between(start, end, true);

      if (occurrences.length > 0) {
        copy.start = getUnixTime(occurrences[0]);
        return copy;
      } else {
        return false;
      }
    } catch (e) {
      //console.log(e);
    }
  };

  // GET ALL RECCURNING  MEETINGS ON A GIVEN DATE RANGE
  const getTodaysReccuringMeetings = (
    meetings: VTSingleMeeting[],
    daysArray: Date[]
  ) => {
    const reccuringMeetings: VTSingleMeeting[] = [];

    daysArray.forEach((date: Date) => {
      meetings.forEach((meeting: any) => {
        if (meeting.rrule !== "" || meeting.rrule !== null) {
          const todaysInstance = getTodaysOccurance(date, meeting);
          if (todaysInstance) {
            reccuringMeetings.push(todaysInstance);
          }
          return;
        }
        return;
      });
    });

    return reccuringMeetings;
  };

  // REMOVE MEETINGS THAT SATISFY EXDATE
  const removeExdateMeetings = (meetings: any) => {
    const filteredMeetings: any = [];
    meetings.forEach((meeting: any) => {
      if (meeting.rrule !== "") {
        const rule = rrulestr(meeting.rrule, {
          forceset: true,
        });
        // @ts-ignore
        const exDates = rule.exdates();
        let match = false;
        exDates.forEach((date: any) => {
          if (isSameDay(date, new Date(meeting.start * 1000))) {
            match = true;
          }
        });
        if (!match) {
          filteredMeetings.push(meeting);
        }
      } else {
        filteredMeetings.push(meeting);
      }
    });
    return filteredMeetings;
  };
  // FUNCTION THAT SETS DAYS WITH MEETINGS ON THE CALENDAR (HIGHLIGHTED) YELLOW DOT
  const getDaysOfMeetings = (meetings: any) => {
    const daysSet = new Set<number>();
    const date = globalDate;
    const firstDayOfRange = daysArray[0];
    const lastDayOfRange = daysArray[daysArray.length - 1];
    const lastDayOfRangeOffseted: any = new Date(
      lastDayOfRange.getFullYear(),
      lastDayOfRange.getMonth(),
      lastDayOfRange.getDate() + 1
    );
    const rangeArray = eachDayOfInterval({
      start: firstDayOfRange,
      end: lastDayOfRangeOffseted,
    });

    const allMeetings = JSON.parse(JSON.stringify(meetings));

    // non reccuring meetings on between selected dates
    const monthsRegularMeetings = allMeetings
      .filter(
        (meeting: VTMeetings) =>
          meeting.start >= getUnixTime(startOfDay(firstDayOfRange)) &&
          meeting.start <= getUnixTime(endOfDay(lastDayOfRangeOffseted))
      )
      .filter(
        (meeting: VTMeetings) => meeting.rrule === "" || meeting.rrule === null
      );

    // reccuring meetings on between selected dates
    let monthsReccuringMeetings = getTodaysReccuringMeetings(
      allMeetings,
      rangeArray
    );
    // remove meetings that satisfy exdate constraints
    monthsReccuringMeetings = removeExdateMeetings(monthsReccuringMeetings);

    const combinedMeetings = [
      ...monthsRegularMeetings,
      ...monthsReccuringMeetings,
    ];

    combinedMeetings.forEach((meeting: any) => {
      const meetingDate = new Date(meeting.start * 1000);
      if (
        meetingDate >= firstDayOfRange &&
        meetingDate <= lastDayOfRangeOffseted
      ) {
        daysSet.add(getDate(meetingDate));
      }
    });
    setHighlightedDays(daysSet);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // SYNC GLOBAL STATE WIH CALENDAR STATE
  useEffect(() => {
    setValue(globalDate);
    modifyDaysArray();
  }, [globalDate]);

  // USED TO POPULATE HIGHLIGHTED DAYS THAT CONTAIN MEETINGS
  useEffect(() => {
    getDaysOfMeetings(meetings);
  }, [meetings, value]);

  // USED TO RESIZE THE CALLENDAR ARRAY BASED ON SIZE OF SCREEN AND ON RESET
  useEffect(() => {
    if (dimensions.width > 0) {
      const daysArray = calculateDaysArray();
      setDaysArray(daysArray);

      debounce(getDaysOfMeetings(meetings), 300);
      //   console.log(daysArray);
      //   console.log(highlightedDays);
    }
  }, [dimensions, reset]);

  // ALTERS RESIZE OF WINDOW WHEN NAVBAR OPEN OR CLOSED
  useEffect(() => {
    window.addEventListener("resize", debounce(handleResize, 100), false);
    if (navbarOpen) {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    } else {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    }
  }, [navbarOpen]);
  useEffect(() => {
    debounce(getDaysOfMeetings(meetings), 300);
  }, [daysArray]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Stack
      p={1}
      pt={0}
      width={1}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-around"}
      sx={{
        background: "white",
        width: "100%",
        overflow: "hidden",
        scrollbarHeight: "none",
      }}
    >
      {daysArray.map((day: any) => {
        return (
          <Stack key={day} id={day} onClick={(e) => handleSelection(day)}>
            <Box p={1} sx={{ alignItems: "center", justifyContent: "center" }}>
              <Typography variant="body2" p={1} textAlign="center">
                {format(day, "iiiii")}
              </Typography>

              {isToday(day) ? (
                <Box>
                  {isPast(day) ? (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          marginLeft: "35%",
                        },
                      }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      key={"s"}
                      overlap="circular"
                      color="warning"
                      variant={hasMeetings(day) ? "dot" : undefined}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          background: isSameDay(globalDate, day)
                            ? "var(--visionable-mid-blue)"
                            : "rgba(189, 216, 250, 1)",
                          color: isSameDay(globalDate, day) ? "white" : "black",
                          borderRadius: "50%",
                        }}
                        p={1}
                      >
                        {format(day, "dd")}
                      </Typography>
                    </Badge>
                  ) : (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          marginLeft: "35%",
                        },
                      }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      key={"s"}
                      overlap="circular"
                      color="warning"
                      variant={hasMeetings(day) ? "dot" : undefined}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          background: isSameDay(globalDate, day)
                            ? "var(--visionable-mid-blue)"
                            : "white",
                          color: isSameDay(globalDate, day) ? "white" : "black",
                          borderRadius: "50%",
                        }}
                        p={1}
                      >
                        {format(day, "dd")}
                      </Typography>
                    </Badge>
                  )}
                </Box>
              ) : (
                <Box>
                  {isPast(day) ? (
                    <Typography
                      variant="body2"
                      sx={{
                        background: isSameDay(globalDate, day)
                          ? "var(--visionable-mid-blue)"
                          : "white",
                        color: isSameDay(globalDate, day) ? "white" : "gray",
                        borderRadius: "50%",
                      }}
                      p={1}
                    >
                      {format(day, "dd")}
                    </Typography>
                  ) : (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          marginLeft: "35%",
                        },
                      }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      key={"s"}
                      overlap="circular"
                      color="warning"
                      variant={hasMeetings(day) ? "dot" : undefined}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          background: isSameDay(globalDate, day)
                            ? "var(--visionable-mid-blue)"
                            : "white",
                          color: isSameDay(globalDate, day) ? "white" : "black",
                          borderRadius: "50%",
                        }}
                        p={1}
                      >
                        {format(day, "dd")}
                      </Typography>
                    </Badge>
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MobileCalendar;
