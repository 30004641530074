import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type VTClose = {
  handleCloseButton: () => void;
};

const Close = ({ handleCloseButton }: VTClose) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "39px",
          height: "100%",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={handleCloseButton}>
          <CloseIcon />
        </Button>
      </Box>
    </>
  );
};

export default Close;
