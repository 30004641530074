import { Box, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";

export type VTMeetingTitle = {
  meetingTitleError: boolean;
  setMeetingTitleError: (meetingTitleError: boolean) => void;
  meetingTitleLengthErrorTooShort: boolean;
  meetingTitleLengthErrorTooLong: boolean;
  meetingTitle: string;
  setMeetingTitle: (meetingTitle: string) => void;
  existingMeetingTitle?: string;
  createMeetingModalIsOpen: boolean;
};

const MeetingTitle = ({
  meetingTitle,
  meetingTitleError,
  setMeetingTitleError,
  setMeetingTitle,
  meetingTitleLengthErrorTooShort,
  meetingTitleLengthErrorTooLong,
  existingMeetingTitle,
  createMeetingModalIsOpen,
}: VTMeetingTitle) => {
  const [characterCount, setCharacterCount] = useState<number>(50);
  const [meetingTitleCharacterCount, setMeetingTitleCharacterCount] =
    useState<number>(0);
  const [existingTitleCharacterCount, setExistingTitleCharacterCount] =
    useState<number>(0);

  useEffect(() => {
    setMeetingTitleCharacterCount(meetingTitle?.length);
  }, [meetingTitle]);

  useEffect(() => {
    existingMeetingTitle !== undefined
      ? setExistingTitleCharacterCount(existingMeetingTitle?.length)
      : setExistingTitleCharacterCount(0);
  }, [existingMeetingTitle]);

  useEffect(() => {
    setCharacterCount(50 - meetingTitle?.length);
  }, [meetingTitle]);

  useEffect(() => {
    if (meetingTitle?.length > 50) {
      setMeetingTitleError(true);
    } else {
      setMeetingTitleError(false);
    }
  }, [meetingTitle]);

  return (
    <>
      <Box
        sx={{
          diplay: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {meetingTitleError ? (
            <TextField
              autoFocus
              error
              id="outlined-error-helper-text"
              label="Error"
              value={meetingTitle}
              onChange={(e) => setMeetingTitle(e.target.value)}
              helperText="A title must be 3 to 50 characters"
              sx={{
                marginLeft: "0px",
                "& .MuiFormHelperText-root.Mui-error": { marginLeft: "0px" },
              }}
            />
          ) : (
            <>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "21.82px",
                  color: "#031C3A",
                }}
              >
                Title
              </Typography>
              <TextField
                autoFocus
                id="outlined-read-only-input"
                value={meetingTitle}
                defaultValue={existingMeetingTitle}
                onChange={(e) => setMeetingTitle(e.target.value)}
                placeholder="Add title here"
              />
            </>
          )}
        </Box>
        {meetingTitle || createMeetingModalIsOpen ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "30px",
              top: "185px",
            }}
          >
            <Typography
              sx={{
                size: "10px",
                color: meetingTitleError ? "var(--red)" : "#CCCCCC",
              }}
            >
              {meetingTitleCharacterCount}/50
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "30px",
              top: "195px",
            }}
          >
            <Typography
              sx={{
                size: "10px",
                color: meetingTitleError ? "var(--red)" : "#CCCCCC",
              }}
            >
              {existingTitleCharacterCount}/50
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MeetingTitle;
