import Typography from "@mui/material/Typography";
import { meetingStore } from "@state/store";

const Title = () => {
  const { selectedMeeting } = meetingStore();
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "32px",
          color: "white",
          padding: "30px",
        }}
      >
        {selectedMeeting.name}
      </Typography>
    </>
  );
};

export default Title;
