///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING MONTHLY MEETING CARD
///
///////////////////////////////////////////////////////////////

import { useState, useEffect, useContext } from "react";

//TIME LIBRARY
import { format } from "date-fns";

// MUI COMPONENTS
import { Box, Stack } from "@mui/material";
import Fade from "@mui/material/Fade";

// TYPES
import { VTInvite, VTMeetings, VTSingleMeeting } from "../../../../types";

//STYLES
import theme from "@components/VisionableTheme";

// CUSTOM COMPONENTS
import StatusIndicator from "../StatusIndicator";

// GLOBAL STATE
import { meetingsStore, userStore } from "@state/store";

///////////////////////////////////////////////////////////////
///
///  MONTHLY CARD COMPONENT
///
///////////////////////////////////////////////////////////////

export type VTMeetingCardProps = {
  selection: any;
  setDateOfSelection: Function;
  handleSelection: Function;
  meeting: VTMeetings;

  handleExpansionClick: ({ uuid }: VTSingleMeeting) => void;
};

const MonthlyCard = ({
  meeting,
  selection,
  handleSelection,
  setDateOfSelection,

  handleExpansionClick,
}: VTMeetingCardProps) => {
  // GLOBAL STATE
  const { globalDate, setGlobalDate } = meetingsStore();
  const { user } = userStore();
  //  STATE
  const [liveStatus, setLiveStatus] = useState(false);
  const [inPastStatus, setInPastStatus] = useState(false);

  // DATE TIME OPERATIONS
  const formattedDate = new Date(meeting.start * 1000);
  const formattedEndDate = new Date(
    meeting.start * 1000 + meeting.duration * 1000
  );
  const meetingStartTime = format(new Date(formattedDate), "HH:mm");
  const meetingEndTime = format(new Date(formattedEndDate), "HH:mm");

  // INVITES
  const userStatusArray: any = meeting.invites.filter((invite: VTInvite) => {
    if (invite?.invitee_id === user?.user_id) {
      return invite.status;
    }
  });

  const userStatus: string = userStatusArray.map((invite: VTInvite) => {
    return invite.status;
  });

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTIONS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // DETERMINE IF MEETING IS CURENTLY LIVE
  const isMeetingLive = () => {
    const currentTime = new Date().getTime();
    const meetingStartTime = new Date(formattedDate).getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();
    const meetingDuration = meeting.duration;

    if (
      currentTime < meetingStartTime - 900 ||
      currentTime > meetingEndTime + meetingDuration
    ) {
      setLiveStatus(false);
    } else {
      setLiveStatus(true);
    }
  };
  // DETERMINE IF MEETING IS IN THE PAST
  const isMeetingPast = () => {
    const currentTime = new Date().getTime();
    const meetingEndTime = new Date(formattedEndDate).getTime();

    if (currentTime > meetingEndTime) {
      setInPastStatus(true);
    } else {
      setInPastStatus(false);
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    isMeetingLive();
    isMeetingPast();
  }, [meeting]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <>
      {inPastStatus ? (
        <Fade in={true}>
          <Box
            p={0.25}
            key={meeting.meeting_id}
            id={meeting.meeting_id as any}
            style={
              liveStatus
                ? { background: "#00AEEF1A", boxShadow: "0px 0px 3px #1271EE" }
                : {}
            }
            onClick={(event: any) => {
              handleSelection(event);
              setDateOfSelection(new Date(meeting.start * 1000));
              setGlobalDate(new Date(meeting.start * 1000));
            }}
            sx={{
              background: theme.palette.grey[500],
              borderRadius: "10px",
              width: "100%",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Stack>
              {/* Meeting   Section */}
              <Stack
                spacing={0.5}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box sx={{ visibility: "hidden" }}>
                  <StatusIndicator status={userStatus} />
                </Box>
                <Box
                  sx={{
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    minWidth: "10px",
                    width: "calc(80vw / 7)",
                    overflow: "hidden",
                    fontSize: "1em",
                    fontWeight: "bold",
                    color: theme.palette.background.paper,
                  }}
                >
                  {meetingStartTime}-{meetingEndTime} {meeting.name}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      ) : (
        <Fade in={true}>
          <Box
            p={0.25}
            key={meeting.meeting_id}
            id={meeting.meeting_id as any}
            style={
              liveStatus
                ? { background: "#00AEEF1A", boxShadow: "0px 0px 3px #1271EE" }
                : {}
            }
            onClick={(event: any) => {
              handleSelection(event);
              setDateOfSelection(new Date(meeting.start * 1000));
              setGlobalDate(new Date(meeting.start * 1000));
            }}
            sx={{
              background: "#ffffff",
              borderRadius: "10px",
              width: "100%",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Stack>
              {/* Meeting   Section */}
              <Stack
                spacing={0.5}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <StatusIndicator status={userStatus} />
                <Box
                  sx={{
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    minWidth: "10px",
                    width: "calc(80vw / 7)",
                    overflow: "hidden",
                    fontSize: "1em",
                    fontWeight: "bold",
                    color: "var(--visionable-dark-blue)",
                  }}
                >
                  {meetingStartTime}-{meetingEndTime} {meeting.name}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      )}
    </>
  );
};

export default MonthlyCard;
