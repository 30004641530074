///////////////////////////////////////////////////////////////
///
/// SKELETON COMPONENTS FOR VARIOUS PARTS OF THE APP
///
///////////////////////////////////////////////////////////////
//STYLES
import styles from "./index.module.css";
import theme from "@components/VisionableTheme";

// MUI COMPONENTS
import { useEffect, useState, useRef, cloneElement, useContext } from "react";
import { Box, Stack, Skeleton } from "@mui/material";

export const MeetingPopupSkeleton = () => {
  return (
    <Box>
      <Stack p={2}>
        <Skeleton variant="text" height={60} />
        <Stack spacing={2} direction={"row"} alignItems={"center"}>
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="text" width={"90%"} height={25} />
        </Stack>
        <Box p={1}></Box>
        <Stack spacing={0} direction={"column"} alignItems={"center"}>
          <Skeleton variant="text" width={"100%"} height={25} />
          <Skeleton variant="text" width={"100%"} height={25} />
          <Skeleton variant="text" width={"100%"} height={25} />
          <Skeleton variant="text" width={"100%"} height={25} />
        </Stack>
        <Box p={2}></Box>
        <Stack spacing={1} direction={"column"} alignItems={"flex-end"}>
          <Skeleton variant="text" width={150} height={60} />
        </Stack>
        <Box p={2}></Box>
        <Stack spacing={1} direction={"row"} alignItems={"flex-start"}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Stack>
      </Stack>
    </Box>
  );
};

export const DailyMeetingsSkeleton = () => {
  return (
    <Stack
      m={1}
      p={2}
      sx={{
        maxHeight: "152px",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Stack spacing={2} p={1} width={"100%"}>
        <Skeleton variant="text" width={"100%"} height={25} />
        <Skeleton variant="text" width={"100%"} height={25} />
        <Skeleton variant="text" width={"100%"} height={25} />
      </Stack>
    </Stack>
  );
};
