import { useState, useEffect, useRef } from "react";
import {
  Box,
  Select,
  MenuItem,
  Modal,
  Typography,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
  styled,
  Stack,
} from "@mui/material";

import RRULEDaily from "./RRULEDaily/index";
import RRULEWeekly from "./RRULEWeekly/index";
import RRULEMonthly from "./RRULEMonthly/index";
import { format } from "date-fns";
import { RRule, RRuleSet } from "rrule";

import { getUnixTime, fromUnixTime } from "date-fns";

import { meetingsStore } from "@state/store";

export type VTRepeatMeetingProps = {
  editMeetingModalIsOpen: boolean;
  existingByDay?: any[];
  frequency: string;
  setFrequency: (frequency: string) => void;
  byMonth: string;
  setByMonth: (byMonth: string) => void;
  byMonthDay: number;
  setByMonthDay: (ByMonthDay: number) => void;
  byDay: any[];
  setByDay: (byDay: any[]) => void;
  interval: number;
  setInterval: (interval: number) => void;
  bySetPos: string;
  setBySetPos: (bySetPos: string) => void;
  count: number;
  setCount: (count: number) => void;
  until: Date;
  setUntil: (until: Date) => void;
  byDayWeekly: string;
  setByDayWeekly: (byDayWeekly: string) => void;
  repeatMeeting: string;
  setRepeatMeeting: (repeatMeeting: string) => void;
  meetingStartDate: string;
  setMeetingStartDate: (meetingStartDate: string) => void;
  dtStart: Date;
  setDtStart: (dtStart: Date) => void;
  setRRule: (rRule: string) => void;
  reoccuringMeetingEnd: Date;
  setReoccuringMeetingEnd: (reoccuringMeetingEnd: Date) => void;
  existingActiveDaysString?: string;
  startingTime: string;
  checked: boolean;
  activeDaysString: string;
  setActiveDaysString: (activeDaysString: string) => void;
  dayString: string;
  setDayString: (dayString: string) => void;
  monthString: string;
  setMonthString: (monthString: string) => void;
  setRruleIsSaved: (rruleIsSaved: boolean) => void;
  recurrenceChecked: boolean;
  setRecurrenceChecked: (recurrenceChecked: boolean) => void;
  recurrenceModalIsOpen: boolean;
  setRecurrenceModalIsOpen: (recurrenceModalIsOpen: boolean) => void;
};

const RepeatMeeting = ({
  checked,
  editMeetingModalIsOpen,
  existingByDay,
  existingActiveDaysString,
  frequency,
  bySetPos,
  byMonth,
  byMonthDay,
  byDay,
  interval,
  count,
  until,
  byDayWeekly,
  repeatMeeting,
  dtStart,
  startingTime,
  setDtStart,
  setCount,
  setUntil,
  setFrequency,
  setByMonth,
  setByMonthDay,
  setByDay,
  setInterval,
  setBySetPos,
  setRRule,
  setByDayWeekly,
  setRepeatMeeting,
  meetingStartDate,
  setMeetingStartDate,
  reoccuringMeetingEnd,
  setReoccuringMeetingEnd,
  activeDaysString,
  setActiveDaysString,
  dayString,
  setDayString,
  monthString,
  setMonthString,
  setRruleIsSaved,
  recurrenceChecked,
  setRecurrenceChecked,
  recurrenceModalIsOpen,
  setRecurrenceModalIsOpen,
}: VTRepeatMeetingProps) => {
  const ref = useRef(null);
  const {
    dateForCalendar,
    setDateForCalendar,
    dateForUntilCalendar,
    setDateForUntilCalendar,
  } = meetingsStore();

  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [isDayActive, setIsDayActive] = useState({
    days: [
      {
        id: 1,
        day: "M",
        value: RRule.MO,
        fullDay: "Monday",
        isActive: false,
      },
      {
        id: 2,
        day: "T",
        value: RRule.TU,
        fullDay: "Tuesday",
        isActive: false,
      },
      {
        id: 3,
        day: "W",
        value: RRule.WE,
        fullDay: "Wednesday",
        isActive: false,
      },
      {
        id: 4,
        day: "T",
        value: RRule.TH,
        fullDay: "Thursday",
        isActive: false,
      },
      {
        id: 5,
        day: "F",
        value: RRule.FR,
        fullDay: "Friday",
        isActive: false,
      },
      {
        id: 6,
        day: "S",
        value: RRule.SA,
        fullDay: "Saturday",
        isActive: false,
      },
      {
        id: 7,
        day: "S",
        value: RRule.SU,
        fullDay: "Sunday",
        isActive: false,
      },
    ],
  });
  const [pickDateButton, setPickDateButton] = useState<boolean>(false);

  const handleReoccuranceToggle = () => {
    setRecurrenceModalIsOpen(true);
    setRecurrenceChecked(!recurrenceChecked);
    setActiveDaysString("");
    setIsDayActive({
      days: [
        {
          id: 1,
          day: "M",
          value: RRule.MO,
          fullDay: "Monday",
          isActive: false,
        },
        {
          id: 2,
          day: "T",
          value: RRule.TU,
          fullDay: "Tuesday",
          isActive: false,
        },
        {
          id: 3,
          day: "W",
          value: RRule.WE,
          fullDay: "Wednesday",
          isActive: false,
        },
        {
          id: 4,
          day: "T",
          value: RRule.TH,
          fullDay: "Thursday",
          isActive: false,
        },
        {
          id: 5,
          day: "F",
          value: RRule.FR,
          fullDay: "Friday",
          isActive: false,
        },
        {
          id: 6,
          day: "S",
          value: RRule.SA,
          fullDay: "Saturday",
          isActive: false,
        },
        {
          id: 7,
          day: "S",
          value: RRule.SU,
          fullDay: "Sunday",
          isActive: false,
        },
      ],
    });
  };

  const handleClose = () => {
    setRecurrenceModalIsOpen(false);
    setRecurrenceChecked(false);
    setPickDateButton(false);
    setDateForCalendar(new Date());
    setDateForUntilCalendar(new Date());
    setRepeatMeeting("no");
    setActiveDaysString("");
    setReoccuringMeetingEnd(new Date());
    setIsDayActive({
      days: [
        {
          id: 1,
          day: "M",
          value: RRule.MO,
          fullDay: "Monday",
          isActive: false,
        },
        {
          id: 2,
          day: "T",
          value: RRule.TU,
          fullDay: "Tuesday",
          isActive: false,
        },
        {
          id: 3,
          day: "W",
          value: RRule.WE,
          fullDay: "Wednesday",
          isActive: false,
        },
        {
          id: 4,
          day: "T",
          value: RRule.TH,
          fullDay: "Thursday",
          isActive: false,
        },
        {
          id: 5,
          day: "F",
          value: RRule.FR,
          fullDay: "Friday",
          isActive: false,
        },
        {
          id: 6,
          day: "S",
          value: RRule.SA,
          fullDay: "Saturday",
          isActive: false,
        },
        {
          id: 7,
          day: "S",
          value: RRule.SU,
          fullDay: "Sunday",
          isActive: false,
        },
      ],
    });
  };

  const handleSaveClose = () => {
    setRruleIsSaved(true);
    setRecurrenceChecked(true);
    setRecurrenceModalIsOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    right: 0,
    width: "40%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "hidden",
  };

  //useEffect to handle the active days
  useEffect((): void => {
    const selectedDays = isDayActive.days.filter((day) => {
      if (day.isActive) {
        return day;
      }
    });

    const newArray = selectedDays.map((day) => {
      return day.value;
    });

    setByDay(newArray);

    const fullDayArray = selectedDays.map((day) => day.fullDay);

    if (fullDayArray.length > 1) {
      setActiveDaysString(fullDayArray.join(" and "));
    } else {
      setActiveDaysString(fullDayArray.toString());
    }
  }, [isDayActive]);

  const dateTime = (
    year: number,
    month: number,
    day: number,
    h?: number,
    min?: number,
    second?: number
  ) => {
    return fromUnixTime(
      getUnixTime(new Date(year, month - 1, day, h, min, second))
    );
  };

  //useEffect to handle dtstart
  useEffect((): void => {
    if (startingTime && meetingStartDate) {
      const hours = parseInt(startingTime?.slice(0, 2)) % 24;
      const minutes = parseInt(startingTime?.slice(3, 5));

      const year = parseInt(meetingStartDate?.slice(0, 4));
      const day = parseInt(meetingStartDate?.slice(8, 10));
      const month = parseInt(meetingStartDate?.slice(5, 7));

      setDtStart(dateTime(year, month, day, hours, minutes, 0));
    }
  }, [meetingStartDate, startingTime]);

  //useEffect to handle the ending of the
  useEffect(() => {
    if (reoccuringMeetingEnd && startingTime) {
      const hours = (parseInt(startingTime?.slice(0, 2)) + 4) % 24;
      const minutes = parseInt(startingTime?.slice(3, 5));

      const formatDate = format(reoccuringMeetingEnd, "yyyy-MM-dd");
      const untilYear = parseInt(formatDate.slice(0, 4));
      const untilMonth = parseInt(formatDate.slice(5, 7)) - 1;
      const untilDay = parseInt(formatDate.slice(8, 10));

      const newUntil = dateTime(
        untilYear,
        untilMonth,
        untilDay,
        hours,
        minutes,
        0
      );
      setUntil(newUntil);
    }
  }, [reoccuringMeetingEnd, repeatMeeting, startingTime]);

  useEffect(() => {
    if (repeatMeeting === "daily") {
      if (pickDateButton) {
        const rule = new RRule({
          freq: RRule.DAILY,
          interval: interval,
          count: count,
          byweekday: editMeetingModalIsOpen ? existingByDay : byDay,
          dtstart: dtStart,
          until: until,
        }).toString();
        setRRule(rule);
      } else {
        const rule = new RRule({
          freq: RRule.DAILY,
          interval: interval,
          count: count,
          byweekday: editMeetingModalIsOpen ? existingByDay : byDay,
          dtstart: dtStart,
        }).toString();
        setRRule(rule);
      }
    } else if (repeatMeeting === "weekly") {
      if (pickDateButton) {
        const rule = new RRule({
          freq: RRule.WEEKLY,
          interval: interval,
          count: count,
          byweekday: editMeetingModalIsOpen ? existingByDay : byDay,
          dtstart: dtStart,
          until: until,
        }).toString();
        setRRule(rule);
      } else {
        const rule = new RRule({
          freq: RRule.WEEKLY,
          interval: interval,
          count: count,
          byweekday: editMeetingModalIsOpen ? existingByDay : byDay,
          dtstart: dtStart,
        }).toString();
        setRRule(rule);
      }
    } else if (repeatMeeting === "monthly") {
      if (pickDateButton) {
        const rule = new RRule({
          freq: RRule.MONTHLY,
          interval: interval,
          count: count,
          bymonthday: [byMonthDay],
          dtstart: dtStart,
          until: until,
        }).toString();
        setRRule(rule);
      } else {
        const rule = new RRule({
          freq: RRule.MONTHLY,
          interval: interval,
          count: count,
          bymonthday: [byMonthDay],
          dtstart: dtStart,
        }).toString();
        setRRule(rule);
      }
    }
  }, [repeatMeeting, interval, count, byDay, byMonthDay, dtStart]);

  const months = [
    { id: 1, month: "January", value: "01" },
    { id: 2, month: "February", value: "02" },
    { id: 3, month: "March", value: "03" },
    { id: 4, month: "April", value: "04" },
    { id: 5, month: "May", value: "05" },
    { id: 6, month: "June", value: "06" },
    { id: 7, month: "July", value: "07" },
    { id: 8, month: "August", value: "08" },
    { id: 9, month: "September", value: "09" },
    { id: 10, month: "October", value: "10" },
    { id: 11, month: "November", value: "11" },
    { id: 12, month: "December", value: "12" },
  ];

  useEffect(() => {
    const monthArray = months.filter((day) => {
      if (day.value === meetingStartDate?.slice(5, 7)) {
        return day.month;
      }
    });
    if (monthArray) {
      setMonthString(monthArray[0]?.month);
    }
  }, [meetingStartDate]);

  useEffect(() => {
    const getNumberWithOrdinal = () => {
      if (byMonthDay) {
        const endings = ["th", "st", "nd", "rd"];
        const ending = endings[byMonthDay % 100 > 3 ? 0 : byMonthDay % 10];
        setDayString(`${byMonthDay}${ending}`);
      }
    };
    getNumberWithOrdinal();
  }, [meetingStartDate, byMonthDay]);

  const year = meetingStartDate?.slice(0, 4);

  const onChange = (e: SelectChangeEvent) => {
    setRepeatMeeting(e.target.value);
    setIsDayActive({
      days: [
        {
          id: 1,
          day: "M",
          value: RRule.MO,
          fullDay: "Monday",
          isActive: false,
        },
        {
          id: 2,
          day: "T",
          value: RRule.TU,
          fullDay: "Tuesday",
          isActive: false,
        },
        {
          id: 3,
          day: "W",
          value: RRule.WE,
          fullDay: "Wednesday",
          isActive: false,
        },
        {
          id: 4,
          day: "T",
          value: RRule.TH,
          fullDay: "Thursday",
          isActive: false,
        },
        {
          id: 5,
          day: "F",
          value: RRule.FR,
          fullDay: "Friday",
          isActive: false,
        },
        {
          id: 6,
          day: "S",
          value: RRule.SA,
          fullDay: "Saturday",
          isActive: false,
        },
        {
          id: 7,
          day: "S",
          value: RRule.SU,
          fullDay: "Sunday",
          isActive: false,
        },
      ],
    });
    setActiveDaysString("");
    setPickDateButton(false);
    setDateForUntilCalendar(new Date());
    setDateForCalendar(new Date());
  };

  //useEffect for existing day meeting information
  useEffect(() => {
    const day = meetingStartDate?.slice(8, 10);
    const monthNumber = meetingStartDate?.slice(5, 7);

    if (existingActiveDaysString) {
      setActiveDaysString(existingActiveDaysString);
      const monthString = months.filter((month) => {
        if (month.value === monthNumber) {
          return month.month;
        }
      });
      setMonthString(monthString[0].month);
      setDayString(day);
    }
  }, [existingActiveDaysString]);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: Event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenInfo(!openInfo);
          setRecurrenceModalIsOpen(!recurrenceModalIsOpen);
          setRecurrenceChecked(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ref);

  useEffect(() => {
    if (dateForCalendar > dateForUntilCalendar) {
      setDateForUntilCalendar(dateForCalendar);
    }
  }, [dateForCalendar, dateForUntilCalendar]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "120%",
          width: "151px",
          height: "38.7px",
          alignItems: "center",
        }}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          spacing={1}
          alignItems={"center"}
        >
          <Typography color={"primary"} variant="h4">
            Recurrence
          </Typography>
          <Switch
            checked={recurrenceChecked ? true : false}
            onChange={() => {
              if (recurrenceChecked == false) {
                setRepeatMeeting("weekly");
                handleReoccuranceToggle();
              } else {
                setRepeatMeeting("no");
                setRecurrenceChecked(false);
              }
            }}
          />
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "500px",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {repeatMeeting === "daily" && recurrenceModalIsOpen ? (
            <>
              <Modal
                open={recurrenceModalIsOpen}
                onClose={handleClose}
                ref={ref}
              >
                <Box sx={style}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "29px",
                    }}
                  >
                    Recurrence
                  </Typography>
                  <RRULEDaily
                    meetingStartDate={meetingStartDate}
                    setMeetingStartDate={setMeetingStartDate}
                    repeatMeeting={repeatMeeting}
                    handleClose={handleClose}
                    isDayActive={isDayActive}
                    setIsDayActive={setIsDayActive}
                    activeDaysString={activeDaysString}
                    dayString={dayString}
                    monthString={monthString}
                    year={year}
                    onChange={onChange}
                    pickDateButton={pickDateButton}
                    setPickDateButton={setPickDateButton}
                    interval={interval}
                    setInterval={setInterval}
                    count={count}
                    setCount={setCount}
                    reoccuringMeetingEnd={reoccuringMeetingEnd}
                    setReoccuringMeetingEnd={setReoccuringMeetingEnd}
                    handleSaveClose={handleSaveClose}
                  />
                </Box>
              </Modal>
            </>
          ) : repeatMeeting === "weekly" && recurrenceModalIsOpen ? (
            <Modal open={recurrenceModalIsOpen} onClose={handleClose} ref={ref}>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "29px",
                  }}
                >
                  Recurrence
                </Typography>
                <RRULEWeekly
                  repeatMeeting={repeatMeeting}
                  meetingStartDate={meetingStartDate}
                  setMeetingStartDate={setMeetingStartDate}
                  handleClose={handleClose}
                  isDayActive={isDayActive}
                  setIsDayActive={setIsDayActive}
                  activeDaysString={activeDaysString}
                  dayString={dayString}
                  monthString={monthString}
                  year={year}
                  onChange={onChange}
                  pickDateButton={pickDateButton}
                  setPickDateButton={setPickDateButton}
                  interval={interval}
                  setInterval={setInterval}
                  count={count}
                  setCount={setCount}
                  reoccuringMeetingEnd={reoccuringMeetingEnd}
                  setReoccuringMeetingEnd={setReoccuringMeetingEnd}
                  handleSaveClose={handleSaveClose}
                />
              </Box>
            </Modal>
          ) : repeatMeeting === "monthly" && recurrenceModalIsOpen ? (
            <Modal open={recurrenceModalIsOpen} onClose={handleClose} ref={ref}>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "29px",
                  }}
                >
                  Recurrence
                </Typography>
                <RRULEMonthly
                  repeatMeeting={repeatMeeting}
                  meetingStartDate={meetingStartDate}
                  setMeetingStartDate={setMeetingStartDate}
                  handleClose={handleClose}
                  isDayActive={isDayActive}
                  setIsDayActive={setIsDayActive}
                  onChange={onChange}
                  pickDateButton={pickDateButton}
                  setPickDateButton={setPickDateButton}
                  interval={interval}
                  setInterval={setInterval}
                  count={count}
                  setCount={setCount}
                  reoccuringMeetingEnd={reoccuringMeetingEnd}
                  setReoccuringMeetingEnd={setReoccuringMeetingEnd}
                  byMonthDay={byMonthDay}
                  setByMonthDay={setByMonthDay}
                  dayString={dayString}
                  monthString={monthString}
                  year={year}
                  handleSaveClose={handleSaveClose}
                />
              </Box>
            </Modal>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default RepeatMeeting;
