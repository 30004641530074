//React
import { useState, useEffect } from "react";

//Global Store
import { meetingStore, userStore } from "@state/store";

//Material UI Style Components
import { StyledDialog } from "@components/DialogModal";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

//JSX Components
import Title from "./Title";
import StateView from "./StateView";
import General from "./General";
import Advanced from "./Advanced";
import Buttons from "./Buttons";

//Types
import { VTMeetings } from "types";

const AuthorizedPreCall = () => {
  const views = ["general", "advanced"];
  const [view, setView] = useState<string>("general");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [selectedMicrophone, setSelectedMicrophone] = useState<string>("");
  const [getUserMediaVideoDone, setGetUserMediaVideoDone] =
    useState<boolean>(false);
  const [getUserMediaMicDone, setGetUserMediaMicDone] =
    useState<boolean>(false);

  const {
    preCallModalIsOpen,
    setPreCallModalIsOpen,
    setSelectedMeeting,
    videoIsOn,
    activeVideoStream,
    videoDevices,
    microphones,
    activeAudioStream,
    selectedVideoStream,
    selectedMicrophoneStream,
    microphoneIsOn,
  } = meetingStore();

  const store = meetingStore();

  const handleToggleForModal = () => {
    setPreCallModalIsOpen(!preCallModalIsOpen);
    setSelectedMeeting({} as VTMeetings);
    store.setLocalClients([]);
    store.setRemoteClients([]);
    store.setScreenShareClients([]);
    store.setRemoteAudioClients([]);
    store.setInitialsScreenShares([]);
    if (store.activeVideoStream !== null) {
      store?.activeVideoStream
        .getTracks()
        .forEach((track: any) => track.stop());
    }
    if (store.activeAudioStream !== null) {
      store?.activeAudioStream
        .getTracks()
        .forEach((track: any) => track.stop());
    }
    store.setActiveVideoStream({} as MediaStream);
    setPreCallModalIsOpen(false);
    store.setVideoIsOn(false);
    store.setMicrophoneIsOn(false);
  };

  useEffect(() => {
    if (
      videoDevices.length > 0 &&
      microphones.length > 0 &&
      selectedMicrophoneStream &&
      activeVideoStream &&
      getUserMediaVideoDone &&
      getUserMediaMicDone
    ) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [videoDevices, microphones, activeVideoStream, selectedMicrophoneStream]);

  return (
    <StyledDialog
      fullWidth
      maxWidth={"lg"}
      onClose={handleToggleForModal}
      open={preCallModalIsOpen}
      sx={{ m: 0 }}
    >
      <>
        <Stack sx={{ backgroundColor: "var(--blue)" }}>
          <Title />

          <StateView view={view} setView={setView} />

          <General
            isLoaded={isLoaded}
            selectedMicrophone={selectedMicrophone}
            setSelectedMicrophone={setSelectedMicrophone}
            setGetUserMediaVideoDone={setGetUserMediaVideoDone}
            setGetUserMediaMicDone={setGetUserMediaMicDone}
          />

          <Buttons isLoaded={isLoaded} />
        </Stack>
      </>
    </StyledDialog>
  );
};

export default AuthorizedPreCall;
