import { format } from "date-fns";
import { useEffect, useContext, useState } from "react";
import { Box, Stack, Button, Dialog, Typography } from "@mui/material";
import styles from "./index.module.css";
import EventIcon from "@mui/icons-material/Event";
import Fade from "@mui/material/Fade";
import { VTInvite } from "../../../../types";
import RSVPPopover from "../../RSVPPopover";
import { userStore } from "@state/store";

export type VTPendingInviteCard = {
  meeting: any;
  needsRefresh: boolean;
  setNeedsRefresh: (needsRefresh: boolean) => void;
};

const PendingInviteCard = ({
  meeting,
  needsRefresh,
  setNeedsRefresh,
}: VTPendingInviteCard) => {
  const { user, setUser } = userStore();
  const formattedDate = new Date(meeting.start * 1000);
  const formattedEndDate = new Date(
    meeting.start * 1000 + meeting.duration * 1000
  );
  const meetingStartTime = format(new Date(formattedDate), "HH:mm");
  const meetingEndTime = format(new Date(formattedEndDate), "HH:mm");
  const meetingFormattedDate = formattedDate.toLocaleString("en-uk", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const inviteIdArray = meeting.invites.filter((invite: VTInvite) => {
    if (invite.invitee_id === user?.user_id) {
      return invite.invite_id;
    }
  });

  const inviteId = inviteIdArray.map((invite: VTInvite) => {
    return invite.invite_id;
  });

  const newMeetingTitle = (meetingTitle: string) => {
    if (meetingTitle.length > 40) {
      let text = meetingTitle.substring(0, 1024); //cuts to 1024
      const last = text.lastIndexOf(" "); //gets last space (to avoid cutting the middle of a word)
      text = text.substring(0, last); //cuts from last space (to avoid cutting the middle of a word)
      text = text + `...`; //adds (...) at the end to show that it's cut
      return text;
    }
    return meetingTitle;
  };

  return (
    <Fade in={true}>
      <Box
        sx={{
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          backgroundColor: "white",
          width: "100%",
          maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        }}
      >
        <Stack p={1} spacing={1} direction="row" alignItems="center">
          <Box sx={{ width: "10%" }}>
            <EventIcon />
          </Box>

          <Box
            sx={{
              width: "60%",
              alignItems: "center",
            }}
          >
            <Typography noWrap={true} variant="h4">
              {newMeetingTitle(meeting.name)}
            </Typography>
            <Typography variant="body2">
              {meetingFormattedDate} at {meetingStartTime} - {meetingEndTime}
            </Typography>
          </Box>

          <Box p={1} sx={{ width: "20%" }}>
            <RSVPPopover
              needsRefresh={needsRefresh}
              setNeedsRefresh={setNeedsRefresh}
              meeting={meeting}
              inviteId={inviteId}
            />
          </Box>
        </Stack>
      </Box>
    </Fade>
  );
};

export default PendingInviteCard;
