///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING INVITATION STATUS OF CARDS
///
///////////////////////////////////////////////////////////////

import { useContext } from "react";

// MUI COMPONENTS
import { Box, Stack } from "@mui/material";
import TimelineDot from "@mui/lab/TimelineDot";

// GLOBAL STATE
import { meetingsStore } from "@state/store";

export type StatusIndicator = {
  status: any;
};

const StatusIndicator = ({ status }: StatusIndicator) => {
  // GLOBAL STATE
  const { setMeetingsView, meetingsView } = meetingsStore();

  return (
    <>
      {meetingsView === "monthly" ? (
        <>
          {status[0] === "tentative" ? (
            <Box>
              <TimelineDot
                sx={{ margin: "0px", background: "yellow" }}
              ></TimelineDot>
            </Box>
          ) : status[0] === "accepted" ? (
            <Box>
              <TimelineDot
                sx={{ margin: "0px", background: "#008746" }}
              ></TimelineDot>
            </Box>
          ) : status[0] === "rejected" ? (
            <Box>
              <TimelineDot
                sx={{ margin: "0px", background: "red" }}
              ></TimelineDot>
            </Box>
          ) : (
            <Box>
              <TimelineDot
                sx={{ margin: "0px", background: "#C4C4C4" }}
              ></TimelineDot>
            </Box>
          )}
        </>
      ) : (
        <>
          {status[0] === "tentative" ? (
            <Box
              m={0.5}
              sx={{
                width: "4px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                background: "yellow",
                borderRadius: "5px",
              }}
            >
              &nbsp;
            </Box>
          ) : status[0] === "accepted" ? (
            <Box
              m={0.5}
              sx={{
                width: "4px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                background: "#008746",
                borderRadius: "5px",
              }}
            >
              &nbsp;
            </Box>
          ) : status[0] === "rejected" ? (
            <Box
              m={0.5}
              sx={{
                width: "4px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                background: "red",
                borderRadius: "5px",
              }}
            >
              &nbsp;
            </Box>
          ) : (
            <Box
              m={0.5}
              sx={{
                width: "4px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                background: "#C4C4C4",
                borderRadius: "5px",
              }}
            >
              &nbsp;
            </Box>
          )}
          <Box />
        </>
      )}
      <Box />
    </>
  );
};
export default StatusIndicator;
