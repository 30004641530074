import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";

export type VTSave = {
  saveMeeting: () => void;
  createMeetingModalIsOpen: boolean;
};

const Save = ({ saveMeeting, createMeetingModalIsOpen }: VTSave) => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [buttonIsClicked, setButtonIsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (buttonIsClicked === false) {
      setButtonDisabled(true);
    }
  }, [buttonIsClicked]);

  return (
    <>
      {createMeetingModalIsOpen ? (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={(e) => {
            e.currentTarget.disabled = !buttonDisabled;
            saveMeeting();
            setButtonIsClicked(!buttonIsClicked);
          }}
        >
          Create
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={saveMeeting}
        >
          Save
        </Button>
      )}
    </>
  );
};

export default Save;
