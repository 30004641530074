import { useState } from "react";

//IMPORT STYLES
import { Stack, Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { meetingsStore } from "@state/store";

//IMPORT TIME
import { format } from "date-fns";

const ForStartDate = () => {
  const { dateForCalendar, setDateForCalendar } = meetingsStore();

  const handleBackwardDateClick = () => {
    setDateForCalendar(
      new Date(dateForCalendar.getTime() - 30 * 24 * 60 * 60 * 1000)
    );
  };

  const handleForwardDateClick = () => {
    setDateForCalendar(
      new Date(dateForCalendar.getTime() + 30 * 24 * 60 * 60 * 1000)
    );
  };

  return (
    <>
      <Stack
        padding={1}
        direction="row"
        spacing={6}
        sx={{
          justifyContent: "center",
          alignItems: "center,",
          minWidth: "320px",
          alignSelf: "center",
        }}
      >
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={handleBackwardDateClick}
        >
          <ArrowBackIosIcon sx={{ color: "black" }} />
        </IconButton>
        <Box
          sx={{
            width: "40%",
            textAlign: "center",
          }}
          alignSelf={"center"}
        >
          {format(dateForCalendar, "MMMM Y")}
        </Box>
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={handleForwardDateClick}
        >
          <ArrowForwardIosIcon sx={{ color: "black" }} />
        </IconButton>
      </Stack>
    </>
  );
};

export default ForStartDate;
